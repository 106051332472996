import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { kebabCase } from 'lodash-es';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-event-tournament-details',
  templateUrl: './event-tournament-details.component.html',
  styleUrls: ['./event-tournament-details.component.scss'],
})
export class EventTournamentDetailsComponent implements OnInit {
  @Input() event: MatchModel;
  @Input() sportId: number;
  @Input() showOddCount: boolean = true;
  productIconClass: string;
  enableStatistics = false;

  constructor(
    private readonly appConfigService: AppConfigService,
    public statisticsQuery: StatisticsQuery,
    public statisticsService: StatisticsService,
    public matchService: MatchService,
    private readonly router: Router
  ) {
    this.enableStatistics = this.showStatistics();
  }

  ngOnInit(): void {
    this.productIconClass = `icon-${this.sportId}`;
  }

  navigateToEvent(): void {
    if (!this.event.odds[0]) {
      return;
    }

    const sport = kebabCase(this.event.odds[0].sportName);
    const category = kebabCase(this.event.categoryName);
    const tournament = kebabCase(this.event.tournamentName);
    const url = `/sports/events/prematch/${sport}/${category}/${tournament}`;

    this.router.navigate([url]);
  }

  private showStatistics(): boolean {
    return this.appConfigService.get('sports').statistics.enabled;
  }
}
