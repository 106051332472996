import { Injectable } from '@angular/core';

import { Order, QueryEntity } from '@datorama/akita';

import { MessagesState } from 'src/app/shared/models/message.model';
import { MessagesStore } from './messages.store';

@Injectable({ providedIn: 'root' })
export class MessagesQuery extends QueryEntity<MessagesState> {
  viewingMessage$ = this.select(state => state.viewingMessage);
  messages$ = this.selectAll({
    sortBy: 'date',
    sortByOrder: Order.DESC,
  });
  unreadMessagesCount$ = this.select(state => state.ui.unreadMessageCount);
  moreMessagesLoading$ = this.select(state => state.ui.moreMessagesLoading);
  totalMessageCount$ = this.select(state => state.ui.totalMessageCount);
  messagesPage$ = this.select(state => state.ui.messagesPage);
  messageCount$ = this.selectCount();
  loading$ = this.selectLoading();
  filteredState$ = this.select(state => state.ui.filter.filteredState);

  constructor(protected store: MessagesStore) {
    super(store);
  }

  get isViewingMessage(): boolean {
    return this.getValue().viewingMessage !== undefined;
  }

  get messagesPage(): number {
    return this.getValue().ui.messagesPage;
  }

  /**
   * Get filter state
   *
   * @return DateFrom, DateTo, state, direction
   */
  get filter(): [Date, Date, number | string] {
    return [this.getValue().ui.filter.dateFrom, this.getValue().ui.filter.dateTo, this.getValue().ui.filter.state];
  }

  get showMoreMessagesButton(): boolean {
    return this.getCount() < this.getValue().ui.totalMessageCount;
  }
}
