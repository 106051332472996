export class ResolverState {
  idCache: IdCacheModel;
  nameCache: NameCacheModel;

  constructor(init: Partial<ResolverState>) {
    Object.assign(this, init);
  }
}

export class NameCacheModel {
  outright: ResolverNameCommonState;
  prematch: ResolverNameCommonState;

  constructor(init: Partial<NameCacheModel>) {
    Object.assign(this, init);
  }
}

export class IdCacheModel {
  outright: ResolverIdCommonState;
  prematch: ResolverIdCommonState;

  constructor(init: Partial<IdCacheModel>) {
    Object.assign(this, init);
  }
}

export class ResolverIdCommonState {
  sports: {
    [key: string]: {
      name: string;
      categories: {
        [key: string]: {
          name: string;
          tournaments: {
            [key: string]: {
              name: string;
            };
          };
        };
      };
    };
  };

  constructor(init: Partial<ResolverIdCommonState>) {
    Object.assign(this, init);
  }
}

export class ResolverNameCommonState {
  sports: {
    [key: string]: {
      id: string;
      categories: {
        [key: string]: {
          id: string;
          tournaments: {
            [key: string]: {
              id: string;
            };
          };
        };
      };
    };
  };

  constructor(init: Partial<ResolverNameCommonState>) {
    Object.assign(this, init);
  }
}

export class ResolvedModel {
  sport: string;
  category: string;
  tournament: string;
  initRegionId: number = 0;
  initAreaId: number = 0;

  constructor(init: Partial<ResolvedModel>) {
    Object.assign(this, init);
  }
}
