import { NgModule } from '@angular/core';
import { AccumulatorBonusProgressionBarComponent } from 'src/app/modules/coupon/components/coupon-acca-bonus-details/coupon-acca-bonus-details.component';
import { CouponSuccessfulBetComponent } from 'src/app/modules/coupon/components/coupon-successful-bet/coupon-successful-bet.component';
import { FreebetsModule } from 'src/app/modules/freebets/freebets.module';
import { MobileVerificationCampaignModule } from 'src/app/modules/mobile-verification-campaign/mobile-verification-campaign.module';

import { SharedModule } from 'src/app/shared/shared.module';
import { CouponGroupingsComponent } from './components/coupon-groupings/coupon-groupings.component';
import { CouponPageWrapperComponent } from './components/coupon-page-wrapper/coupon-page-wrapper.component';
import { CouponSelectionsComponent } from './components/coupon-selections/coupon-selections.component';
import { CouponTotalsComponent } from './components/coupon-totals/coupon-totals.component';
import { CouponRoutingModule } from './coupon-routing.module';
import { CouponComponent } from './coupon.component';

const EXPORTS = [AccumulatorBonusProgressionBarComponent, CouponComponent];

const COMPONENTS = [
  CouponSelectionsComponent,
  CouponGroupingsComponent,
  CouponTotalsComponent,
  CouponPageWrapperComponent,
  CouponSuccessfulBetComponent,
  ...EXPORTS,
];

@NgModule({
  imports: [SharedModule, CouponRoutingModule, MobileVerificationCampaignModule, FreebetsModule],
  declarations: [...COMPONENTS],
  exports: [...EXPORTS],
})
export class CouponModule {}
