import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'titleCase',
})
export class TitleCasePipe implements PipeTransform {
  transform(title: string): any {
    const titleString = title.toLowerCase().split(' ');

    const final = [];

    for (const word of titleString) {
      final.push(word.charAt(0).toUpperCase() + word.slice(1));
    }

    return final.join(' ');
  }
}
