<div class="area-region-dropdown" *ngIf="(selectedRegion$ | async) && (selectedRegion$ | async).name !== '-' &&
                                          (selectedArea$ | async) && (selectedArea$ | async).name !== '-'"
  [@expandCollapse]="{ value: ((dropdownOpened$ | async ) || ( showInfo$ | async)) ? 'expanded' : 'collapsed', params: { minHeight: '48px' } }">
  <div class="region-dropdown" [ngClass]="{ full: !showMoreInfo }" (click)="toggleDropdown()"
    *ngIf="(selectedRegion$ | async) && (selectedArea$ | async)" [@fadeIn]>
    <span>
      {{ (selectedRegion$ | async) && (selectedRegion$ | async).name }}/{{ (selectedArea$ | async).name }}
    </span>
    <span class="material-icons arrow-down">{{'arrow_drop_down'}}</span>
  </div>
  <ng-container *ngIf="showMoreInfo">
    <div class="info-line" [class.opened]="showInfo$ | async" *ngIf="sportQuery.selectedArea$ | async as selectedArea">
      <span class="material-icons" (click)="toggleInfo()">{{'info'}}</span>
    </div>
    <div class="market-info" [@expandCollapse]="(showInfo$ | async) ? 'expanded' : 'collapsed'"
      *ngIf="(sportQuery.selectedPrematch$ | async)[0]">
      <div class="market" *ngFor="let market of (sportQuery.selectedPrematch$ | async)[0].area.markets">
        <span class="market-name">{{market.name}}:&nbsp;</span>
        <span class="market-description">{{market.description}}</span>
      </div>
    </div>
  </ng-container>
  <div class="regions-wrapper"
    [@expandCollapse]="{ value: (dropdownOpened$ | async) ? 'expanded' : 'collapsed', params: { minHeight: '0px' } }">
    <div class="region-row" [ngClass]="{ closed: (dropdownOpened$ | async) === false }"
      *ngFor="let regionRow of regionsDropdown$ | async; let rowIndex = index">
      <div class="region-buttons-wrapper">
        <div class="region-button" *ngFor="let region of regionRow; let regionIndex = index"
          (click)="selectRegion(rowIndex, regionIndex, region)" [ngClass]="{
                active: regionIndex === (selectedRegionIndex$ | async) && rowIndex === (selectedRegionRow$ | async),
                selected: (selectedRegion$ | async) && (selectedRegion$ | async).region && (selectedRegion$ | async).region.id === region.region.id
              }">
          {{ region.region.name }}
        </div>
      </div>
      <ng-container *ngIf="regionRow[selectedRegionIndex$ | async]">
        <div class="areas" [@fadeIn] [ngClass]="{ selectedAreas: rowIndex === (selectedRegionRow$ | async) }"
          [@expandCollapse]="
                regionRow[selectedRegionIndex$ | async] && (selectedRegionRow$ | async) === rowIndex ? 'expanded' : 'collapsed'
              ">
          <div class="area-wrapper" *ngFor="let area of regionRow[selectedRegionIndex$ | async].areas"
            (click)="selectArea(area)">
            <div class="area" [ngClass]="{ selected: area.id === (selectedArea$ | async).id }">
              {{ area.name }}
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
