import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { RegistrationService } from 'src/app/core/services/registration.service';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { RegistrationType } from 'src/app/shared/models/registration.model';

/**
 * This guard ensures the user will enter the appropriate registration
 * function as is configured for the brand.
 */
@Injectable()
export class RegistrationRouteGuard implements CanActivate {
  constructor(
    private readonly appConfigService: AppConfigService,
    private readonly router: Router,
    private readonly registrationService: RegistrationService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly location: Location
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    try {
      if (state.url.includes('/registration-success')) {
        return this.performNavigation(state, route, state.url);
      } else {
        const url = this.urlBuilder(route);
        switch (this.registrationQuery.registrationType) {
          case RegistrationType.Normal:
          case RegistrationType.Split:
          case RegistrationType.Quick:
          case RegistrationType.UserJourney:
            return this.performNavigation(state, route, url);
          case RegistrationType.Redirect:
            this.router.navigate([this.appConfigService.get('registration').registrationRedirect]);
            break;
          default:
            this.router.navigate(['/']);
        }
        return false;
      }
    } catch {
      this.router.navigate(['/']);
      return false;
    }
  }

  private performNavigation(state: RouterStateSnapshot, route: ActivatedRouteSnapshot, url: string): boolean {
    if (state.url === url) {
      return true;
    }
    this.router.navigateByUrl(url);
  }

  private openModal(url: string): boolean {
    if (window.location.pathname.includes('/register')) {
      // Navigate to landing page but persist URL
      this.router.navigateByUrl('/', { skipLocationChange: true });
      this.location.replaceState(url);
    }
    return false;
  }

  private urlBuilder(route: ActivatedRouteSnapshot): string {
    switch (this.registrationQuery.registrationType) {
      case RegistrationType.Normal:
      case RegistrationType.Split:
      case RegistrationType.UserJourney:
        return `/account/registration${this.queryStringReplicator(route)}`;
      case RegistrationType.Quick:
        return `/account/registration/quick${this.queryStringReplicator(route)}`;
      case RegistrationType.Redirect:
      default:
        return '/';
    }
  }

  /**
   * Instead of using window.location.search, due to an issue where query strings are not persisted
   * when switching between registration types, this method was created instead to created query string lists from route snapshot
   *
   * @param route The current route
   */
  private queryStringReplicator(route: ActivatedRouteSnapshot): string {
    return route.queryParamMap.keys.length
      ? `?${route.queryParamMap.keys.map(key => `${key}=${route.queryParamMap.get(key)}`).join('&')}`
      : '';
  }
}
