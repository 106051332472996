import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';

import { indexOf } from 'lodash-es';
import { Observable } from 'rxjs';
import { filter, finalize, map, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { EvaluationSignalrService } from 'src/app/core/services/evaluation-signalr.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CashoutQuery } from 'src/app/core/state/cashout/cashout.query';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BetCashoutModel, BetCashoutResponseModel, CashoutStatus, CashoutStatusModel } from 'src/app/shared/models/cashout.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { APIService } from './api.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class CashoutService {
  private readonly cashoutErrorCodes: any = {
    105: $localize`Cashout unavailable when in-play`,
    300: $localize`An error has occurred during the cashing out process. Please try again`,
    301: $localize`Cash out for this coupon has already been requested`,
    302: $localize`The number of odds being cashed out doesn't match the ones in the coupon`, // TODO: Better explanation
    303: $localize`The offered cash out value count is smaller than the coupons to cash out count`, // TODO: Better explanation
    304: $localize`Offered cash out value is different than computed by the system`,
    305: $localize`An unhandled error has occurred during cash out. Please try again.`,
    306: $localize`Live not available for started match`,
    307: $localize`Timeout exceeded for cashout request after cashout value calculation`,
    308: $localize`Out of leeway`,
    309: $localize`Hash integrity violation`,
  };
  private readonly disableCashoutCodes: number[] = [311, 312];
  private readonly currencyFormatPipe;

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly cashoutQuery: CashoutQuery,
    private readonly cashoutStore: CashoutStore,
    private readonly currencyService: CurrencyService,
    private readonly evaluationSignalrService: EvaluationSignalrService,
    private readonly languageService: LanguageService,
    private readonly myBetsStore: MyBetsStore,
    private readonly notificationService: NotificationService
  ) {
    this.currencyFormatPipe = new CurrencyFormatPipe(this.applicationQuery, this.languageService, this.currencyService);
  }

  get isWhitelistedUserType(): boolean {
    return this.checkCashoutBlacklist(this.accountQuery.userData);
  }

  refreshCashout(id: ID, couponCode: string): Observable<boolean> {
    if (!couponCode) {
      return new Observable<boolean>();
    }

    this.cashoutStore.setActive(id);
    this.cashoutStore.cashoutInProgress(true);

    return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/cashoutvalue_new/${couponCode}`).pipe(
      finalize(() => {
        this.cashoutStore.cashoutInProgress(false);
      }),
      map(responseData => {
        if (!responseData) {
          return false;
        }

        const betCashout: BetCashoutModel = this.parseBetCashout(responseData);
        if (betCashout) {
          this.cashoutStore.updateBetCashoutData(betCashout);
        }
        return true;
      })
    );
  }

  cashout(id: ID, cashoutData: any, inBehalfOf: string): Observable<boolean> {
    if (!cashoutData) {
      return new Observable<boolean>();
    }

    const apiSettings: APISettings = new APISettings();
    if (inBehalfOf) {
      apiSettings.inBehalfOf = inBehalfOf;
    }

    this.cashoutStore.setActive(id);
    this.cashoutStore.cashoutInProgress(true);

    return this.apiService.post<any>(APIType.Sportsbook, `api/coupons/cashout_new`, [cashoutData], apiSettings).pipe(
      finalize(() => {
        this.cashoutStore.cashoutInProgress(false);
      }),
      map(responseData => {
        let cashoutResponse: BetCashoutResponseModel;

        if (!responseData) {
          cashoutResponse = this.parseCashoutResponse(undefined);
        } else {
          cashoutResponse = this.parseCashoutResponse(responseData);
        }

        if (cashoutResponse) {
          this.cashoutStore.updateCashoutResponseData(cashoutResponse);
        }
        return true;
      })
    );
  }

  addToPendingList(cashoutId: number, couponCode: string): void {
    this.cashoutStore.addToPendingList(cashoutId, couponCode);
  }

  pendingCashouts(): Observable<boolean> {
    this.cashoutStore.clearPendingCashoutList();

    return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pendingcashout`).pipe(
      map(responseData => {
        if (!responseData || !responseData.length) {
          this.cashoutStore.clearPendingCashoutList();
          return false;
        }

        responseData.forEach(cashout => {
          this.cashoutStore.addToPendingList(cashout.CashoutID, cashout.CouponCode);
        });
        return true;
      })
    );
  }

  pendingCashoutData(data: any): void {
    if (!data) {
      return;
    }

    const parsedData = this.parseCashoutStatusData(data);

    this.cashoutStore.cashoutRequested(false);
    this.cashoutStore.removeFromPendingList(parsedData.cashoutId);

    if (parsedData.status === CashoutStatus.Approved) {
      this.accountService.updateBalance();
      this.myBetsStore.removeOpenBet(parsedData.couponCode);
      this.cashoutStore.removeCashout(parsedData.couponCode);

      this.notificationService.showSuccessNotification(
        $localize`Coupon ${parsedData.couponCode} has been cashed out with a value of ${this.currencyFormatPipe.transform(
          parsedData.cashoutValue
        )}.`
      );
    } else if (parsedData.status === CashoutStatus.Rejected) {
      this.notificationService.showErrorNotification($localize`Cashout for coupon ${parsedData.couponCode} has been rejected by operator.`);
    }
  }

  initPendingCashouts(): void {
    this.accountQuery.isAuthenticated$.subscribe(auth => {
      if (auth) {
        this.pendingCashouts().subscribe();
      } else {
        // stop Evaluation Connection
        this.evaluationSignalrService.stopConnection();

        this.myBetsStore.clear();
      }
    });

    this.cashoutQuery.pendingCashoutList$
      .pipe(
        tap(pendingCashoutList => {
          if (pendingCashoutList && pendingCashoutList.length > 0) {
            // start Evaluation Connection
            this.evaluationSignalrService.startConnection();
          }
        })
      )
      .subscribe();

    this.evaluationSignalrService.cashoutEvaluationComplete$
      .pipe(
        filter((response: any) => Object.keys(response).length !== 0),
        tap((response: any) => this.pendingCashoutData(response))
      )
      .subscribe();
  }

  parseBetCashoutResponse(response: any): BetCashoutModel {
    // the list of CashoutResult codes which will show a message
    // to users if cashout is not available
    const codesWithDisplayMessage = [
      105, // coupon contains live events
    ];

    // the list of CashoutResult codes which will be shown the
    // 'Cash-out temporarily unavailable' generic message
    const temporarilyUnavailableCodes = this.appConfig.get('sports').cashout.unavailableCodes;

    const betCashout = new BetCashoutModel({
      availability: false,
      allowCashout: true,
      result: 2,
      value: -1,
      message: '',
    });

    if (!response) {
      return betCashout;
    }

    // Need to store the original object since now it needs to be sent back during cashout
    betCashout.serverData = response;
    betCashout.availability = response.CashoutAvailability;
    betCashout.result = response.CashoutResult;

    if (betCashout.availability) {
      betCashout.value = response.CashoutValue;
      betCashout.valueNet = response.NetOfferedValue;
      betCashout.tax = response.WithholdingTax;

      if (betCashout.result === 311) {
        // disable cashout button when responseCode is 311
        betCashout.allowCashout = false;
      }
    } else if (indexOf(temporarilyUnavailableCodes, betCashout.result) > -1) {
      betCashout.message = $localize`Cash-Out Temporarily Unavailable`;
    } else if (indexOf(codesWithDisplayMessage, betCashout.result) > -1) {
      betCashout.message = this.getCashoutError(betCashout.result);
    }

    return betCashout;
  }

  getCashoutError(errorCode: number): string {
    return this.cashoutErrorCodes[errorCode];
  }

  isWhitelistedUserType$(): Observable<boolean> {
    return this.accountQuery.userData$.pipe(map(this.checkCashoutBlacklist));
  }

  private readonly checkCashoutBlacklist = userData =>
    // If not logged in, the default user type would be a USER, so not blacklisted
    userData ? !(this.appConfig.get('sports').cashout.blackListedUserTypes || []).includes(userData.userTypeCode) : true;

  private parseBetCashout(response: any): BetCashoutModel {
    if (!response || !response.length) {
      return new BetCashoutModel({});
    }

    let allowCashout = true;
    if (this.disableCashoutCodes.includes(response[0].CashoutResult)) {
      allowCashout = false;
    }

    const betCashout = new BetCashoutModel({
      availability: response[0].CashoutAvailability,
      allowCashout: allowCashout,
      result: response[0].CashoutResult,
      value: response[0].CashoutValue,
      valueNet: response[0].NetOfferedValue,
      tax: response[0].WithholdingTax,
      serverData: response[0], // Need to store the original object since now it needs to be sent back during cashout
    });
    return betCashout;
  }

  private parseCashoutResponse(response: any): BetCashoutResponseModel {
    const cashoutResponse = new BetCashoutResponseModel({
      success: false,
      cashoutAccepted: false,
      userMessage: '',
      responseCode: 300,
      cashoutId: -1,
      couponCode: '',
      cashoutValue: -1,
      currencySymbol: '',
    });

    if (!response || !response.length) {
      return cashoutResponse;
    }

    cashoutResponse.success = response[0].CashoutRequested;
    cashoutResponse.cashoutAccepted = response[0].CashoutAccepted;
    cashoutResponse.responseCode = response[0].CashoutResult;
    cashoutResponse.cashoutId = response[0].CashoutId;
    cashoutResponse.couponCode = response[0].CouponCode;
    cashoutResponse.cashoutValue = response[0].CashoutValue;
    cashoutResponse.currencySymbol = response[0].Currency ? response[0].Currency.CurrencySymbol : undefined;

    // parse user message
    cashoutResponse.userMessage = $localize`Cashout is being evaluated by operator. You will be notified shortly with the result.`;
    if (!cashoutResponse.success) {
      cashoutResponse.userMessage = $localize`An error has occurred during the cashing out process. Please try again (${cashoutResponse.responseCode})`;
    }
    if (cashoutResponse.responseCode >= 300 && cashoutResponse.responseCode < 400) {
      // It might be the case that cashout was successfully requested by we get a response code in this range
      // Error codes 300+ have specific messages
      cashoutResponse.userMessage = this.getCashoutError(cashoutResponse.responseCode);

      if (cashoutResponse.responseCode === 304) {
        const betCashout: BetCashoutModel = new BetCashoutModel({
          value: response[0].CashoutValue,
        });
        this.cashoutStore.updateBetCashoutData(betCashout);
      } else {
        this.cashoutStore.updateBetCashoutData(undefined);
      }
    }

    return cashoutResponse;
  }

  private parseCashoutStatusData(response: any): CashoutStatusModel {
    const cashoutResponse = new CashoutStatusModel({
      status: CashoutStatus.Pending,
      cashoutId: -1,
      couponCode: '',
      cashoutValue: -1,
    });

    if (response === undefined) {
      return cashoutResponse;
    }

    cashoutResponse.cashoutId = response.cashoutId;
    cashoutResponse.couponCode = response.couponCode;

    if (response.cashoutStatus === 2) {
      cashoutResponse.status = CashoutStatus.Approved;
      cashoutResponse.cashoutValue = response.netOfferedValue;
    } else if (response.cashoutStatus === 3) {
      cashoutResponse.status = CashoutStatus.Rejected;
    }

    return cashoutResponse;
  }
}
