<div class="footer-section">
  <div class="section-1">
    <div class="play-responsibly-container">
      <div class="play-responsibly">
        <span class="age-icon"></span>
        <span class="label" i18n>Play Responsibly</span>
      </div>
    </div>
    <div class="badges">
      <ng-container *ngIf="showAwards">
        <span class="award-icon icon-1"></span>
      </ng-container>
      <div class="league" *ngIf="!!leagueOneSponsor">
        <span class="league-badge badge-1"></span>
        <span class="label-text">{{ leagueOneSponsor }}</span>
      </div>
      <div class="league" *ngIf="!!leagueTwoSponsor">
        <span class="league-badge badge-2"></span>
        <span class="label-text">{{ leagueTwoSponsor }}</span>
      </div>
    </div>
  </div>
  <div class="section-2">
    <a class="fa fa-facebook-official" [attr.href]="social.facebook" *ngIf="social.facebook"></a>
    <a class="fa fa-twitter" [attr.href]="social.twitter" *ngIf="social.twitter"></a>
    <a class="fa fa-instagram" [attr.href]="social.instagram" *ngIf="social.instagram"></a>
    <a class="fa fa-youtube" [attr.href]="social.youtube" *ngIf="social.youtube"></a>
  </div>
  <div class="section-3" *ngIf="showPaymentIcons">
    <div class="payment-icons"></div>
  </div>
  <div class="section-4" *ngIf="brandInfo.footerNote">
    <span>{{ brandInfo.footerNote }}</span>
  </div>
  <div class="section-5">
    <div class="brand-logo"></div>
    <div class="legal-notice">
      <ng-container *ngIf="applicationQuery.isBrand(['Nigeria'])">
        <span>©</span>
        <span>{{ legalNoticeYear }}</span>
        <span i18n>SV Gaming Limited T/A BetKing {{footerLicenseNumber}}. All Rights Reserved by SV Gaming Limited T/A
          BetKing.</span>
      </ng-container>
      <ng-container *ngIf="applicationQuery.isBrand(['Kenya'])">
        <span i18n>ENTERTAINMENT NETWORK GROUP KENYA LIMITED t/a BetKing is licensed by BCLB(Betting Control and
          Licensing Board of
          Kenya) under the Betting, Lotteries and Gaming Act, Cap 131, Laws of Kenya under License number:
          {{footerLicenseNumber}}</span>
      </ng-container>
      <ng-container *ngIf="applicationQuery.isBrand(['Ethiopia'])">
        <span>©</span>
        <span>{{ legalNoticeYear }}</span>
        <span i18n>BetKing Ethiopia Sports Betting T/A BetKing. All Rights Reserved by BetKing Ethiopia Sports Betting
          T/A BetKing.</span>
      </ng-container>
      <ng-container *ngIf="applicationQuery.isBrand(['Ghana'])">
        <span i18n>Arcadia Games Ltd trading as BetKing Ghana is licensed by the Gaming Commission of Ghana under the
          Gaming Act, 2006 (Act, 721), License number: {{footerLicenseNumber}}</span>
      </ng-container>
    </div>
  </div>
  <div *ngIf="androidSettingsQuery.appVersionName !== undefined" class="section-6">
    <span>{{ 'V' + MobilePlusVersion }}</span>
    <span>{{ ' | App V' + androidSettingsQuery.appVersionName }}</span>
  </div>
</div>
