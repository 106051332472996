import { ChangeDetectionStrategy, Component, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';

@Component({
  selector: 'app-acca-bonus-unacceptable-selections-prompt',
  templateUrl: './acca-bonus-unacceptable-selections-prompt.component.html',
  styleUrls: ['./acca-bonus-unacceptable-selections-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccaBonusUnacceptableSelectionsPromptComponent implements OnDestroy {
  @Output() readonly closePrompt = new EventEmitter();

  private readonly MIN_ACCA_ODD_VALUE_KEY = '[[Min_Acca_Odd_Value]]';
  private readonly destroy$ = new Subject<boolean>();

  constructor(private readonly accumulatorBonusQuery: AccumulatorBonusQuery) {}

  get textContent$(): Observable<string> {
    return this.accumulatorBonusQuery.cmsContent$.pipe(
      filter(cmsContent => !!cmsContent),
      map(cmsContent =>
        cmsContent.accumulatorBonusOddsValuePopupText.includes(this.MIN_ACCA_ODD_VALUE_KEY)
          ? cmsContent.accumulatorBonusOddsValuePopupText.replace(
              this.MIN_ACCA_ODD_VALUE_KEY,
              this.accumulatorBonusQuery.minOddsValue.toString()
            )
          : cmsContent.accumulatorBonusOddsValuePopupText
      ),
      takeUntil(this.destroy$)
    );
  }

  close(): void {
    this.closePrompt.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
