import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AreaMatchModel, DayModel, SportModel, TodaysEventsState } from 'src/app/modules/sport/models/todays-events/todays-events.model';
import { TodaysEventsStore } from 'src/app/modules/sport/state/todays-events/todays-events.store';
import { AreaModel, MarketModel } from 'src/app/shared/models/sport.model';

@Injectable({ providedIn: 'root' })
export class TodaysEventsQuery extends Query<TodaysEventsState> {
  correctScoreAreaIds = this.appConfig.get('correctScoreAreaIds');
  areaMatches$ = this.select(state => state.areaMatches);
  regionsWithAreas$ = this.select(state => state.regionsWithAreas);
  areas$ = this.select(state => state.areas);
  regions$ = this.select(state => state.regions);
  sports$ = this.select(state => state.sports);
  selectedTournamentId$ = this.select(state => state.selectedTournamentId);
  matchesByTournament$ = this.select(state => state.matchesByTournament);
  tournaments$ = this.select(state => state.tournaments);
  selectedDay$ = this.select(state => state.selectedDay);
  selectedSport$ = this.select(state => state.selectedSport);
  selectedRegion$ = this.select(state => state.selectedRegion);
  selectedArea$ = this.select(state => state.selectedArea);
  selectedMarket$ = this.select(state => state.selectedMarket);
  sortType$ = this.select(state => state.sortType);
  isEventsLoading$ = this.select(state => state.ui.isEventsLoading);
  firstTimeLoadIsInProgress$ = this.select(state => state.ui.firstTimeLoadIsInProgress);
  flowControl$ = combineLatest(this.selectedDay$, this.selectedRegion$, this.selectedArea$);
  isItMultiline$ = this.select(state => state.isItMultiline);
  isItCorrectScores$ = this.selectedArea$.pipe(
    map(area => {
      let isItCorrectScore = false;
      this.correctScoreAreaIds.forEach(areaId => {
        if (area && areaId === area.id) {
          isItCorrectScore = true;
        }
      });
      return isItCorrectScore;
    })
  );
  areaMatchesForPlayerViewSingleLine$ = this.areaMatches$.pipe(
    map(data => {
      if (!data || !data[0]) {
        return;
      }
      const reducedTeamsData = [];
      const flags = [];
      const reducedEvens: any = [];
      const l = data[0].items.length;
      let i: number;
      for (i = 0; i < l; i++) {
        if (flags[data[0].items[i].extParentItemID]) {
          continue;
        }
        flags[data[0].items[i].extParentItemID] = true;
        reducedEvens.push({ extParentItemID: data[0].items[i].extParentItemID, data: [] });
      }
      data[0].items.forEach(element => {
        reducedEvens.forEach(event => {
          if (element.extParentItemID === event.extParentItemID) {
            event.data.push(element);
          }
        });
      });

      reducedEvens.forEach((eventData, index) => {
        eventData.data.forEach(innerEventData => {
          const flags2 = [];
          const reducedEvens2: any = [];
          const l2 = eventData.data.length;
          let i2: number;
          for (i2 = 0; i2 < l2; i2++) {
            if (flags2[eventData.data[i2].extParentTeamID]) {
              continue;
            }
            flags2[eventData.data[i2].extParentTeamID] = true;
            reducedEvens2.push({
              extParentTeamID: eventData.data[i2].extParentTeamID,
              data: [],
              tournamentName: eventData.data[i2].tournamentName,
            });
          }
          reducedTeamsData[index] = [].concat(reducedEvens2);
        });
      });

      reducedEvens.forEach((eventData2, index) => {
        eventData2.data.forEach(innerEventData => {
          reducedTeamsData.forEach(id => {
            id.forEach((elem, index2) => {
              if (elem.extParentTeamID === innerEventData.extParentTeamID) {
                reducedTeamsData[index][index2].data.push(innerEventData);
              }
            });
          });
        });
      });

      reducedTeamsData.forEach(teamData => {
        let allTypeOfOdds = [];
        teamData.forEach(matchData => {
          matchData.data.forEach(oddsData => {
            if (oddsData.odds.length > allTypeOfOdds.length) {
              allTypeOfOdds = oddsData.odds;
            }
          });
        });
        teamData.push(allTypeOfOdds);
      });
      const reorderedTeamData = [];
      reducedTeamsData.forEach(teams => {
        if (!teams || teams.length !== 3) {
          return;
        }
        let homeTeam;
        let awayTeam;
        const odds = [...teams[teams.length - 1]];
        if (teams[0].data[0].extTeamOrder === 2) {
          awayTeam = { ...teams[0] };
          homeTeam = { ...teams[1] };
        }
        if (teams[0].data[0].extTeamOrder === 1) {
          awayTeam = { ...teams[1] };
          homeTeam = { ...teams[0] };
        }
        const newTeamOrder = [homeTeam, awayTeam, odds];
        reorderedTeamData.push(newTeamOrder);
      });

      return reorderedTeamData;
    })
  );
  areaMatchesForPlayerViewSingleLineSortByTournament$ = this.areaMatchesForPlayerViewSingleLine$.pipe(
    map(teamData => {
      const matchesByTournaments = [];
      this.tournaments.forEach(tournament => {
        const tournamentId = tournament.tournamentId;
        const matchesByTournament = { tournamentId, data: [] };
        teamData.forEach(teams => {
          if (teams[0].data[0].tournamentId === tournamentId) {
            matchesByTournament.data.push(teams);
          }
        });
        matchesByTournaments.push(matchesByTournament);
      });
      return matchesByTournaments;
    })
  );
  isRegularMarket$ = this.select(state => state.isRegularMarket);
  isPlayerMarket$ = this.select(state => state.isPlayerMarket);

  constructor(private readonly appConfig: AppConfigService, protected store: TodaysEventsStore) {
    super(store);
  }

  get areaMatchesForPlayerViewSortByTournament(): any {
    const teamData = this.areaMatchesForPlayerView;
    const matchesByTournaments = [];
    this.tournaments.forEach(tournament => {
      const tournamentId = tournament.tournamentId;
      const matchesByTournament = { tournamentId, data: [] };
      teamData.forEach(teams => {
        if (teams[0].data[0].tournamentId === tournamentId) {
          matchesByTournament.data.push(teams);
        }
      });
      matchesByTournaments.push(matchesByTournament);
    });
    return matchesByTournaments;
  }

  get areaMatchesForPlayerView(): any {
    const data = this.getValue().areaMatches;
    if (!data[0]) {
      return;
    }
    const reducedTeamsData = [];
    const flags = [];
    const reducedEvens: any = [];
    const l = data[0].items.length;
    let i: number;
    for (i = 0; i < l; i++) {
      if (flags[data[0].items[i].extParentItemID]) {
        continue;
      }
      flags[data[0].items[i].extParentItemID] = true;
      reducedEvens.push({ extParentItemID: data[0].items[i].extParentItemID, data: [] });
    }
    data[0].items.forEach(element => {
      reducedEvens.forEach(event => {
        if (element.extParentItemID === event.extParentItemID) {
          event.data.push(element);
        }
      });
    });

    reducedEvens.forEach((eventData, index) => {
      eventData.data.forEach(innerEventData => {
        const flags2 = [];
        const reducedEvens2: any = [];
        const l2 = eventData.data.length;
        let i2: number;
        for (i2 = 0; i2 < l2; i2++) {
          if (flags2[eventData.data[i2].extParentTeamID]) {
            continue;
          }
          flags2[eventData.data[i2].extParentTeamID] = true;
          reducedEvens2.push({
            extParentTeamID: eventData.data[i2].extParentTeamID,
            data: [],
            tournamentName: eventData.data[i2].tournamentName,
          });
        }
        reducedTeamsData[index] = [].concat(reducedEvens2);
      });
    });

    reducedEvens.forEach((eventData2, index) => {
      eventData2.data.forEach(innerEventData => {
        reducedTeamsData.forEach(id => {
          id.forEach((elem, index2) => {
            if (elem.extParentTeamID === innerEventData.extParentTeamID) {
              reducedTeamsData[index][index2].data.push(innerEventData);
            }
          });
        });
      });
    });

    reducedTeamsData.forEach(teamData => {
      let allTypeOfOdds = [];
      teamData.forEach(matchData => {
        matchData.data.forEach(oddsData => {
          if (oddsData.odds.length > allTypeOfOdds.length) {
            allTypeOfOdds = oddsData.odds;
          }
        });
      });
      teamData.push(allTypeOfOdds);
    });
    const reorderedTeamData = [];
    reducedTeamsData.forEach(teams => {
      let homeTeam;
      let awayTeam;
      const odds = [...teams[teams.length - 1]];
      if (teams[0].data[0].extTeamOrder === 2) {
        awayTeam = { ...teams[0] };
        homeTeam = { ...teams[1] };
      }
      if (teams[0].data[0].extTeamOrder === 1) {
        awayTeam = { ...teams[1] };
        homeTeam = { ...teams[0] };
      }
      const newTeamOrder = [homeTeam, awayTeam, odds];
      reorderedTeamData.push(newTeamOrder);
    });

    return reorderedTeamData;
  }

  get areaMatches(): AreaMatchModel[] {
    return this.getValue().areaMatches;
  }
  get isItMultiline(): boolean {
    return this.getValue().isItMultiline;
  }
  get isItCorrectScores(): boolean {
    return this.getValue().isItCorrectScores;
  }

  get selectedTournamentId(): number {
    return this.getValue().selectedTournamentId;
  }

  get selectedDay(): DayModel {
    return this.getValue().selectedDay;
  }

  get selectedArea(): AreaModel {
    return this.getValue().selectedArea;
  }

  get selectedSport(): SportModel {
    return this.getValue().selectedSport;
  }

  get selectedMarket(): MarketModel {
    return this.getValue().selectedMarket;
  }

  get tournaments(): any {
    return this.getValue().tournaments;
  }

  get isEventsLoading(): boolean {
    return this.getValue().ui.isEventsLoading;
  }

  get firstTimeLoadIsInProgress(): boolean {
    return this.getValue().ui.firstTimeLoadIsInProgress;
  }
}
