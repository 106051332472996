// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  appConfigPath: 'assets/configs/app.test.conf',
  // appConfigCacheBuster is used to force cache busting of the config file. When needed generate a new value from https://randomkeygen.com/
  appConfigCacheBuster: 'yzCR8Xg6Lp',
  // brandMap is used to enable the isBrand() functionality inside application.query.ts. Must be kept up to date with new brands
  brandMap: {
    nigeria: 1901,
    ethiopia: 1902,
    kenya: 1903,
    ghana: 1904,
  },
  // The below default settings will be used if a value is not defined in the brand's config file OR if the config file is not available
  appConfigDefaults: {
    siteVersion: '1.0.0',
    brandId: 1901,
    f2pBrandId: 1999,
    activitySource: 'mobileplus',
    appActivitySource: 'mobileandroid',
    apiBaseUrl: {
      cms: 'https://cmsapi.betking.com/api/mobile',
      guardian: 'https://auth-api-betagy-sbdev3.betagy.co',
      f2pGuardian: 'https://auth-api-betagy-f2p-lotdev1.betagy.co',
      platform: 'https://website-api-betking-sbdev3.betagy.co',
      website: 'https://frontendservices-pldev1.betagy.co',
      engagement: 'https://dev1-eng.int.kingmakers.tech',
      sportsbook: 'https://sportsapi-sbdev3.betagy.co',
      sportsbookFeed: 'https://sportsapi-sbdev3.betagy.co',
      sportsbookWebSocket: 'https://st-push-test-sbdev2.kingmakers.tech',
      betRadarStatistics: 'https://s5.sir.sportradar.com/betking',
      betRadarResults: 'https://ls.fn.sportradar.com/betking',
      betRadarTeamLogo: 'https://img.sportradar.com/ls',
      betRadarWidgets: 'https://widgets.sir.sportradar.com/8291e456ef20ffd848b311f769db64c9/widgetloader',
      virtuals: 'https://virtualsapi-vrgr.betagy.co',
      virtualsFeed: 'https://virtualsapi-feeds-vrgr.betagy.co',
      virtualsWebSocket: 'wss://virtualsapi-sockets-vrgr.betagy.co/WebSockets/MobileNotifier.ashx',
      jackpotBets: 'https://lottery-api-jpbdev1.betagy.co',
      jackpotBetsCms: 'https://betking-cms-aks-test.kingmakers.tech',
      superPicksValidationDomain: 'https://frontendservices-f2p-lotdev1.betagy.co',
    },
    account: {
      accountNumberValidationRegex: '^[0-9]{10}$',
      insufficientBalanceThreshold: 500,
      delimiters: {
        CBEDeposit: [50, 100, 200, 500, 1000],
        CBEWithdrawal: [100, 200, 500, 1000, 5000],
        defaultDeposit: [500, 1000, 5000, 10000, 50000],
        defaultWithdrawal: [5000, 7000, 10000, 50000, 100000],
      },
      enableWithdrawalBankProfile: true,
      bankProfileBlackList: ['master', 'superAgent', 'cashier', 'subAccountPlayer'],
      accountTypes: {
        master: 'J_MASTER',
        superAgent: 'J_SUPER_AG',
        shopOwner: 'J_SHOP_OWN',
        cashier: 'J_CASHIER',
        subAccountPlayer: 'PLYSUB',
      },
      depositRedirect: '',
      profileEditability: 'Off', // Can be 'Off', 'One-Time', 'Always'
      mobilePhoneEditability: false,
      nameSurnameEditability: false,
      loginPrefix: '',
      loginType: 'Username', // Can be 'Username', 'UsernameOrMobile', 'Mobile'
      enableWinNotificationSetting: false,
      checkUserDataUponWithdraw: false,
      enableMessagingReplies: false,
      transactionListDefaultRangeInDays: 7,
      enableDepositDefaultRedirection: false,
      enableWithdrawalDefaultRedirection: true,
      mpesaExpressDeposit: {
        pollInterval: 5000,
        pollCountLimit: 6,
      },
      telebirrDeposit: {
        pollInterval: 5000,
        pollCountLimit: 6,
      },
      mobileVerificationCampaign: false,
      paymentsType: 'regular', // Can be 'regular' or 'userJourney',
      depositIncrementAmountValues: [10, 20, 50, 100, 1000],
      withdrawalIncrementAmountValues: [10, 20, 50, 100, 1000],
      paystackDeposit: {
        publicKey: '',
        momoPollInterval: 5000,
        momoPollTimeout: 180000,
        cardRepeatDepositPollInterval: 1000,
        cardRepeatDepositPollAttempts: 5,
      },
      paymentChannel: 'card',
      enableAutoWithdrawalMaximumWithdrawalsAllowed: false,
      kycVerificationFormUrl: '',
      enableWithdrawalNudge: false,
      withdrawalStatus: {
        pollInterval: 5000,
        pollCountLimit: 12,
      },
      depositStatus: {
        pollInterval: 3000,
        pollCountLimit: 10,
      },
      bankProfileStatus: {
        pollInterval: 1000,
        pollCountLimit: 5,
      },
    },
    appInsights: {
      enabled: false,
      instrumentationKey: '',
      androidInstrumentationKey: '',
      samplingPercentage: 0,
    },
    desktopUrl: '#',
    otp: {
      enableOTPbySms: false,
      otpCodeLength: 6,
      otpResendTimer: 60,
      otpAllowResendWithoutTimer: false,
      enableMobilePhoneVerification: false,
      allowUnconfirmedUsers: false,
      skipRegistrationOTP: false,
    },
    sports: {
      widgetDefaults: {
        landingPage: [
          {
            type: 'liveBetting',
            name: 'Live Now',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 6000,
            sortOrder: 1,
          },
          {
            type: 'mostPopular',
            name: 'Popular',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 2,
          },
          {
            type: 'upcomingEvents',
            name: 'Upcoming',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 3,
          },
          {
            type: 'oddsBoost',
            name: 'Boosted',
            isGrouped: false,
            numberOfEvents: 15,
            refreshInterval: 60000,
            sortOrder: 3,
          },
        ],
      },
      lastMinute: {
        count: 10,
        sportIds: [1, 2, 3, 4, 5, 16],
      },
      live: {
        betRadarWidgetHideIds: [3, 8, 13, 16, 19, 20, 21, 22, 24, 29, 31, 32, 34, 109, 111, 112],
        newAreaIds: [],
        extraScoresWhiteList: [5, 19, 20, 23],
        marketGroupingDetailsDefaults: [
          {
            sportID: 0,
            marketsCount: 10,
          },
        ],
      },
      statistics: {
        type: 2,
        enabled: true,
        maximumRows: 5,
      },
      coupon: {
        fixedStakes: [100, 200, 500, 1000, 2000],
        showStakeReduction: false,
        oddChangesTimer: 60000,
        liveOddChangesTimer: 30000,
        canBookWhenLoggedIn: true,
        defaultCurrency: 'NGN',
        stakeTaxPercentageToShow: '',
        showBookedBetRetailMessage: true,
        sendBookedCouponCode: false,
        winningsInfoBubble: true,
      },
      topCompetitionsLeagueCount: 10,
      allowSameMatchSelections: true,
      allowCombinationBets: true,
      freebets: {
        allowFreeBets: false,
        allowOnSingleBets: false,
        allowOnMultipleBets: false,
        allowOnSystemBets: false,
        allowCashout: false,
        freebetVoucherRetrievalstaleTimeInSeconds: 300,
        freebetCMSCallsTimeout: 5000,
        freebetVoucherCallTimeout: 5000,
      },
      enableCustomMenu: true,
      accumulatorBonus: {
        minOddValue: 1.2,
        showProgressionBar: true,
      },
      cashout: {
        blackListedUserTypes: ['J_CASHIER'],
        enabled: true,
        unavailableCodes: [200, 113],
      },
      myBets: {
        couponCount: 20,
        pollLiveDetails: false,
        pollLiveDetailsInterval: 6000,
      },
      bookedBets: {
        enabled: true,
      },
    },
    jackpotBets: {
      enabled: false,
      userWhiteListEnabled: false,
      playSourceHeader: 1,
      myBets: {
        couponCount: 20,
      },
      bookedBets: {
        enabled: false,
      },
    },
    xSell: {
      casino: {
        enabled: true,
        showWhenLoggedIn: true,
        showWhenLoggedOut: false,
        whitelist: ['live'],
      },
    },
    messages: {
      defaultPageAmount: 10,
    },
    registration: {
      defaultCountry: 'NGA',
      disableInternationalNumbers: 'true',
      mobileRegex: '^0?[0-9]{10}$',
      deadlineDate: '',
      successRedirectUrl: '/help/getting-started',
      privacyPolicyUrl: '/help/terms-and-conditions',
      termsAndConditionsUrl: '',
      registrationRedirect: '',
      registrationType: 'normal', // can be 'normal', 'quick', 'split', 'userJourney'
      showDNDNotice: true,
      tutorialButtonHighlight: false,
      promoCodeToggleVisibility: false,
    },
    // The applicationServiceMethod means that the menu item will call a method in the application service
    // If you adding a new applicationServiceMethod calls for an item, you need to declare the given method in the application service
    menuDefaults: {
      landingPage: [
        {
          title: 'Soccer',
          icon: 'sport',
          linkURL: '/sports/soccer',
        },
        {
          title: 'Live',
          icon: 'live',
          linkURL: '/live',
        },
        {
          title: 'Today',
          icon: 'today',
          linkURL: '/sports/todays-events',
        },
        {
          title: 'Virtual League',
          icon: 'league',
          linkURL: '/virtual/league',
        },
        {
          title: 'Virtual Offering',
          icon: 'virtual',
          linkURL: '/virtual',
        },
        {
          title: 'Book a Bet',
          icon: 'book-a-bet',
          linkURL: '/book-bet',
        },
        {
          title: 'Odds Less Than',
          icon: 'odds-less-than',
          linkURL: '/odds-less-than',
        },
        {
          title: 'Search',
          icon: 'search',
          linkURL: '/search',
        },
        {
          title: 'Last Minute',
          icon: 'last-minute',
          linkURL: '/last-minute',
        },
        {
          title: 'Quick Bet',
          icon: 'quick-bet',
          linkURL: '/quick-bet',
        },
        {
          title: 'Coupon Check',
          icon: 'coupon-check',
          linkURL: '/coupon-check',
        },
        {
          title: 'News',
          icon: 'news',
          linkURL: 'http://news.betking.com/',
        },
        {
          title: 'Live Chat',
          icon: 'live-chat',
          functionName: 'loadLiveChat',
        },
        {
          title: 'Help',
          icon: 'help',
          linkURL: '/help',
        },
      ],
      sidebarQuicklinks: [],
    },
    correctScoreAreaIds: [537, 1104, 1103, 567, 584, 632, 959],
    live: {
      pollingTimer: 6000,
      useServerSideCoupon: false,
    },
    selectAllMaxItemCount: 50,
    liveChat: {
      liveChatIntegration: 'sfk',
      sfk: {
        buttonId: '5730C000000002C',
        deploymentId: '5720C000000002H',
        organizationId: '00D0C0000000XV5',
      },
    },
    logging: {
      enabled: true,
      minLogLevel: 'Warning',
      endpoints: ['AppInsights'],
    },
    cmsCacheTTL: 1800,
    cookieLastClick: true,
    cookieFirstClick: false,
    affiliateCookieExpiry: 30,
    fallbackTimer: 10,
    enableDataLayer: true,
    enableOTPOnCall: false,
    enableSlideUps: false,
    enableQuickCouponForPrematch: false,
    enableQuickCouponForLive: true,
    maximumNumberOfToasts: 5,
    canonicalUrl: '',
    nativeApp: {
      appUrl: '',
      appDownloadUrl: '',
      daysOfMaxSessionLength: 7,
    },
    languages: [
      {
        language: 'en',
        languageDescription: 'English',
        locale: 'en-us',
      },
    ],
    social: {
      facebook: 'https://www.facebook.com/Betking-979151908905922',
      twitter: 'https://twitter.com/BetKingNG',
      instagram: 'https://www.instagram.com/betkingng',
      youtube: 'https://www.youtube.com/channel/UCH9QkG2k4oh_kU39IUXjomg',
    },
    registrationRedirect: '',
    footer: {
      showPaymentMethodImage: true,
      leagueOneSponsor: undefined,
      leagueTwoSponsor: undefined,
      showAwards: false,
    },
    virtuals: {
      enabled: true,
      fixedStakes: [20, 100, 200, 500, 1000],
      winningsInfoBubble: true,
      disabledLeaguesForSubAccountPlayers: ['kings-bundliga', 13130, 'kings-insta-live-league', 'kings-insta-liga'],
      eventsTimingsThreshold: 1000,
      breadcrumbNavigationDefaults: {
        title: 'Virtual',
        url: '/virtual',
        uniqueValue: 'virtual',
        subMenu: [
          {
            title: 'Scheduled Leagues',
            url: '/virtual/league',
            uniqueValue: 'virtualScheduled',
            subMenu: [
              {
                title: 'Kings League',
                url: '/virtual/league/kings-league',
                isDefaultGame: true,
                uniqueValue: 'virtualScheduledLeague',
              },
            ],
          },
          {
            title: 'Instant Leagues',
            url: '/virtual/instant/kings-insta-live-league',
            uniqueValue: 'virtualInstant',
            subMenu: [
              {
                title: 'Kings InstaLeague',
                url: '/virtual/instant/kings-insta-live-league',
                isDefaultGame: true,
                uniqueValue: 'virtualInstantKingsLeague',
              },
            ],
          },
          {
            title: 'Games',
            url: '/virtual/games',
            uniqueValue: 'virtualGames',
            subMenu: [
              {
                title: 'Spin2Win',
                url: '/virtual/games/spin-2-win',
                isDefaultGame: true,
                uniqueValue: 'virtualGamesSpin2Win',
              },
            ],
          },
          {
            title: 'Racing',
            url: '/virtual/racing',
            uniqueValue: 'virtualRacing',
            subMenu: [
              {
                title: 'Dog Racing',
                url: '/virtual/racing/greyhounds-6',
                isDefaultGame: true,
                uniqueValue: 'virtualRacingGreyhounds',
              },
            ],
          },
        ],
      },
      menuSectionsDefaults: [
        {
          sectionName: 'Scheduled Football Leagues',
          icon: 'soccer',
          sectionGames: [
            {
              game: 'kings-league',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/nygkg2t2/kings-league.svg',
              isNew: false,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
            {
              game: 'kings-liga',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/dd2m4zwg/kings-liga.svg',
              isNew: false,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
            {
              game: 'kings-italiano',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/akohgqvy/kings-italiano.svg',
              isNew: false,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
            {
              game: 'kings-bundliga',
              gameTitle: '',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/ai3d4zdv/kings-bundliga.svg',
              isNew: true,
              showLoginDialog: false,
              showDemoCTA: false,
              isOnDemandGame: false,
            },
          ],
        },
        {
          sectionName: 'Instant Football Leagues',
          icon: 'soccer',
          sectionGames: [
            {
              game: 'kings-insta-live-league',
              gameTitle: 'KINGS INSTALEAGUE',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/ehyass1e/kings-instaleague-logo-full.svg',
              isNew: false,
              showLoginDialog: true,
              showDemoCTA: false,
              isOnDemandGame: true,
            },
            {
              game: 'kings-insta-liga',
              gameTitle: 'KINGS INSTALIGA',
              thumbnailUrl: 'https://web-btk-cmshl-app-prod-staging.azurewebsites.net/media/vlnk5whe/kings-instaliga-logo-full.svg',
              isNew: false,
              showLoginDialog: true,
              showDemoCTA: false,
              isOnDemandGame: true,
            },
          ],
        },
      ],
      scheduledLeague: {
        enabled: false,
        assetsUrl: '/assets/mvs/',
        mountingPoint: '/virtual/league',
        defaultLeagueId: 2,
        showLivePlayCTA: false,
        showStats: false,
        gameTime: 90,
        playoutTimeMs: 30000,
        countDownHeartBeatMs: 5000,
        minutesBetweenWeeks: 3,
        inPlayResultsDurationMs: 3000,
        recentBetsStorageKey: 'mvsRecentBets',
        defaultCurrency: 'NGN',
        showMinOddAsMissingOdd: false,
        useVirtualsNavbar: false,
        freebets: {
          allowFreeBets: true,
          allowOnSingleBets: true,
          allowOnMultipleBets: true,
          allowOnSystemBets: false,
          freebetVoucherRetrievalstaleTimeInSeconds: 300,
          freebetCMSCallsTimeout: 30000,
          freebetVoucherCallTimeout: 30000,
        },
        accumulatorBonus: {
          minOddValue: 1.2,
          showProgressionBar: true,
          whiteList: ['league', 'kings-league', 'kings-italiano', 'kings-liga', 'kings-bundliga'],
        },
        categories: {
          '2': {
            url: 'kings-league',
            theme: {
              themeColorPrimary: '#2662d9',
              themeColorSecondary: '#121d73',
              textColor: '#fff',
            },
          },
          '3': {
            url: 'kings-liga',
            theme: {
              themeColorPrimary: '#E51C3E',
              themeColorSecondary: '#930241',
              textColor: '#fff',
            },
          },
          '4': {
            url: 'kings-italiano',
            theme: {
              themeColorPrimary: '#269dd9',
              themeColorSecondary: '#2863B5',
              textColor: '#fff',
            },
          },
          '13110': {
            url: 'kings-italiano',
            theme: {
              themeColorPrimary: '#269dd9',
              themeColorSecondary: '#2863B5',
              textColor: '#fff',
            },
          },
          '14102': {
            url: 'kings-euro',
            theme: 'champions',
          },
          '13130': {
            url: 'kings-bundliga',
            theme: {
              themeColorPrimary: '#E51C3E',
              themeColorSecondary: '#930241',
              textColor: '#fff',
            },
          },
        },
      },
      instantLeague: {
        enabled: false,
        mountingPoint: '/virtual/instant',
        defaultLeagueId: 'GROLeagueEngland',
        recentBetsStorageKey: 'mvsInstantRecentBets',
        winningsStorageKey: 'mvsInstantWinningsData',
        webSocketActiveUrls: [
          'instant',
          'kings-insta-live-league',
          'kings-insta-liga',
          'my-bets',
          'virtuals-bet-search',
          'bet-search',
          'instant-coupon',
        ],
        userDataStorageKey: 'mvsInstantUserData',
        couponDataStorageKey: 'mvsInstantCouponData',
        couponExtraDataStorageKey: 'mvsInstantCouponExtraData',
        lastEventAndCategoryStorageKey: 'mvsLastEventAndSelectedCategory',
        numberOfOpenInPlayFixtures: 5,
        sessionSynchronizeInterval: 120000,
        videoSlotsStartingTime: [0, 15, 30, 45, 60, 75],
        showMinOddAsMissingOdd: true,
        useVirtualsNavbar: false,
        accumulatorBonus: {
          minOddValue: 1.01,
          lastPercentageItemShown: 40,
          showProgressionBar: true,
          whiteList: ['instant', 'kings-insta-live-league', 'kings-insta-liga'],
        },
        categories: {
          GROLeagueEngland: {
            url: 'kings-insta-live-league',
            theme: {
              themeColorPrimary: '#2662d9',
              themeColorSecondary: '#121d73',
              textColor: '#fff',
            },
            name: 'InstaLeague',
            displayOrder: 1,
          },
          GROLeagueSpain: {
            url: 'kings-insta-live-league',
            theme: {
              themeColorPrimary: '#ffc400',
              themeColorSecondary: '#CC6D00',
              textColor: '#fff',
            },
            name: 'InstaLiga',
            displayOrder: 2,
          },
        },
        webSocketConfig: {
          host: 'wss://vi-proxy.betking.com/vs',
          basePath: '/api/external/v2',
          reconnection: {
            delay: 5000,
          },
          timeout: 60000,
          debug: true,
        },
      },
      globalBet: {
        enabled: false,
        enabledGames: [],
      },
      goldenRace: {
        enabled: false,
        enabledGames: [],
        disabledGamesForSubAccountPlayers: ['keno', 'spin-2-win', 'dog-racing'],
        launcherUrl: '',
        phk: '',
        iframeDisplayTimeout: 2000,
      },
      showBetslipLoginDialog: true,
      jackpot: {
        jackpotWonTextDuration: 3500,
        jackpotDropDuration: 2500,
        jackpotDropValueTransition: 1000,
        jackpotIncrementValueTransition: 2000,
        jackpotApiCallInterval: 60000,
        updatesPerSec: 0.01,
        jackpotsRotationInterval: 180000,
        enableJackpotCategories: [2, 3, 4],
        dukeJackpotId: 5,
        kingmakerJackpotId: 6,
        jackpotShortName: 'Jackpot',
        jackpotLongName: 'Jackpot',
        jackpotPluralName: 'Jackpots',
      },
      enableWebSocketConnection: false,
      showExcludingTaxLabels: false,
      cashout: {
        enabled: false,
      },
      myBets: {
        couponCount: 20,
        pollLiveDetails: false,
        pollLiveDetailsInterval: 6000,
        instantLeagueDaysToSearch: 21,
      },
      bookedBets: {
        enabled: false,
      },
      experiments: {
        // declare the list of active experiments here, including the experimentId and the defaultValue to use.
        // Example:
        //    leagueSelector: {
        //      experimentId: '',
        //      defaultValue: '0',
        //    },
      },
      defaultGames: {
        games: 'spin-2-win',
        racing: 'greyhounds-6',
      },
    },
    geoAccessControl: {
      accessBlockingEnabled: false,
      tldRedirectionEnabled: false,
      siteCountryCode: '',
    },
    googleOptimize: {
      retryAttempts: 3,
      retryDelay: 500,
    },
    geolocationRedirect: {}, // TODO: Phase this out in favour of the newer 'geoAccessControl'
    accessDownloadPageEnabled: false,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
