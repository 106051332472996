<span class="odds-boost-badge" *ngIf="odd?.isBoosted"></span>
<span class="odd-value" [class.boosted]="odd.isBoosted" *ngIf="odd; else missingOdd">
  {{ odd.value | formatOdd }}
  <span class="old-odd-value" *ngIf="odd.isBoosted">
    <br>{{ odd.unboostedValue | formatOdd }}
  </span>
</span>

<ng-template #missingOdd>
  <span i18n="Missing odd indicator">-</span>
</ng-template>
