import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponEditService } from 'src/app/core/services/coupon/coupon-edit.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { MatchService } from 'src/app/core/services/match.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { expandCollapse } from 'src/app/shared/animations';
import { Router } from '@angular/router';
import { CouponGroupingType, ExpiredEvents, OddModel } from 'src/app/shared/models/coupon.model';
import { AreaModel, MatchModel, SportModel, TournamentModel } from 'src/app/shared/models/sport.model';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { LiveService } from 'src/app/core/services/live.service';
import { LiveQuery } from 'src/app/core/state/live/live.query';

@Component({
  selector: 'app-coupon-selections',
  templateUrl: './coupon-selections.component.html',
  styleUrls: ['./coupon-selections.component.scss'],
  animations: [expandCollapse()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponSelectionsComponent implements OnInit {
  @Input() bookedCoupon: boolean = false;

  groupingType: typeof CouponGroupingType = CouponGroupingType;
  hasOddChanges$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // Although not currently being dynamically set, in the next phases of the rebrand we will have different colors
  // for selections.
  marketBgColor = '#fff';

  constructor(
    private readonly router: Router,
    readonly couponQuery: CouponQuery,
    private readonly couponStore: CouponStore,
    private readonly matchService: MatchService,
    private readonly couponService: CouponService,
    private readonly couponEditService: CouponEditService,
    private readonly applicationService: ApplicationService,
    private readonly liveService: LiveService,
    private readonly liveQuery: LiveQuery
  ) {}

  ngOnInit(): void {
    this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());

    this.couponQuery.oddChanges$.subscribe(oddChanges => {
      if (oddChanges) {
        this.hasOddChanges$.next(this.couponQuery.couponContainsOddChanges());
      }
    });
  }

  parseSelectionName(selectionName, eventName): string {
    const teams = eventName.split(' - ');

    const homeTeam = teams[0];
    const awayTeam = teams.length > 1 ? teams[1] : undefined;

    return this.applicationService.parseSelectionName(selectionName, homeTeam, awayTeam);
  }

  updateOddBankerStatus(oddId: number, banker: boolean): void {
    if (!this.bookedCoupon) {
      this.couponService.updateOddBankerStatus(oddId, banker);
    }
  }

  removeOdd(selectionId: number): void {
    if (!this.bookedCoupon) {
      this.couponService.removeOdd(selectionId);
    }
  }

  selectionCount(marketId: number, matchOdds: any): boolean {
    let numOfSelections: number = 0;

    matchOdds.forEach(odd => {
      if (odd.marketId === marketId) {
        numOfSelections = numOfSelections + 1;
      }
    });

    return numOfSelections === 1;
  }

  clearExpiredEvents(): void {
    this.couponService.clearExpiredEvents();
  }

  eventTrackBy(index: number, match: ExpiredEvents): number {
    return match.eventId;
  }

  selectionTrackBy(index: number, item: SportModel): number {
    return item.id;
  }

  tournamentTrackBy(index: number, item: TournamentModel): number {
    return item.id;
  }

  matchTrackBy(index: number, item: MatchModel): number {
    return item.id;
  }

  oddTrackBy(index: number, item: OddModel): number {
    return item.id;
  }

  indexTrackBy(index: number): number {
    return index;
  }

  editOdd(smartBetCode: number, marketTypeId: number, spreadValue?: number): void {
    if (
      !(
        this.couponQuery.selectedForEdit !== undefined &&
        smartBetCode === this.couponQuery.selectedForEdit.matchId &&
        marketTypeId === this.couponQuery.selectedForEdit.marketTypeId
      )
    ) {
      this.couponEditService
        .getMarketSelections(smartBetCode, marketTypeId, spreadValue === undefined || spreadValue === 0 ? undefined : spreadValue)
        .subscribe();
      this.couponService.allowCompleteDeselectOfEventOdds = false;
    } else {
      this.couponEditService.clearEditData();
    }
  }

  clearEditData(): void {
    this.couponEditService.clearEditData();
  }

  goToPage(event, match): void {
    if (event && event.target) {
      const fixedContentHeight = 40 + 56; // fixed height for the breadcrumb and coupon header
      const height = (event.target.getBoundingClientRect().top as number) + document.documentElement.scrollTop;
      this.couponStore.update({ betslipScrollTop: height - fixedContentHeight });
    }
    if (match.eventCategory === 'L') {
      const areaId = this.liveQuery.liveAreaSelectionIds[match.odds[0].id] as number;
      const areaObject = areaId ? { id: areaId } : {};
      this.liveService.updateSelectedAreaInMatchView(areaObject as AreaModel);

      this.router.navigateByUrl(`/live/${match.id}`);
    } else {
      this.matchService.navigateToMatch(match.id, match.name);
      this.applicationService.closeAnySlideUps();
    }
  }

  isInvalidFreebetSelection = (odd: OddModel, invalidSelections: number[]): boolean =>
    invalidSelections.some(invalidSelection => invalidSelection === odd.id);
}
