import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { uniq } from 'lodash-es';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable({
  providedIn: 'root',
})
export class SportsCreateSIDsResolver implements Resolve<any> {
  constructor(
    private readonly couponService: CouponService,
    private readonly couponQuery: CouponQuery,
    private readonly loggerService: LoggerService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    // Get selection Ids, trim and remove duplicates
    const selectionIds = route.params.selectionIds
      .split(',')
      .filter((val: string) => val.trim().length > 0)
      .map((val: string) => parseInt(val.trim(), 10));

    if (this.couponQuery.couponData && this.couponQuery.couponData.Odds) {
      this.couponQuery.couponData.Odds.forEach(odd => selectionIds.push(odd.SelectionId));
    }

    if (selectionIds.length > 0) {
      this.couponService
        .createCouponFromSelectionIds(
          uniq(selectionIds).map((val: number) => ({ SelectionId: val })),
          route.params.stake
        )
        .subscribe(
          () => false,
          () => {
            this.loggerService.logEvent(
              'Failed to create coupon from external Selection IDs',
              route.params.selectionIds,
              SeverityLevel.Error,
              LogTypeModel.Application
            );
          },
          () => {
            if (typeof URLSearchParams !== 'undefined') {
              // clean URL
              const url = new URL(document.location as any);
              url.searchParams.delete('createSIDs');
              url.searchParams.delete('stake');
              window.history.replaceState({}, document.title, `${url.origin}${url.search ? `${url.search}` : ''}`);
            }
          }
        );
    }
  }
}
