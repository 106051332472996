<div class="cashout-container" *ngIf="item$ | async as item" [@expandCollapseSimpler]>
  <div *ngIf="item.betFinalState === betFinalState.Placed">
    <!-- Cashout requested section -->
    <div class="content" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Requested">
      <div class="cash-out-details">
        <button class="cash-out-button disabled">
          <div class="label" i18n>Cash Out In Evaluation</div>
        </button>
        <span class="cash-out-spinner"></span>
      </div>
    </div>
    <!-- Cashout available section -->
    <!-- enableCashout$ - has been moved to the parent node to avoid empty padding since the disclaimer has been hidden below -->
    <div class="content" *ngIf="(enableCashout$ | async) && item.cashoutStatusTab === cashoutStatusTab.Available">
      <div class="cash-out-details">
        <button
          class="cash-out-button"
          (click)="onCashout(item)"
          [class.disabled]="item.cashoutInProgress || (enableCashout$ | async) === false || item.betCashout.allowCashout === false"
        >
          <div class="label" i18n>Cash Out:</div>
          <div class="value">{{ item.betCashout.valueNet | currencyFormat }}</div>
        </button>
        <button class="cash-out-refresh material-icons" (click)="onRefreshCashout(item)">{{ 'refresh' }}</button>
      </div>

      <!-- this section has been hidden via CSS as requested -->
      <div class="cashout-unavailable-disclaimer" *ngIf="(enableCashout$ | async) === false">
        <!-- Cashout blocked disclaimer section -->
        <span *ngIf="(cashoutWhitelist$ | async) === false" i18n>Account not eligible for cashout</span>
        <span
          i18n
          *ngIf="
            (cashoutWhitelist$ | async) &&
            ((cashoutLoggedIn$ | async) === false || (cashoutSameUser$ | async) === false) &&
            (accountQuery.isShopOwner$ | async) === false
          "
        >
          Please log in with coupon user's account to cashout
        </span>
        <span
          i18n
          *ngIf="(cashoutWhitelist$ | async) && (accountQuery.isShopOwner$ | async) && (cashoutShopOwnerSubUser$ | async) === false"
        >
          Coupon not created by a user of your shop
        </span>
      </div>
    </div>
    <!-- Cashout unavailable section or Running Market -->
    <div class="content" *ngIf="item.cashoutStatusTab === cashoutStatusTab.Unavailable || item.betCashout.result === 312">
      <div class="cash-out-details">
        <button class="cash-out-button disabled">
          <div class="label" i18n>Cash Out Unavailable</div>
        </button>
        <button class="cash-out-info material-icons" (click)="toggleMoreInfo()">{{ 'info' }}</button>
      </div>

      <div class="cash-out-value" *ngIf="showMoreInfo$ | async" [@expandCollapseSimpler]>
        <ng-container *ngIf="item.betCashout.result === 312; else message">
          <div class="label" i18n>Running market not allowed for cashout</div>
        </ng-container>
        <ng-template #message>
          <div class="label">{{ item.betCashout.message }}</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
