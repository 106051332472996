<div class="recent-bets-container" *ngIf="bets" data-testid="my-bets-recent-bets">
  <div class="filter-bets">
    <input type="text" placeholder="Filter Bets" [(ngModel)]="filterBets" />
  </div>
  <app-loading-bar [show]="loading"></app-loading-bar>
  <ng-container *ngIf="!loading">
    <div class="bet-content">
      <!-- In Evaluation Coupons -->
      <ng-container *ngIf="evaluationQuery.evaluationList$ | async as inEvaluationCoupons">
        <ng-container *ngIf="inEvaluationCoupons && inEvaluationCoupons.length > 0 && !isSettled">
          <div class="wrapper in-evaluation">
            <div class="items" *ngFor="let item of inEvaluationCoupons | filterSearch: filterBets; trackBy: myBetsTrackBy">
              <ng-container *ngIf="item.coupon.updatedBetCoupon as couponItem">
                <div class="title-overlay">
                  <span i18n>One of our traders is reviewing your bet</span>
                </div>
                <div class="bet">
                  <div class="bet-details">
                    <div class="status-type-odds">
                      <div class="status-type">
                        <span class="total-combinations" *ngIf="couponItem.CouponTypeId === couponType.System">{{
                          couponItem.TotalCombinations
                        }}</span>
                        <span class="bet-type">{{ couponItem.CouponType || getCouponType(couponItem.CouponTypeId) }}</span>
                      </div>
                      <div class="odds">
                        <div class="total-odds" *ngIf="couponItem.CouponTypeId !== couponType.System">
                          <div class="label" i18n>Odds:</div>
                          <div class="value">
                            <span [innerHTML]="couponItem.TotalOdds | number: '1.2-2' | highlight: filterBets"></span>
                          </div>
                        </div>
                        <div class="total-odds" *ngIf="couponItem.couponTypeId === couponType.System && couponItem">
                          <div class="label" i18n>Odds:</div>
                          <div class="value">
                            <span [innerHTML]="couponItem.MaxOdd | number: '1.2-2' | highlight: filterBets"></span>
                          </div>
                        </div>
                        <span class="expand-collapse-bet material-icons">{{ 'expand_more' }}</span>
                      </div>
                    </div>

                    <div class="bet-overview">
                      <div class="stake">
                        <div class="label" i18n>Stake:</div>
                        <div class="value">
                          <span [innerHTML]="couponItem.StakeGross | currencyFormat | highlight: filterBets"></span>
                        </div>
                      </div>
                      <div class="pot-win">
                        <div class="label" i18n>Potential Win:</div>
                        <div class="value">
                          <span [innerHTML]="couponItem.MaxWinNet | currencyFormat | highlight: filterBets"></span>
                        </div>
                      </div>
                    </div>

                    <div class="logo-container">
                      <span class="logo"></span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <!-- Normal Coupons -->
      <div class="wrapper">
        <my-bets-recent-bet
          *ngFor="let item of bets | filterSearch: filterBets; trackBy: myBetsTrackBy"
          [item]="item"
          [collapsible]="true"
          [filterBets]="filterBets"
          [isRebetEnabled]="isRecentBetRebetEnabled(item)"
          [liveDetails]="myBetsQuery.expandedLiveBetsDetails$ | async"
          [loadCollapsed]="!autoExpandCoupon(item.couponCode)"
          [isVirtualsInstantLeague]="isVirtualsInstantLeague"
          (getBetDetails)="getBetDetails($event)"
          (toggleBetInfo)="toggleBetInfo($event)"
          (navigateToBetDetails)="navigateToBetDetails($event)"
        ></my-bets-recent-bet>
      </div>
    </div>

    <my-bets-no-bets
      *ngIf="bets.length === 0"
      i18n-tabName
      data-testid="my-bets-bo-bets"
      [tabName]="isSettled ? betsTab.Settled : betsTab.Open"
      [productName]="productTabSelected"
    ></my-bets-no-bets>

    <div class="bet-search" *ngIf="productTabSelected !== productType.JackpotBets">
      <div class="label">
        <span i18n>A maximum of </span>
        {{ myBetsQuery.couponCount }}
        <span i18n> Bets are shown. Go to </span>
        <a (click)="navigateBetList()" i18n>Betlist</a>
        <span i18n> to view all bets</span>
      </div>
    </div>
  </ng-container>
</div>
