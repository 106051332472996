<x-sell-swiper
  [hidden]="(showSwiper$ | async) === false"
  [xSellIconURL]="xSellQuery.xSellIcon$ | async"
  [xSellIconBGColor]="xSellQuery.xSellColor$ | async"
  [spbIconURL]="xSellQuery.spbIcon$ | async"
  [spbIconBGColor]="xSellQuery.spbColor$ | async"
>
  <div class="content-wrapper" x-sell-content>
    <div *ngIf="swiperContentHasBeenShown$ | async">
      <iframe [src]="xSellQuery.contentURL$ | async | safeUrl"></iframe>
    </div>
  </div>
</x-sell-swiper>
