import { OddModel } from './coupon.model';

export class SearchState {
  searchResults: SearchResult[];
  searchKeyword: string;
  ui: SearchUIState;

  constructor(init: Partial<SearchState>) {
    Object.assign(this, init);
  }
}

export class SearchUIState {
  noSearchResultsFor: string;
  lastBadgeClicked: string;
  searchIsLoading: boolean;

  constructor(init: Partial<SearchUIState>) {
    Object.assign(this, init);
  }
}

export class SearchResult {
  sportName: string;
  data: SearchResultEvent[];

  constructor(init: Partial<SearchResult>) {
    Object.assign(this, init);
  }
}

export class SearchResultEvent {
  idEvent: number;
  eventName: string;
  teamHome: string;
  teamAway: string;
  eventDate: string;
  idEventType: number;
  idSport: number;
  sportName: string;
  idTournament: number;
  tournamentName: string;
  idCategory: number;
  categoryName: string;
  totalOdds: number;
  markets: Market[];
  smartBetCode: number;
  externalID: number;

  constructor(init: Partial<SearchResultEvent>) {
    Object.assign(this, init);
  }
}

export class OddsType {
  oddsTypeID: number;
  oddsTypeName: string;
  oddsDescription: string;
  isOutright: boolean;
  isGoalScorer: boolean;
  idGroup?: any;
  idGroupMarketType: number;
  templateType: number;
  oddsTypeOrder: number;
  onlyRegularTime: boolean;
  multilineType: number;

  constructor(init: Partial<OddsType>) {
    Object.assign(this, init);
  }
}

export class Market {
  oddsID: number;
  oddsType: OddsType;
  specialBetValue: number;
  specialValueDisplay: string;
  markets: OddModel[];
  gamePlay: number;

  constructor(init: Partial<Market>) {
    Object.assign(this, init);
  }
}

export type RecentSearchTheme = 'light' | 'dark';
