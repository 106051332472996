import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { EventModel, SelectionModel } from 'src/app/shared/models/live.model';

@Component({
  selector: 'app-live-odds',
  templateUrl: './live-odds.component.html',
  styleUrls: ['./live-odds.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LiveOddsComponent {
  @Input() event: EventModel;
  @Input() eventLocation: string = undefined; // Used for data layer events

  selectionTrackBy(index: number, selection: SelectionModel): number {
    return selection.id;
  }

  oddTrackBy(index: number, odd: OddModel): number {
    return odd.id;
  }
}
