import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { FreebetsQuery } from 'src/app/modules/freebets/state/freebets.query';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';

@Component({
  selector: 'freebets-voucher',
  templateUrl: './freebets-voucher.component.html',
  styleUrls: ['./freebets-voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreebetsVoucherComponent implements OnInit, OnDestroy {
  @Input() code = '';
  @Input() name = '';
  @Input() expirationDate = '';
  @Input() amount = 0;

  @Input() showExpirationDate: boolean;
  @Input() showToggle: boolean;

  @Input() readonly productType: FreeBetProductType;
  @Output() readonly clickToggle = new EventEmitter();

  readonly isSelected$ = new BehaviorSubject(false);

  private readonly _destroy$ = new Subject<boolean>();

  constructor(private readonly freebetQuery: FreebetsQuery) {}

  handleClickToggle(): void {
    this.clickToggle.emit(this.code);
  }

  ngOnInit(): void {
    this.freebetQuery
      .selectedFreebetVoucher$(this.productType)
      .pipe(
        tap(selectedVoucher => {
          this.isSelected$.next(selectedVoucher ? selectedVoucher.code === this.code : false);
        }),
        takeUntil(this._destroy$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
