import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CorrectScoreEventService } from 'src/app/core/services/correct-score-event.service';

import { catchError, finalize, first } from 'rxjs/operators';

import { kebabCase } from 'lodash-es';
import { throwError } from 'rxjs';
import { APIType } from 'src/app/shared/models/api.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CategoryModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SelectionModel,
  SportModel,
  TournamentModel,
} from 'src/app/shared/models/sport.model';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APIService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class MatchService {
  constructor(
    private readonly router: Router,
    private readonly apiService: APIService,
    private readonly sportStore: SportStore,
    private readonly correctScoreEventService: CorrectScoreEventService
  ) {}

  collapseAll(): void {
    this.sportStore.updateUI({
      preMatchCloseAll: true,
    });
  }

  expandAll(): void {
    this.sportStore.updateUI({
      preMatchCloseAll: false,
    });
  }

  clearError(): void {
    this.sportStore.setError(undefined);
  }

  clearData(): void {
    this.sportStore.updateMatch(undefined);
    this.sportStore.updateUI({ preMatchCloseAll: false });
  }

  navigateToMatch(matchId: number, name?: string): void {
    // In case navigation happens from Sports section, this must always be set to false
    // so that navigating using the back button still works.
    this.sportStore.updateEventSelection({ filterToDepthFour: false });

    const kebabCaseName = kebabCase(name);
    this.router.navigate([`sports/match/${matchId}${kebabCaseName ? `/${kebabCaseName}` : ''}`]);
  }

  getMatch(matchId: number): void {
    this.sportStore.setLoading(true);

    this.apiService
      .get(APIType.SportsbookFeed, `api/feeds/prematch/lite/event/ungrouped/en/4/${matchId}`)
      .pipe(
        first(),
        catchError(error => {
          this.sportStore.setError(error);
          return throwError(error);
        }),
        finalize(() => {
          this.sportStore.setLoading(false);
        })
      )
      .subscribe(data => {
        const match: SportModel = new SportModel({});
        const odds = this.mapOddsDataToModel(data.AM[0].IT[0], data.AM[0].SI, data.AM[0].SN);
        // There will only be one sport, category, and tournament for a single match
        match.id = data.AM[0].SI;
        match.name = data.AM[0].SN;
        match.groupingType = data.AM[0].GT;
        match.categories = [
          new CategoryModel({
            id: data.AM[0].IT[0].CI,
            name: data.AM[0].IT[0].CN,
            tournaments: [
              new TournamentModel({
                id: data.AM[0].IT[0].TI,
                name: data.AM[0].IT[0].TN,
                regions: this.mapMarketDataToModel(data.AM[0].IT[0].OC),
                matches: [
                  new MatchModel({
                    id: data.AM[0].IT[0].II,
                    date: data.AM[0].IT[0].ID,
                    name: data.AM[0].IT[0].IN,
                    smartBetCode: data.AM[0].IT[0].SB,
                    eventCategory: data.AM[0].IT[0].EC,
                    categoryName: data.AM[0].IT[0].CN,
                    tournamentName: data.AM[0].IT[0].TN.trim(),
                    externalId: data.AM[0].IT[0].EI,
                    extParentItemID: data.AM[0].IT[0].PI,
                    extParentTeamID: data.AM[0].IT[0].PT,
                    extTeamOrder: data.AM[0].IT[0].TO,
                    odds,
                    correctScoreOdds: this.correctScoreEventService.parseOddsForCorrectScoreView(odds),
                  }),
                ],
              }),
            ],
          }),
        ];
        this.sportStore.updateMatch(match);
      });
  }

  filterMarkets(filterQuery: string): void {
    this.sportStore.updateUI({
      filterQuery,
    });
  }

  private mapMarketDataToModel(data: any): RegionModel[] {
    return [
      new RegionModel({
        isDefault: true,
        areas: [
          new AreaModel({
            isDefault: true,
            markets: data.map(
              oc =>
                new MarketModel({
                  id: oc.OT.OI,
                  name: oc.OT.ON,
                  marketType: oc.OT.TT, // Maps to MultilineType
                  spreadValue: oc.SB,
                  spreadDisplayValue: oc.MO[0].OA.VD,
                  selections: oc.MO.map(
                    selection =>
                      new SelectionModel({
                        id: oc.OT.OI,
                        name: selection.OA.ON,
                        spreadValue: selection.OA.SV,
                        spreadDisplayValue: selection.OA.VD,
                      })
                  ),
                  description: oc.OT.OD,
                  typeId: oc.OT.MT,
                  order: oc.OO,
                })
            ),
          }),
        ],
      }),
    ];
  }

  private mapOddsDataToModel(data: any, sportId: number, sportName: string): OddModel[] {
    const odds: OddModel[] = [];
    data.OC.forEach(oddCollection => {
      oddCollection.MO.forEach(odd => {
        odds.push(
          new OddModel({
            id: odd.OI,
            value: odd.OT.OO,
            unboostedValue: odd.OT.UO,
            spreadValue: odd.OA.SV,
            sportId,
            sportName,
            categoryId: data.CI,
            categoryName: data.CN,
            tournamentId: data.TI,
            tournamentName: data.TN.trim(),
            matchId: data.II,
            matchName: data.IN,
            matchDate: data.ID,
            marketId: oddCollection.OI,
            marketTypeId: oddCollection.OT.OI,
            marketName: oddCollection.OT.ON,
            smartCode: odd.SB,
            eventCategory: data.EC,
            combinability: oddCollection.CB,
            selectionId: odd.OA.OI,
            selectionTypeId: odd.OT.OI,
            selectionName: odd.OA.ON,
            selectionDescription: odd.OA.SD ? this.parseSelectionDescription(data.IN, odd.OA.SD) : `${oddCollection.OT.ON} - ${odd.OA.ON}`,
            incompatibleEvents: data.IE,
          })
        );
      });
    });
    return odds;
  }

  /**
   * Parse odd's selection description, to include event specific information
   *
   * @param eventName Event name
   * @param selectionDescription Selection Description to parse
   */
  private parseSelectionDescription(eventName: string, selectionDescription: string): string {
    if (!!eventName && !!selectionDescription) {
      let parsedSelectionDescription = '';
      const startIndexofPlayer = eventName.lastIndexOf('[');
      const eventNameWithoutPlayer = eventName.slice(0, startIndexofPlayer !== -1 ? startIndexofPlayer : undefined).trim();
      const eventTeams = eventNameWithoutPlayer.split('-');

      // Player
      parsedSelectionDescription = selectionDescription.replace(
        '{{PLAYER}}',
        eventName.includes('[') ? eventName.slice(startIndexofPlayer + 1, eventName.lastIndexOf(']')) : ''
      );
      // Home
      parsedSelectionDescription = parsedSelectionDescription.replace('{{HOME}}', eventTeams[0].trim());
      // Away
      parsedSelectionDescription = parsedSelectionDescription.replace('{{AWAY}}', eventTeams[1].trim());

      return parsedSelectionDescription;
    } else {
      return '';
    }
  }
}
