import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { LoginType } from 'src/app/shared/models/account.model';
import { ApplicationState, ApplicationUIState, CmsModel, EmbedConfig, LoaderState } from 'src/app/shared/models/application.model';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { MenuItemModel } from 'src/app/shared/models/menu.model';
import { environment } from 'src/environments/environment';
import { ApplicationStore, createInitialApplicationState } from './application.store';

@Injectable({
  providedIn: 'root',
})
export class ApplicationQuery extends Query<ApplicationState> {
  // UI
  loaderState$ = this.select(state => state.ui.loader);
  showMenu$ = this.select(state => state.ui.showMenu);
  showAccountMenu$ = this.select(state => state.ui.showAccountMenu);
  showLogin$ = this.select(state => state.ui.showLogin);
  showLoginDialog$ = this.select(state => state.ui.showLoginDialog);
  showCoupon$ = this.select(state => state.ui.showCoupon);
  showQuickCoupon$ = this.select(state => state.ui.showQuickCoupon);
  showMyBets$ = this.select(state => state.ui.showMyBets);
  showDropdownOverlay$ = this.select(state => state.ui.showDropdownOverlay);
  isVirtuals$ = this.select(state => state.activeProduct === ProductType.Virtuals);
  isVirtualsScheduled$ = this.select(
    state => state.activeProduct === ProductType.Virtuals && state.activeVirtualsLeague === VirtualsLeagueType.Scheduled
  );
  isVirtualsInstant$ = this.select(
    state => state.activeProduct === ProductType.Virtuals && state.activeVirtualsLeague === VirtualsLeagueType.Instant
  );
  isJackpotBets$ = this.select(state => state.activeProduct === ProductType.JackpotBets);
  inCoupon$ = this.select(state => state.ui.inCoupon);
  isSportsSection$ = this.select(state => state.ui.isSportsSection);
  loginState$ = this.select(state => state.ui.loginState);
  isZohoLiveChatLoading$ = this.select(state => state.ui.isZohoLiveChatLoading);
  loadingSFKChat$ = this.select(state => state.ui.loadingSFKChat);
  showSFKChat$ = this.select(state => state.ui.showSFKChat);
  isLoadingQuicklinks$ = this.select(state => state.ui.loadingQuicklinks);
  landingMenuExpanded$ = this.select(state => state.ui.landingMenuExpanded);
  scrollY$ = this.select(state => state.ui.scrollY);
  activeUrl$ = this.select(state => state.activeUrl);
  currency$ = this.select(() =>
    this.accountQuery.isAuthenticated
      ? this.accountQuery.userData.currency.code
      : this.appConfigService.get('sports').coupon.defaultCurrency
  );
  activeProduct$ = this.select(state => state.activeProduct);
  activeVirtualsLeague$ = this.select(state => state.activeVirtualsLeague);
  landingSiteMessages$ = this.select(state => state.siteMessages.landing);
  sportsSiteMessages$ = this.select(state => state.siteMessages.sports);
  cms$ = this.select(state => state.cms);
  sideBarMenu$ = this.select(state => state.sideBarMenu);
  isShowingNavbarBetslipSelections$ = this.select(state => state.ui.isShowingNavbarBetslipSelections);
  isShowingNavbarBetslipAccumulatorBonusBar$ = this.select(state => state.ui.isShowingNavbarBetslipAccumulatorBonusBar);
  isShowingAccumulatorBonusUnacceptableSelectionsPrompt$ = this.select(
    state => state.ui.isShowingAccumulatorBonusUnacceptableSelectionsPrompt
  );
  embedConfig$ = this.select(state => state.embedConfig);

  // this combineLatest needs to include the show states of all the existing slide up panels
  isSlideUpVisible$ = combineLatest([
    this.showMenu$,
    this.showAccountMenu$,
    this.showLogin$,
    this.showCoupon$,
    this.showMyBets$,
    this.showDropdownOverlay$,
    this.showSFKChat$,
  ]).pipe(map(states => states.findIndex(state => state) >= 0));

  constructor(
    protected store: ApplicationStore,
    private readonly appConfigService: AppConfigService,
    private readonly accountQuery: AccountQuery
  ) {
    super(store);
  }

  get showNavbar$(): Observable<boolean> {
    return combineLatest([this.select(state => state.ui.showNavbar), this.select(state => state.embedConfig)]).pipe(
      map(([showNavbar, embedConfig]) => showNavbar && !embedConfig?.hideNavbar)
    );
  }

  get isVirtualsLeague$(): Observable<boolean> {
    return combineLatest([this.isVirtualsScheduled$, this.isVirtualsInstant$]).pipe(
      map(([isVirtualsScheduled, isVirtualsInstant]) => isVirtualsScheduled || isVirtualsInstant)
    );
  }

  get slideUpResetObject(): ApplicationUIState {
    return createInitialApplicationState().ui;
  }

  get currency(): string {
    return this.accountQuery.isAuthenticated && this.accountQuery.userData.currency.name
      ? this.accountQuery.userData.currency.name
      : this.appConfigService.get('sports').coupon.defaultCurrency;
  }

  get landingMenuExpanded(): boolean {
    return this.getValue().ui.landingMenuExpanded;
  }

  get activeUrl(): string[] {
    return this.getValue().activeUrl;
  }

  get cms(): CmsModel {
    return this.getValue().cms;
  }

  get loaderState(): LoaderState {
    return this.getValue().ui.loader;
  }

  get showSFKChat(): boolean {
    return this.getValue().ui.showSFKChat;
  }

  get showMenu(): boolean {
    return this.getValue().ui.showMenu;
  }

  get showAccountMenu(): boolean {
    return this.getValue().ui.showAccountMenu;
  }

  get showLogin(): boolean {
    return this.getValue().ui.showLogin;
  }

  get showCoupon(): boolean {
    return this.getValue().ui.showCoupon;
  }

  get showQuickCoupon(): boolean {
    return this.getValue().ui.showQuickCoupon;
  }

  get showMyBets(): boolean {
    return this.getValue().ui.showMyBets;
  }

  get showDropdownOverlay(): boolean {
    return this.getValue().ui.showDropdownOverlay;
  }

  get isVirtuals(): boolean {
    return this.getValue().activeProduct === ProductType.Virtuals;
  }

  get isVirtualsScheduled(): boolean {
    return this.getValue().activeProduct === ProductType.Virtuals && this.getValue().activeVirtualsLeague === VirtualsLeagueType.Scheduled;
  }

  get isVirtualsInstant(): boolean {
    return this.getValue().activeProduct === ProductType.Virtuals && this.getValue().activeVirtualsLeague === VirtualsLeagueType.Instant;
  }

  get isJackpotBets(): boolean {
    return this.getValue().activeProduct === ProductType.JackpotBets;
  }

  get loginState(): string {
    return this.getValue().ui.loginState;
  }

  get hasSidebarQuicklinks(): boolean {
    return this.getValue().sideBarQuickLinks.length > 0;
  }

  get sideBarQuickLinks$(): Observable<MenuItemModel[]> {
    return this.select(state => state.sideBarQuickLinks).pipe(
      map((mim: MenuItemModel[]) =>
        mim.filter(item => (this.accountQuery.isAuthenticated ? item.showWhenLoggedIn : item.showWhenLoggedOut))
      )
    );
  }

  get activeProduct(): ProductType {
    return this.getValue().activeProduct;
  }

  get activeVirtualsLeague(): VirtualsLeagueType {
    return this.getValue().activeVirtualsLeague;
  }

  get loginType(): LoginType {
    return this.appConfigService.get('account').loginType;
  }

  get enableOTPBySMS(): boolean {
    return this.appConfigService.get('otp').enableOTPbySms;
  }

  get inCoupon(): boolean {
    return this.getValue().ui.inCoupon;
  }

  get inMyBets(): boolean {
    return this.getValue().ui.inMyBets;
  }

  get isSportsSection(): boolean {
    return this.getValue().ui.isSportsSection;
  }

  get skipRegistrationOTP(): boolean {
    return this.appConfigService.get('otp').skipRegistrationOTP;
  }

  get isCasinoXSellEnabled(): boolean {
    return this.appConfigService.get('xSell').casino.enabled;
  }

  get embedConfig(): EmbedConfig {
    return this.getValue().embedConfig;
  }

  /**
   * This helper method can be used to check if the current brand matches any of the brand names passed as a parameter.
   * If the array is empty then the function will return false.
   *
   * Example usage: <span *ngIf="applicationQuery.isBrand(['Nigeria', 'Kenya'])"></span>
   *
   * @param brandNames A string array of brand names to check against
   * @returns true if the current brand matches any of the supplied brand names, false otherwise
   */
  isBrand(brandNames: string[]): boolean {
    const brandId: number = this.getValue().brandId;
    const appBrandName: string = Object.keys(environment.brandMap).find(key => environment.brandMap[key] === brandId);
    if (!appBrandName || !brandNames) {
      return false;
    }
    return brandNames.find(brandName => brandName.trim().toUpperCase() === appBrandName.trim().toUpperCase()) !== undefined;
  }
}
