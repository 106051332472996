import { AreaModel, MarketModel, MatchModel, RegionModel } from 'src/app/shared/models/sport.model';

export class TodaysEventsState {
  areaMatches: AreaMatchModel[];
  regionsWithAreas: RegionsWithAreasModel[];
  areas: AreaModel[];
  regions: RegionModel[];
  sports: SportModel[];
  tournaments: MatchModel[];
  matchesByTournament: MatchByTournamentModel[];
  matchesByTime: MatchModel[];
  selectedDay: DayModel;
  selectedSport: SportModel;
  selectedArea: AreaModel;
  selectedRegion: { region: RegionModel; areas: AreaModel[] };
  selectedMarket: MarketModel;
  selectedTournamentId: number;
  sortType: SortType;
  isItMultiline: boolean;
  isItCorrectScores: boolean;
  isPlayerMarket: boolean;
  isRegularMarket: boolean;
  ui: TodaysEventsUIState;

  constructor(init: Partial<TodaysEventsState>) {
    Object.assign(this, init);
  }
}

export enum SortType {
  ByTime,
  ByTournament,
  ByOddsLowestFirst,
  ByOddsHighestFirst,
}

export class TodaysEventsUIState {
  isEventsLoading: boolean;
  firstTimeLoadIsInProgress: boolean;

  constructor(init: Partial<TodaysEventsUIState>) {
    Object.assign(this, init);
  }
}

export class RegionsWithAreasModel {
  areas: AreaModel[];
  regions: RegionModel[];

  constructor(init: Partial<RegionsWithAreasModel>) {
    Object.assign(this, init);
  }
}

export class MatchByTournamentModel {
  tournamentId: number;
  matches: MatchModel[];

  constructor(init: Partial<MatchByTournamentModel>) {
    Object.assign(this, init);
  }
}

export class SportModel {
  sportId: number;
  sportName: string;

  constructor(init: Partial<SportModel>) {
    Object.assign(this, init);
  }
}

export class OddAttributeModel {
  oddTypeId: number;
  oddName: string;
  specialValue: number;
  order: number;
  specialValueDisplay: string;

  constructor(init: Partial<OddAttributeModel>) {
    Object.assign(this, init);
  }
}

export class OutcomeModel {
  outcomeId: number;
  oddOutcome: number;
  scheduleStatus: number;

  constructor(init: Partial<OutcomeModel>) {
    Object.assign(this, init);
  }
}

export class MatchOddModel {
  matchOddsId: number;
  smartBetCode: number;
  oddAttribute: OddAttributeModel;
  outcome: OutcomeModel;
  markup: number;
  pricePerShare: number;
  availableShares: number;

  constructor(init: Partial<MatchOddModel>) {
    Object.assign(this, init);
  }
}

export class AreaMatchModel {
  sportId: number;
  sportName: string;
  areaMarkets: MarketModel[];
  groupingType: number;
  area: AreaModel;
  region: RegionModel;
  items: MatchModel[];
  containsOnlyOutright: boolean;
  areaMarketsType: number;

  constructor(init: Partial<AreaMatchModel>) {
    Object.assign(this, init);
  }
}

export class GetEventsAPIResponse {
  sports: SportModel[];
  areaMatches: AreaMatchModel[];
  areas: AreaModel[];
  regions: RegionModel[];
  totalNoOfItems: number;

  constructor(init: Partial<GetEventsAPIResponse>) {
    Object.assign(this, init);
  }
}

export class DayModel {
  id: number;
  name: string;
  date: string;

  constructor(init: Partial<DayModel>) {
    Object.assign(this, init);
  }
}
