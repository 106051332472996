<div class="details">
  <div class="date-time">
    <div class="date" *ngIf="showDate">
      {{ event.date | date: 'dd MMM' }}
    </div>
    <div class="time">
      {{ event.date | date: 'HH:mm' }}
    </div>
  </div>
  <div class="event-competitors">
    <div class="competitor-one" [class.can-split]="teamNameCanBeSplit(event.homeTeam)">
      {{ event.homeTeam }}
    </div>
    <div class="competitor-two" [class.can-split]="teamNameCanBeSplit(event.awayTeam)">
      {{ event.awayTeam }}
    </div>
  </div>
</div>
