<div class="freebets-container" data-testid="my-bets-bet-info-content-freebet">
  <div
    class="freebets-applied-text"
    *ngIf="freebetsQuery.myBetsContentCallDone$ | async"
    [innerHTML]="freebetsQuery.myBetsFreeBetsAppliedLabel$(voucher.amount) | async"
  ></div>
  <freebets-voucher [code]="voucher.code" [name]="voucher.name" [amount]="voucher.amount"></freebets-voucher>
  <ng-container *ngIf="this.freebetsQuery.freebetsStakeDisclaimer$ | async as stakeDisclaimer">
    <div class="stake-disclaimer" *ngIf="showStakeDisclaimer && (freebetsQuery.myBetsContentCallDone$ | async)">{{ stakeDisclaimer }}</div>
  </ng-container>
</div>
