<div class="market" [ngStyle]="{'background-color': bgColor }">
  <div class="market-name" *ngIf="showMarketName && marketName">
    {{ marketName }}
  </div>
  <div class="separator" *ngIf="separatorText"
    [class.last-minute]="(applicationQuery.activeUrl$ | async)[0] === 'last-minute'">
    {{ separatorText }}
  </div>
  <div class="markets" [class.separator]="separator">
    <div *ngFor="let selection of markets; trackBy: marketTrackBy" class="market" [ngStyle]="{'background-color': bgColor }">
      {{ selection.name }}
    </div>
  </div>
</div>
