import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { forkJoin, Subject, throwError } from 'rxjs';
import { catchError, filter, first, takeUntil, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { VirtualsService } from 'src/app/core/services/virtuals.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { UserType } from 'src/app/shared/models/account.model';
import { VirtualsLobbyGame } from 'src/app/shared/models/virtuals.model';
import { LoadingService } from 'src/app/core/services/loading.service';
import { VirtualsInstantService } from 'src/app/core/services/virtuals-instant.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';

@Component({
  selector: 'virtuals-game-lobby',
  templateUrl: './virtuals-game-lobby.component.html',
  styleUrls: ['./virtuals-game-lobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VirtualsGameLobbyComponent implements OnInit, OnDestroy {
  @Input() showBreadcrumbs: boolean = true;
  @Input() showPageTitle: boolean = true;
  @Input() showInfoBubble: boolean = true;

  private requestedGameCode: string = ''; // Used to store the code of the game which the user wants to load (for games that require login)
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    readonly virtualsQuery: VirtualsQuery,
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly applicationService: ApplicationService,
    private readonly authenticationService: AuthenticationService,
    private readonly loadingService: LoadingService,
    private readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly virtualsInstantService: VirtualsInstantService,
    private readonly virtualsService: VirtualsService,
    private readonly virtualsStore: VirtualsStore
  ) {
    this.requestedGameCode = this.router.getCurrentNavigation()?.extras?.state?.route.split('/').pop().split('?')[0];
  }

  ngOnInit(): void {
    this.requestedGameCode && this.loadingService.enqueueLoader();
    this.virtualsService.getBreadcrumbNavigation().subscribe();

    forkJoin([this.accountService.refreshEnabledGameProviders(), this.virtualsService.getGameLobby()])
      .pipe(
        catchError(error => {
          this.loadingService.dequeueLoader();
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.getCategoryEventsTimings();
        this.getJackpots();
        // TODO: Get jackpot values

        if (this.requestedGameCode) {
          this.loadingService.dequeueLoader();
          const requestedGame = this.virtualsQuery.lobbyGames?.find(game => game.code === this.requestedGameCode);
          if (requestedGame) {
            this.openGame(requestedGame);
          }
        }
      });
  }

  isGameDisabled(game: VirtualsLobbyGame): boolean {
    const isSubAccount = this.accountQuery.userData && this.accountQuery.userData.userTypeCode === UserType.SubAccountPlayer;
    const virtualsConfig = this.appConfig.get('virtuals');
    const disabledGamesForSubAccountPlayers = virtualsConfig.goldenRace.disabledGamesForSubAccountPlayers;
    const disabledLeaguesForSubAccountPlayers = virtualsConfig.disabledLeaguesForSubAccountPlayers;
    const scheduledLeaguesDisabled = game.gameType === 'scheduled-league' && !virtualsConfig.scheduledLeague?.enabled;
    const instantLeaguesDisabled = game.gameType === 'instant-league' && !virtualsConfig.instantLeague?.enabled;

    return !!(
      !game.isEnabled ||
      scheduledLeaguesDisabled ||
      instantLeaguesDisabled ||
      (isSubAccount && (disabledGamesForSubAccountPlayers.includes(game.code) || disabledLeaguesForSubAccountPlayers.includes(game.code)))
    );
  }

  openGame(game: VirtualsLobbyGame): void {
    if (this.isGameDisabled(game)) {
      this.notificationService.showInfoNotification($localize`Please check back soon`, $localize`Coming Soon`);
      return;
    }

    this.requestedGameCode = game.code; // store code in case we need to show the login dialog

    if (this.accountQuery.isAuthenticated) {
      // validate if the token is still valid
      game.gameType === 'instant-league' ? this.loginUserToGRBO(game.code) : this.validateToken(game);
    } else {
      if (game.showLoginDialog) {
        this.applicationService.setShowLoginDialog(true);
        this.applicationQuery.showLoginDialog$
          .pipe(
            filter(state => !state),
            tap(() => this.loginDialogClosed()),
            takeUntil(this.destroy$)
          )
          .subscribe();
      } else {
        this.navigateToGame(game.code);
      }
    }
  }

  ctaClick(ctaClickUrl: string): void {
    this.navigateToUrl(ctaClickUrl);
  }

  jackpotClick(jackpotClickUrl: string): void {
    this.navigateToUrl(jackpotClickUrl);
  }

  refreshEventTiming(categoryId: number | string): void {
    this.getCategoryEventsTimings();
  }

  getDropFrequency(label: string, dropFrequency: number): string {
    if (!label || !dropFrequency || dropFrequency < 0) {
      return undefined;
    }

    // These calculations expect the dropFrequency value to be in seconds
    let days = Math.floor(dropFrequency / (3600 * 24));
    let hours = Math.floor((dropFrequency % (3600 * 24)) / 3600);
    let minutes = Math.floor((dropFrequency % 3600) / 60);
    const seconds = Math.floor(dropFrequency % 60);

    // Round up the time where applicable and format the value to make it readable
    let formattedValue: string;
    if (days > 0 || (hours === 23 && minutes >= 30)) {
      if (hours >= 12) {
        days++;
      }
      formattedValue = this.formatTimeValue(days, $localize`day`, $localize`days`);
    } else if (hours > 0 || (minutes === 59 && seconds >= 30)) {
      if (minutes >= 30) {
        hours++;
      }
      formattedValue = this.formatTimeValue(hours, $localize`hour`, $localize`hours`);
    } else if (minutes > 0 || seconds >= 30) {
      if (seconds >= 30) {
        minutes++;
      }
      formattedValue = this.formatTimeValue(minutes, $localize`minute`, $localize`minutes`);
    }

    return formattedValue ? `${label} ${formattedValue}` : undefined;
  }

  private formatTimeValue(value: number, singularSuffix: string, pluralSuffix: string): string {
    return `${value} ${value > 1 ? pluralSuffix : singularSuffix}`;
  }

  private getCategoryEventsTimings(): void {
    const categoryIds: (number | string)[] = this.virtualsQuery.lobbyGames
      ?.filter(game => game.isEnabled && game.showCountdown && game.providerId)
      .map(game => game.providerId);

    if (categoryIds?.length) {
      this.virtualsService.getCategoryEventsTimings(categoryIds).pipe(takeUntil(this.destroy$)).subscribe();
    } else {
      this.virtualsStore.clearCategoryEventsTimings();
    }
  }

  private getJackpots(): void {
    this.virtualsStore.clearJackpots();

    const jackpots: string[] = this.virtualsQuery.lobbyData?.sections
      ?.filter(section => section.isEnabled && section.jackpot)
      .map(section => section.jackpot);

    if (jackpots?.length) {
      this.virtualsService.getJackpots().pipe(takeUntil(this.destroy$)).subscribe();
    }
  }

  private navigateToUrl(url: string): void {
    if (url) {
      const urlRegex = /^https?:\/\//i;
      if (urlRegex.test(url)) {
        // absolute url
        window.location.href = url;
      } else {
        this.router.navigateByUrl(url);
      }
    }
  }

  private loginDialogClosed(): void {
    if (this.accountQuery.isAuthenticated) {
      this.loginUserToGRBO(this.requestedGameCode);
    }
  }

  private validateToken(game: VirtualsLobbyGame): void {
    this.authenticationService
      .validateToken(this.accountQuery.accessToken)
      .pipe(first(), takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data && data.isValidated === false) {
            // if token is invalid, logout the user
            this.accountService.clearUserData();

            // show the login dialog if required
            if (game.showLoginDialog) {
              this.showLogin();
            } else {
              this.navigateToGame(game.code);
            }
          } else {
            // if token is valid or no data was returned, proceed to the game
            this.navigateToGame(game.code);
          }
        },
        () => {
          // if an error occurs during token validation, proceed to the game with the current user details
          this.navigateToGame(game.code);
        }
      );
  }

  private showLogin(): void {
    if (this.appConfig.get('virtuals')?.showBetslipLoginDialog) {
      this.applicationService.setShowLoginDialog(true);
      this.applicationQuery.showLoginDialog$
        .pipe(
          filter(state => !state),
          tap(() => this.loginDialogClosed()),
          takeUntil(this.destroy$)
        )
        .subscribe();
    } else {
      this.notificationService.showInfoNotification($localize`Please log into the site`, $localize`Login Required`);
    }
  }

  private loginUserToGRBO(gameCode: string) {
    this.loadingService.enqueueLoader();
    this.loadingService.updateFullscreen(true);
    this.virtualsInstantService
      .loginToGR()
      .pipe(
        catchError(err => {
          this.loadingService.dequeueLoader();
          this.notificationService.showInfoNotification($localize`Please try again later`, $localize`Game could not be loaded.`);
          return throwError(err.message);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.loadingService.dequeueLoader();
        this.navigateToGame(gameCode);
      });
  }

  private navigateToGame(gameCode: string): void {
    this.router.navigate(['/virtual', gameCode]);
  }

  ngOnDestroy(): void {
    this.virtualsStore.clearCategoryEventsTimings();
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
