import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { fadeInOut } from 'src/app/shared/animations';

@Component({
  selector: 'app-loading-bar-overlay',
  templateUrl: './loading-bar-overlay.component.html',
  styleUrls: ['./loading-bar-overlay.component.scss'],
  animations: [fadeInOut()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingBarOverlayComponent {
  @Input() show: boolean = false;
}
