export interface DataLayerEvent {
  event: string;
  [key: string]: string | number | boolean;
}
export enum DataLayerProduct {
  VirtualsScheduled = 'virtuals-scheduled',
  VirtualsInstant = 'virtuals-instant',
  SportsBookLive = 'sportsbook-live',
  SportsBookPrematch = 'sportsbook-prematch',
  SportsBookMixed = 'sportsbook-mixed',
}

export enum BetslipActions {
  AddToBetslip = 'add_to_betslip',
  BetslipOpen = 'betslip_open',
  BetslipError = 'betslip_error',
  BetSubmitted = 'bet_submitted',
  BetSuccess = 'bet_success',
  BetFailure = 'bet_failure',
}

export interface BetslipActionsDetails {
  event: BetslipActions;
  product: DataLayerProduct;
  userId?: number;
  currency?: string;
  betStake?: number;
  betType?: string;
  errorMessage?: string;
  couponId?: string;
  selectionId?: number;
  location?: string;
  isFreeBet?: boolean;
}
