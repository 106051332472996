import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsService } from 'src/app/core/services/statistics.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { MarketModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-multi-line-event',
  templateUrl: './multi-line-event.component.html',
  styleUrls: ['./multi-line-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiLineEventComponent {
  @Input() event: MatchModel;
  @Input() market: MarketModel;
  @Input() showDetails: boolean = true;
  @Input() sportId: number = undefined;

  constructor(
    readonly statisticsService: StatisticsService,
    readonly statisticsQuery: StatisticsQuery,
    readonly matchService: MatchService
  ) {}
}
