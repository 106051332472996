import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';
import { BreadcrumbNavigationService } from 'src/app/core/services/breadcrumb-navigation.service';

@Component({
  selector: 'app-breadcrumb-item',
  templateUrl: './breadcrumb-item.component.html',
  styleUrls: ['./breadcrumb-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbItemComponent implements OnInit {
  @Input() breadcrumb: BreadcrumbNavigation;
  @Input() openedDropdownItem: string;
  readonly activeItem$: BehaviorSubject<BreadcrumbNavigation> = new BehaviorSubject(undefined);
  readonly lastActiveItem$: BehaviorSubject<string> = new BehaviorSubject(undefined);
  breadcrumbItems: BreadcrumbNavigation[];

  constructor(private readonly router: Router, private readonly breadcrumbNavigationService: BreadcrumbNavigationService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.breadcrumbItems) {
        this.setActiveBreadcrumbItem();
      }
    });
  }

  ngOnInit() {
    this.breadcrumbItems = this.breadcrumb.subMenu;
    this.breadcrumbItems && this.setActiveBreadcrumbItem();
  }

  breadcrumbClick(item: BreadcrumbNavigation, itemUniqueValue: string): void {
    if (this.breadcrumbItems.length === 1) {
      this.goToPage(item);
    } else {
      itemUniqueValue !== this.openedDropdownItem
        ? this.breadcrumbNavigationService.openDropdownForActiveItem(this.breadcrumb)
        : this.breadcrumbNavigationService.closeOpenDropdown();
    }
  }

  goToPage(item: BreadcrumbNavigation): void {
    let urlRedirect = item.url;
    this.breadcrumbNavigationService.closeOpenDropdown();
    // Check for default games before redirecting
    item.subMenu?.forEach(submenuItem => {
      if (submenuItem.isDefaultGame) {
        urlRedirect = submenuItem.url;
      }
    });
    this.router.navigateByUrl(urlRedirect);
  }

  private setActiveBreadcrumbItem(): void {
    for (const breadcrumbItem of this.breadcrumbItems) {
      let activeUrl = this.router.url.split('?')[0];

      if (activeUrl === breadcrumbItem.url) {
        this.lastActiveItem$.next(breadcrumbItem.uniqueValue);
      }

      for (let i = 0; i < this.router.url.split('/').length; i++) {
        if (activeUrl === breadcrumbItem.url) {
          this.activeItem$.next(breadcrumbItem);
        }
        activeUrl = this.removeLastUrlPart(activeUrl);
      }
    }
  }

  private removeLastUrlPart(activeUrl): string {
    return activeUrl.substring(0, activeUrl.lastIndexOf('/'));
  }
}
