import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { InstantCouponQuery } from 'src/app/core/state/instant-coupon/instant-coupon.query';
import {
  CouponDetails,
  EventBets,
  EventData,
  EventDataData,
  InstantEvent,
  InstantEvents,
  InstantPostCoupon,
  SystemBets,
} from 'src/app/shared/models/instant-coupon.model';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';

@Injectable({
  providedIn: 'root',
})
export class BuildInstantCouponService {
  private readonly additionalInstantCouponDataKey = this.appConfig.get('virtuals').instantLeague?.couponExtraDataStorageKey;
  private selectedEvents;

  constructor(
    private readonly localStorage: LocalStorageService,
    private readonly instantCouponQuery: InstantCouponQuery,
    private readonly appConfig: AppConfigService,
    private readonly virtualsQuery: VirtualsQuery
  ) {}

  buildCouponData(): InstantPostCoupon {
    this.selectedEvents = this.localStorage.retrieve(this.additionalInstantCouponDataKey)?.events;

    const postCouponData = new InstantPostCoupon({
      tagsId: this.virtualsQuery.instantUserData?.tagsId,
      timeSend: new Date().toISOString(),
      sellStaff: {
        entityType: [],
        id: this.virtualsQuery.instantUserData?.unitId,
        name: this.virtualsQuery.instantUserData?.name,
        extId: this.virtualsQuery.instantUserData?.extId,
        extData: null,
        status: this.virtualsQuery.instantUserData?.status,
      },
      calculationId: this.virtualsQuery.instantUserData?.calculationId,
      walletId: this.virtualsQuery.instantUserData?.walletId,
      details: this.parseCouponDetails(),
    });

    return postCouponData;
  }

  clearCouponData(): void {
    this.localStorage.clear(this.additionalInstantCouponDataKey);
  }

  private parseCouponDetails(): CouponDetails {
    return {
      events: this.parseEvents(),
      systemBets: this.parseSystemBets(),
      jackpotDetails: [],
      additionalDetails: [],
    };
  }

  private parseSystemBets(): SystemBets[] {
    const groupings = [];
    this.instantCouponQuery.couponData.Groupings.forEach(group => {
      groupings.push({
        grouping: group.Grouping,
        systemCount: group.Combinations,
        stake: group.Stake * group.Combinations,
        winningData: {
          // added default value because if limitMaxPayout is empty coupon will not be send over
          limitMaxPayout: this.virtualsQuery.instantUserData?.userLimits?.maxPayout || 1500000,
          minWinning: group.MinWinNet,
          maxWinning: group.MaxWinNet,
          minBonus: group.MinBonus,
          maxBonus: group.MaxBonus,
          winningCount: group.Combinations,
        },
      });
    });

    return groupings;
  }

  private parseEvents(): InstantEvents[] {
    const events = [];

    this.instantCouponQuery.couponData.Odds.forEach(odd => {
      const eventExist = events.find(event => event.eventId === odd.MatchId);

      if (!eventExist) {
        events.push({
          finalOutcome: [],
          bets: this.parseEventBets(odd, []),
          eventId: odd.MatchId,
          extId: null,
          extData: null,
          playlistId: this.parseEvent(odd)?.playlistId,
          playlistDescription: this.parseEvent(odd)?.description,
          gameType: this.parseEvent(odd)?.gameType,
          eventTime: null,
          data: this.parseData(odd),
          isBanker: false,
        });
      } else {
        eventExist.bets = this.parseEventBets(odd, eventExist.bets);
      }
    });

    return events;
  }

  private parseData(odd): EventData {
    return {
      classType: 'ChTicketEventData',
      participants: this.parseEvent(odd)?.data.participants,
      competitionType: 'LEAGUE',
      data: this.parseEventData(odd),
      eventNdx: this.parseEvent(odd)?.eventNdx,
      competitionSubType: 'LEAGUE',
    };
  }

  private parseEventData(odd): EventDataData {
    const data = this.parseEvent(odd)?.eventData;

    return {
      classType: data.classType,
      champId: data.champId,
      weekDay: data.weekDay,
      legOrder: data.legOrder,
      phase: data.phase,
      matchDay: data.matchDay,
      eBlockId: this.parseEvent(odd)?.eBlockId,
    };
  }

  private parseEvent(odd): InstantEvent {
    return this.selectedEvents.filter(event => event.eventId === odd.MatchId)[0];
  }

  private getOddId(selectionId): string {
    const selectedOdds = this.localStorage.retrieve(this.additionalInstantCouponDataKey)?.selectedOdds;
    const selectionUniqueId = selectedOdds.filter(odd => odd.oddIdd === selectionId)[0]?.selectionUniqueId;

    return selectionUniqueId;
  }

  private parseEventBets(odd, bets): EventBets[] {
    const groupingsStake = this.instantCouponQuery.couponData.AllGroupings[0].Stake;
    const stake = groupingsStake < 0 ? 0 : groupingsStake;
    bets.push({
      marketId: odd.MarketId,
      oddId: this.getOddId(odd.SelectionId),
      oddValue: odd.OddValue.toString(),
      minOdd: null,
      maxOdd: null,
      solvedOdd: null,
      numCombis: null,
      wonCombis: null,
      stake: stake,
      cancelledStake: 0,
      wonAmount: null,
      targetRTP: null,
      targetBalance: null,
      betParam: null,
      status: 'OPEN',
      profitType: 'NONE',
      timeSolved: null,
    });

    return bets;
  }
}
