export const brandInfo = {
  brandName: 'BetKing',
  brandUrl: 'BetKing.com',
  metaDataDefaults: {
    title: 'Best Online Sports Betting',
    description: `Best online sports betting website in Nigeria. Visit BetKing for high odds, welcome bonus, rebet, cash out and
                  live betting. Bet on Virtual, Tennis and more.`,
    'og:image': 'https://m.betking.com/assets/images/logo/brand-logo.svg',
    'og:type': 'website',
    'og:locale': 'en_GB',
    'twitter:card': 'summary_large_image',
  },
  footerNote: '',
};
