import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig: any = {};
  private readonly appConfigDefaults: any = environment.appConfigDefaults;

  constructor(private readonly httpBackend: HttpBackend) {}

  loadAppConfig(): Promise<void> {
    // We're making use of HttpBackend to ensure that the HTTPInterceptors don't fire for the follow
    // GET request
    const http = new HttpClient(this.httpBackend);

    // Since this function will be called inside the APP_INITIALIZER, we have to
    // return a promise since observables are not supported yet in that part of the code.
    // A random ID is added to the request to force cache busting.
    const promise = new Promise<void>(resolve =>
      http
        .get(`./${environment.appConfigPath}?cb=${environment.appConfigCacheBuster}`, { responseType: 'text' })
        .pipe(map(data => (data ? data.toString().trim() : '')))
        .toPromise()
        .then(data => {
          try {
            // Check if the returned string is in base64 format or not
            const base64Validator = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
            this.appConfig = base64Validator.test(data) ? JSON.parse(atob(data)) : JSON.parse(data);
          } catch (error) {
            console.error(`Error parsing config file: '${environment.appConfigPath}'`);
          } finally {
            resolve();
          }
        })
        .catch(error => {
          console.error(`Error loading config file: '${environment.appConfigPath}'`);
          resolve();
        })
    );

    return promise;
  }

  get<T = any>(keyName: string): T {
    const configValue = this.appConfig[keyName];
    const defaultValue = this.appConfigDefaults[keyName];

    return configValue !== undefined ? configValue : defaultValue;
  }
}
