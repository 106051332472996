import { Injectable } from '@angular/core';

import { EMPTY, Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { APIService } from 'src/app/core/services/api.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { BannerStore } from 'src/app/core/state/banner/banner.store';
import { APIType } from 'src/app/shared/models/api.model';
import { BannerModel, MarketsData, Market, Module } from 'src/app/shared/models/banner.model';
import { OddModel } from 'src/app/shared/models/coupon.model';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';

@Injectable({
  providedIn: 'root',
})
export class BannerService {
  readonly ODDS_NAMES = {
    1: $localize`Home (1)`,
    X: $localize`Draw (X)`,
    2: $localize`Away (2)`,
    '1 HH': $localize`Home (1 HH)`,
    '2 HH': $localize`Away (2 HH)`,
  };

  constructor(
    private readonly apiService: APIService,
    private readonly bannerStore: BannerStore,
    private readonly applicationService: ApplicationService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly couponService: CouponService
  ) {}

  getBannersData(module: Module): Observable<void> {
    let url: string = 'SiteRoot/GetBanners';
    let storeUpdateParameter: string = 'lastSportsBannerUpdate';
    let lastBannerUpdate: number = this.applicationQuery.cms.lastSportsBannerUpdate;

    if (module === Module.Payments) {
      url = 'Account/GetPaymentBanners';
      lastBannerUpdate = this.applicationQuery.cms.lastPaymentsBannerUpdate;
      storeUpdateParameter = 'lastPaymentsBannerUpdate';
    }

    if (this.applicationService.getEpochTimeDifference(lastBannerUpdate) > this.applicationQuery.cms.cacheTTL) {
      // Since we are doing an update, reset cache timers and current list state
      this.bannerStore.update({ bannerDetails: undefined });
      this.applicationService.resetBannerUpdateCaches();

      return this.apiService.get(APIType.CMS, url).pipe(
        first(),
        tap(data => {
          this.bannerStore.update({ bannerDetails: data });
          const update = {};
          update[storeUpdateParameter] = this.applicationService.getEpochTime();
          this.applicationService.updateCms(update);
        })
      );
    } else {
      return EMPTY;
    }
  }

  getBannerOdds(banner: BannerModel, bannerDetails, marketTypes, index): Observable<void> {
    const marketId = marketTypes[banner.bannerType];
    const URL = `api/feeds/prematch/QuickBet/ByMarketTypes/${banner.eventOnlineCode}/${marketId}/en`;
    return this.apiService.get(APIType.SportsbookFeed, URL).pipe(
      first(),
      map(data => {
        const marketsData = new MarketsData({
          categoryName: data.CategoryName,
          eventDate: data.EventDate,
          eventName: data.EventName,
          idCategory: data.IDCategory,
          idEvent: data.IDEvent,
          idEventType: data.IDEventType,
          idSport: data.IDSport,
          externalID: data.ExtProvIDItem,
          idTournament: data.IDTournament,
          markets: [],
          smartBetCode: data.SmartBetCode,
          sportName: data.SportName,
          teamHome: data.EventName.split(' - ')[0],
          teamAway: data.EventName.split(' - ')[1],
          totalOdds: data.TotalOdds,
          tournamentName: data.TournamentName,
        });

        data.Markets.forEach(market => {
          const markets = new Market({
            gamePlay: market.GamePlay,
            markets: [],
            oddsID: market.OddsID,
            specialBetValue: market.SpecialBetValue,
            specialValueDisplay: market.specialValueDisplay,
          });

          market.Markets.forEach(innerMarket => {
            const inner = new OddModel({
              id: innerMarket.MatchOddsID,
              value: innerMarket.OddOutcome,
              unboostedValue: innerMarket.UnboostedOddValue,
              spreadValue: innerMarket.OddAttribute.SpecialValue,
              sportId: data.IDSport,
              sportName: data.SportName,
              categoryId: data.IDCategory,
              categoryName: data.CategoryName,
              eventCategory: 'F', // F = prematch, L = live
              smartCode: data.SmartBetCode,
              tournamentId: data.IDTournament,
              tournamentName: data.TournamentName,
              matchId: data.IDEvent,
              matchName: data.EventName,
              matchDate: data.EventDate,
              marketId: market.OddsID,
              marketTypeId: market.OddsType.OddsTypeID,
              marketName: market.OddsType.OddsTypeName,
              selectionId: innerMarket.OddAttribute.OddTypeID,
              selectionName: this.ODDS_NAMES[innerMarket.OddAttribute.OddName],
              incompatibleEvents: data.IncompatibleEvents,
              combinability: market.GamePlay,
              selected: this.couponService.isOddInCoupon(innerMarket.MatchOddsID),
              enabled: true,
            });
            markets.markets.push(inner);
          });

          marketsData.markets.push(markets);
          banner.marketsData = marketsData;
          bannerDetails[index] = banner;
          this.bannerStore.update({ bannerDetails });
        });
      })
    );
  }
}
