export enum HeaderTab {
  NotSelected,
  HeadToHead,
  LastMatches,
  NextMatches,
  OverUnder,
  PlayerStats,
  LeagueRanking,
}

export enum TeamTab {
  Home,
  Away,
}

export enum MatchesTab {
  All = 'all',
  Home = 'home',
  Away = 'away',
}

export enum SegmentTab {
  FullTime = 'fulltime',
  FirstHalf = '1sthalf',
  SecondHalf = '2ndhalf',
}

export enum SpreadTab {
  Spread05 = '05',
  Spread15 = '15',
  Spread25 = '25',
  Spread35 = '35',
  Spread45 = '45',
  Spread55 = '55',
}

export enum StatsTab {
  Goals,
  Assists,
  Cards,
}

export interface StatisticsState {
  matchStatisticsData: MatchStatisticsModel;
  headToHeadData: HeadToHeadModel;
  lastMatchesData: LastMatchesModel;
  nextMatchesData: NextMatchesModel;
  overUnderData: OverUnderModel;
  goalscorerData: GoalscorerModel;
  assistsData: AssistsModel;
  cardsData: CardsModel;
  leagueRankingData: LeagueRankingModel;
  ui: {
    selectedExternalId: number;
    expandedStatistics: boolean;
  };
}

export class MatchStatisticsModel {
  seasonId: number;
  homeTeamId: number;
  awayTeamId: number;

  constructor(init: Partial<MatchStatisticsModel>) {
    Object.assign(this, init);
  }
}

export class HeadToHeadModel {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamId: number;
  awayTeamName: string;
  headToHeadHistory: MatchModel[];
  teamHomeWins: number;
  teamHomeWinsPerc: string;
  teamDraws: number;
  teamDrawsPerc: string;
  teamAwayWins: number;
  teamAwayWinsPerc: string;

  constructor(init: Partial<HeadToHeadModel>) {
    Object.assign(this, init);
  }
}

export class MatchModel {
  date: string;
  teamHomeId: number;
  teamHomeName: string;
  teamHomeScore: number;
  teamHomeLogo: string;
  teamAwayId: number;
  teamAwayName: string;
  teamAwayScore: number;
  teamAwayLogo: string;
  winner: string;
  score: string;
  round: string;

  constructor(init: Partial<MatchModel>) {
    Object.assign(this, init);
  }
}

export class ResultModel {
  id: number;
  result: string;

  constructor(init: Partial<ResultModel>) {
    Object.assign(this, init);
  }
}

export class LastMatchesModel {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamId: number;
  awayTeamName: string;
  homeLastResults: ResultModel[];
  awayLastResults: ResultModel[];
  homeMatches: MatchModel[];
  awayMatches: MatchModel[];

  constructor(init: Partial<LastMatchesModel>) {
    Object.assign(this, init);
  }
}
export class NextMatchesModel {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamId: number;
  awayTeamName: string;
  homeMatches: MatchModel[];
  awayMatches: MatchModel[];

  constructor(init: Partial<NextMatchesModel>) {
    Object.assign(this, init);
  }
}
export class OverUnderModel {
  allfulltime: OverUnderTypeModel[];
  all1sthalf: OverUnderTypeModel[];
  all2ndhalf: OverUnderTypeModel[];
  homefulltime: OverUnderTypeModel[];
  home1sthalf: OverUnderTypeModel[];
  home2ndhalf: OverUnderTypeModel[];
  awayfulltime: OverUnderTypeModel[];
  away1sthalf: OverUnderTypeModel[];
  away2ndhalf: OverUnderTypeModel[];

  constructor(init: Partial<OverUnderModel>) {
    Object.assign(this, init);
  }
}

export class OverUnderTypeModel {
  id: string;
  spread: string;
  teams: OverUnderTeamModel[];

  constructor(init: Partial<OverUnderTypeModel>) {
    Object.assign(this, init);
  }
}

export class OverUnderTeamModel {
  teamId: number;
  teamName: string;
  teamLogo: string;
  played: number;
  over: number;
  under: number;
  averageGoalscored: number;
  cleanSheet: number;
  atLeastOneGoal: number;
  order: number;

  constructor(init: Partial<OverUnderTeamModel>) {
    Object.assign(this, init);
  }
}

export class GoalscorerModel {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamId: number;
  awayTeamName: string;
  homeGoalscorers: PlayerModel[];
  awayGoalscorers: PlayerModel[];

  constructor(init: Partial<GoalscorerModel>) {
    Object.assign(this, init);
  }
}
export class AssistsModel {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamId: number;
  awayTeamName: string;
  homeAssists: PlayerModel[];
  awayAssists: PlayerModel[];

  constructor(init: Partial<AssistsModel>) {
    Object.assign(this, init);
  }
}
export class CardsModel {
  homeTeamId: number;
  homeTeamName: string;
  awayTeamId: number;
  awayTeamName: string;
  homeCards: PlayerModel[];
  awayCards: PlayerModel[];

  constructor(init: Partial<CardsModel>) {
    Object.assign(this, init);
  }
}

export class PlayerModel {
  position: string;
  name: string;
  nationality: string;
  nationalityFlag: string;
  matches: number;
  goals: number;
  assists: number;
  yellowCards: string;
  yellowRedCards: string;
  redCards: string;

  constructor(init: Partial<PlayerModel>) {
    Object.assign(this, init);
  }
}

export class LeagueRankingModel {
  teams: LeagueRankingTeamModel[];

  constructor(init: Partial<LeagueRankingModel>) {
    Object.assign(this, init);
  }
}

export class LeagueRankingTeamModel {
  position: number;
  teamId: number;
  teamName: string;
  teamLogo: string;
  played: number;
  won: number;
  drawn: number;
  lost: number;
  goalsFor: number;
  goalsAgainst: number;
  goalsDifference: number;
  points: number;

  constructor(init: Partial<LeagueRankingTeamModel>) {
    Object.assign(this, init);
  }
}
