import { ChangeDetectionStrategy, ElementRef, Component, OnDestroy, ViewChild, OnInit, Input } from '@angular/core';
import { BehaviorSubject, Subject, interval, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BannerService } from 'src/app/core/services/banner.service';
import { PageVisibilityApi } from 'src/app/core/services/page-visibility-api.service';
import { BannerQuery } from 'src/app/core/state/banner/banner.query';
import { BookBetService } from 'src/app/core/services/book-bet.service';
import { BannerType, Module } from 'src/app/shared/models/banner.model';
import { fadeInOut } from 'src/app/shared/animations';
import Hammer from '@egjs/hammerjs';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Component({
  selector: 'app-banner',
  animations: [fadeInOut()],
  templateUrl: './app-banner.component.html',
  styleUrls: ['./app-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBannerComponent implements OnInit, OnDestroy {
  @Input() module = Module.Sports;

  private readonly bannerSwitchInterval$ = new BehaviorSubject<Subscription>(undefined);
  private readonly bannerContainer$ = new BehaviorSubject(undefined);
  private readonly bannersCount$ = new BehaviorSubject<number>(0);
  private readonly showArrows$ = new BehaviorSubject<boolean>(false);
  private readonly couponCodeClicked$ = new BehaviorSubject<boolean>(false);
  private readonly destroy$ = new Subject<any>();
  private isGesturesRegistered = false;

  readonly showBanner$ = new BehaviorSubject<boolean>(false);
  readonly currentVisibleBannerIndex$ = new BehaviorSubject<number>(0);

  constructor(
    readonly bannerQuery: BannerQuery,
    private readonly bannerService: BannerService,
    private readonly bookBetService: BookBetService,
    private readonly pageVisibilityApi: PageVisibilityApi,
    private readonly sportQuery: SportQuery
  ) {}

  @ViewChild('appBannerContainer')
  set bannerContainerSetter(bannerContainer: ElementRef) {
    if (bannerContainer) {
      this.bannerContainer$.next(bannerContainer);
    }
  }

  ngOnInit(): void {
    this.sportQuery.marketTypeIds$.subscribe(marketTypeIds => {
      if (marketTypeIds) {
        const marketType1X2 = marketTypeIds.filter(item => item.lookupCode === '1X2');
        const marketType12 = marketTypeIds.filter(item => item.lookupCode === '1-2');

        const marketTypes = {
          1: marketType1X2.length ? marketType1X2[0].marketTypeId : 10,
          2: marketType12.length ? marketType12[0].marketTypeId : 20,
        };

        this.initialiseBanners(marketTypes);
      }
    });
  }

  initialiseBanners(marketTypes): void {
    this.bannerService.getBannersData(this.module).subscribe();

    this.bannerQuery.bannerDetails$.pipe(takeUntil(this.destroy$)).subscribe(bannerDetails => {
      bannerDetails.map((banner, index) => {
        const eventOnlineCode = banner.eventOnlineCode;
        const bannerType = banner.bannerType;
        if (bannerType === BannerType._1X2 || bannerType === BannerType.H2H) {
          if (eventOnlineCode === undefined || eventOnlineCode.trim().length <= 0 || banner.marketsData !== undefined) {
            return;
          }
          this.bannerService.getBannerOdds(banner, bannerDetails, marketTypes, index).subscribe();
        } else {
          return banner;
        }
      });

      this.disableAutoChange();
      this.showArrows$.next(false);
      this.showBanner$.next(false);

      if (bannerDetails && bannerDetails.length > 0) {
        this.showBanner$.next(true);
        if (bannerDetails.length > 1) {
          this.enableAutoChange();
          this.showArrows$.next(true);
          this.bannersCount$.next(bannerDetails.length);
        }
      }
    });

    this.bannerContainer$.subscribe(bannerContainer => {
      this.disableAutoChange();
      if (bannerContainer) {
        this.showBanner$.next(true);
        if (!this.isGesturesRegistered) {
          this.registerGestures();
        }
        if (this.bannersCount$.value > 1) {
          this.enableAutoChange();
        }
      }
    });

    this.pageVisibilityApi.isPageVisible$.subscribe(isPageVisible => {
      this.disableAutoChange();
      if (isPageVisible) {
        this.enableAutoChange();
      }
    });
  }

  ngOnDestroy(): void {
    this.disableAutoChange();
    this.destroy$.next();
    this.destroy$.complete();
  }

  onNextClick(): void {
    if (!this.showArrows$.value) {
      return;
    }
    this.disableAutoChange();
    this.updateCurrentBannerIndex('next');
    this.enableAutoChange();
  }

  onPreviousClick(): void {
    if (!this.showArrows$.value) {
      return;
    }
    this.disableAutoChange();
    this.updateCurrentBannerIndex();
    this.enableAutoChange();
  }

  updateCurrentBannerIndex(action: string = 'previous'): void {
    let activeBannerIndex = this.currentVisibleBannerIndex$.value;
    const bannerCount = this.bannersCount$.value;
    let updatedIndex: number;

    if (action === 'next') {
      activeBannerIndex++;
      updatedIndex = activeBannerIndex < bannerCount ? activeBannerIndex : 0;
    } else {
      activeBannerIndex--;
      updatedIndex = activeBannerIndex >= 0 ? activeBannerIndex : bannerCount - 1;
    }
    this.currentVisibleBannerIndex$.next(updatedIndex);
  }

  clickBannerCouponCode(couponCode: string): void {
    if (!this.couponCodeClicked$) {
      this.couponCodeClicked$.next(true);
      this.bookBetService.getBookBet(couponCode).subscribe(() => this.couponCodeClicked$.next(false));
    }
  }

  indexTrackBy(index: number): number {
    return index;
  }

  private registerGestures() {
    const hammer = new Hammer(this.bannerContainer$.value.nativeElement);
    hammer.on('swipeleft', () => {
      this.onNextClick();
    });
    hammer.on('swiperight', () => {
      this.onPreviousClick();
    });
    hammer.on('press', () => {
      this.disableAutoChange();
    });
    hammer.on('pressup', () => {
      this.enableAutoChange();
    });
    this.isGesturesRegistered = true;
  }

  private enableAutoChange(): void {
    const subscription = interval(5000)
      .pipe(
        tap(() => {
          if (this.bannerContainer$.value) {
            this.updateCurrentBannerIndex('next');
          }
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
    this.bannerSwitchInterval$.next(subscription);
  }

  private disableAutoChange(): void {
    if (this.bannerSwitchInterval$.value) {
      this.bannerSwitchInterval$.value.unsubscribe();
      this.bannerSwitchInterval$.next(undefined);
    }
  }
}
