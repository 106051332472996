<div class="event-container">
  <div class="event-wrapper" [class.no-padding]="!showDetails" [class.show-border]="showBorder" [class.last]="last">
    <app-event-tournament-details [event]="event" [sportId]="sportId" [showOddCount]=false *ngIf="showDetails">
    </app-event-tournament-details>
    <div class="event">
      <div class="event-details">
        <app-event-details [event]="event" [showDate]="showDate" *ngIf="showDetails"
          (click)="matchService.navigateToMatch(event.id, event.name)"></app-event-details>
      </div>
      <div class="event-odds">
        <app-odd-group [odds]="getOdds()" [eventLocation]="eventLocation"></app-odd-group>
      </div>
    </div>
  </div>
  <div class="statistics"
    [@expandCollapse]="{ value: (statisticsQuery.expandedStatistics$ | async) ? 'expanded' : 'collapsed'}">
    <app-statistics *ngIf="event.externalId === (statisticsQuery.selectedExternalId$ | async)"
      [externalId]="event.externalId"></app-statistics>
  </div>
</div>
