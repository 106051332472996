<app-header *ngIf="showHeader" [darkMode]="darkMode" [showIcons]="showIcons"></app-header>
<div *ngIf="showBreadcrumbs && (applicationQuery.embedConfig$ | async)?.hideBreadcrumbs !== true" data-testid="breadcrumb-container"
  class="breadcrumb-container" [class.no-header]="!showHeader"
  [ngClass]="[class, (accountQuery.userData$ | async) && (accountQuery.userData$ | async).userStatus === 'PCHANG' ? 'disable-container': '']">
  <div class="breadcrumb-container-title">
    <i class="fa fa-caret-left" i18n-title title="Back" (click)="goBack()" aria-hidden="true"
      *ngIf="goBackIcon === 'caret-left'"></i>
    <span class="material-icons-outlined close" title="Close" (click)="goBack()" aria-hidden="true"
      *ngIf="goBackIcon === 'close'">{{'close'}}</span>
    <div class="title">
      <div class="text">{{ title }}</div>
    </div>
  </div>
</div>
<app-phone-unverified-tooltip></app-phone-unverified-tooltip>
