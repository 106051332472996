import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { kebabCase } from 'lodash-es';
import { of } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ResolverService } from 'src/app/core/services/resolver.service';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { EventType } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class ProceedToEventsService {
  constructor(
    private readonly sportQuery: SportQuery,
    private readonly resolverService: ResolverService,
    private readonly router: Router,
    private readonly sportService: SportService
  ) {}

  proceedToOneEvent(eventId: number, eventType: EventType, autoForwardHappened: boolean, menuUniqueId: string): void {
    const allIds = this.resolverService.bruteForceResolveByTournamentId(eventId, eventType, menuUniqueId);

    if (!allIds) {
      return;
    }
    const allNames = this.resolverService.convertToNames(allIds, eventType);

    const normalizedSportName = kebabCase(allNames.sport);
    const normalizedCategoryName = kebabCase(allNames.category);
    const normalizeTournamentName = kebabCase(allNames.tournament);

    this.sportService.updateEventSelection({ autoForwardHappened });
    const url = `${eventType}/${normalizedSportName}/${normalizedCategoryName}/${normalizeTournamentName}`;
    this.router.navigate([`/sports/events/${url}`]);
  }

  /**
   * Used by the proceed bar
   *
   * @param depth The current event selection depth
   */
  proceed(depth: number): boolean {
    let events$ = of([]);
    let eventType: EventType = 'prematch';

    if (depth === 1) {
      if (this.sportQuery.selectedQuicklink.id === 2) {
        events$ = this.sportQuery.competitionsAZSelectedElements$;
      } else if (this.sportQuery.selectedQuicklink.id === 0) {
        events$ = this.sportQuery.topCompetitionsSelectedElements$;
      } else {
        return false;
      }
    } else if (depth === 2) {
      switch (this.sportQuery.selectedQuicklink.id) {
        case 4:
          events$ = this.sportQuery.allCompetitionByCountrySelectedElementsDepth2$;
          break;
        case 5:
          events$ = this.sportQuery.outrightsSelectedElementsDepth2$;
          eventType = 'outright';
          break;
        case 6:
          events$ = this.sportQuery.specialSportsSelectedElementsDepth2$;
          eventType = 'special';
          break;
        case 7:
          events$ = this.sportQuery.goalscorerSportSelectedElementsDepth2$;
          eventType = 'special';
          break;
        case 8:
          events$ = this.sportQuery.oddsBoostSelectedElementsDepth2$;
          eventType = 'special';
          break;
        case 9:
          events$ = this.sportQuery.playerSpecialsSelectedElementsDepth2$;
          eventType = 'special';
          break;
        default:
          return false;
      }
    } else {
      return false;
    }

    events$
      .pipe(
        filter(events => !!events),
        take(1)
      )
      .subscribe(data => {
        this.proceedToEvents(data, eventType);
      });

    return true;
  }

  private readonly proceedToEvents = (data, eventType) => {
    if (!data || !data.length) {
      return;
    }

    let url = `${eventType}/`;

    if (data.length === 1) {
      const normalisedNames = this.getNormalizedNames(data[0], eventType);
      url += `${normalisedNames.normalizedSportName}/${normalisedNames.normalizedCategoryName}/${normalisedNames.normalizeTournamentName}`;
    } else if (data.length > 1) {
      data.forEach((event, index) => {
        const eventIds = this.getEventIds(event, eventType);

        url += `${eventIds.sportId},${eventIds.categoryId},${eventIds.tournamentId}`;
        if (index < data.length - 1) {
          url += '-';
        }
      });
    }

    this.sportService.updateEventSelection({ autoForwardHappened: this.sportQuery.eventSelectionDepth === 1 });
    this.router.navigate([`/sports/events/${url}`]);
  };

  private readonly getNormalizedNames = (event, eventType) => {
    const eventIds = this.getEventIds(event, eventType);
    const allNames = this.resolverService.convertToNames(eventIds, eventType);

    return {
      normalizedSportName: kebabCase(allNames.sport),
      normalizedCategoryName: kebabCase(allNames.category),
      normalizeTournamentName: kebabCase(allNames.tournament),
    };
  };

  private readonly getEventIds = (event, eventType) =>
    this.resolverService.bruteForceResolveByTournamentId(event.tournamentId ? event.tournamentId : event.id, eventType);
}
