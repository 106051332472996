import { Injectable } from '@angular/core';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';

import { BetCouponOdd, CouponType } from 'clientside-coupon';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponGroupingType, MarketMatchMap, OddModel } from 'src/app/shared/models/coupon.model';
import {
  AreaModel,
  CategoryModel,
  MarketModel,
  MatchModel,
  RegionModel,
  SportModel,
  TournamentModel,
} from 'src/app/shared/models/sport.model';
import { CouponService } from './coupon.service';

@Injectable({
  providedIn: 'root',
})
export class CouponSelectionService {
  couponType: typeof CouponType = CouponType;

  constructor(
    private readonly couponQuery: CouponQuery,
    private readonly couponService: CouponService,
    private readonly couponStore: CouponStore
  ) {}

  parseSelections(selections: BetCouponOdd[]): void {
    const sports: SportModel[] = [];
    const marketMatches: MarketMatchMap[] = [];

    selections.forEach(selection => {
      const odd = new OddModel({
        marketId: selection.MarketId,
        marketName: selection.MarketName,
        selectionName: selection.SelectionName,
        selectionTypeId: selection.IDSelectionType,
        id: selection.SelectionId,
        value: selection.OddValue,
        unboostedValue: selection.UnboostedOddValue,
        isLocked: selection.IsLocked,
        isExpired: selection.IsExpired,
        spreadValue: selection.SpecialValue === '' ? undefined : parseInt(selection.SpecialValue, 10),
      });

      let sport = sports.find(o => o.id === selection.IDSport);
      if (!sport) {
        sport = new SportModel({
          id: selection.IDSport,
          name: selection.SportName,
          categories: [
            new CategoryModel({
              id: selection.EventId,
              name: selection.EventName,
              tournaments: [],
            }),
          ],
        });

        sports.push(sport);
      }

      let category = sport.categories.find(c => c.id === selection.EventId);
      if (!category) {
        category = new CategoryModel({
          id: selection.EventId,
          name: selection.EventName,
          tournaments: [],
        });

        sport.categories.push(category);
      }

      let tournament = category.tournaments.find(t => t.id === selection.TournamentId);
      const extractedSpreadValue = selection.SelectionName.match(/\((.*?)\)/);

      if (!tournament) {
        tournament = new TournamentModel({
          id: selection.TournamentId,
          name: selection.TournamentName,
          matches: [
            new MatchModel({
              id: selection.MatchId,
              name: selection.MatchName,
              date: selection.EventDate,
              smartBetCode: selection.SmartCode.toString(),
              eventCategory: selection.EventCategory,
              categoryName: selection.TournamentName,
              combinability: selection.CompatibilityLevel,
              fixed: selection.Fixed,
              allowFixed: selection.AllowFixed,
              groupingType: selection.GroupingType,
              isBankerDisabled: this.isBankerDisabled(selection.AllowFixed, selection.Fixed),
              hasExpiredEvents: selection.IsExpired,
              odds: [odd],
            }),
          ],
          regions: [
            new RegionModel({
              isDefault: true,
              areas: [
                new AreaModel({
                  isDefault: true,
                  markets: [
                    new MarketModel({
                      id: selection.MarketId,
                      name: selection.MarketName,
                      groupingType: undefined,
                      marketType: undefined,
                      typeId: selection.MarketTypeId,
                      description: selection.GamePlayDescription,
                      spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
                    }),
                  ],
                }),
              ],
            }),
          ],
        });
        category.tournaments.push(tournament);
        marketMatches.push(
          new MarketMatchMap({
            marketId: selection.MarketId,
            matchIds: [selection.MatchId],
          })
        );
      } else {
        let match = tournament.matches.find(m => m.id === selection.MatchId);
        if (!match) {
          match = new MatchModel({
            id: selection.MatchId,
            name: selection.MatchName,
            date: selection.EventDate,
            smartBetCode: selection.SmartCode.toString(),
            eventCategory: selection.EventCategory,
            categoryName: selection.TournamentName,
            combinability: selection.CompatibilityLevel,
            fixed: selection.Fixed,
            allowFixed: selection.AllowFixed,
            groupingType: selection.GroupingType,
            isBankerDisabled: this.isBankerDisabled(selection.AllowFixed, selection.Fixed),
            hasExpiredEvents: selection.IsExpired,
            odds: [],
          });

          tournament.matches.push(match);
        }

        match.odds.push(odd);

        const area = tournament.regions[0].areas[0];
        let market = area.markets.find(m => m.id === selection.MarketId);
        if (!market) {
          market = new MarketModel({
            id: selection.MarketId,
            name: selection.MarketName,
            groupingType: undefined,
            marketType: undefined,
            typeId: selection.MarketTypeId,
            description: selection.GamePlayDescription,
            spreadValue: extractedSpreadValue ? parseFloat(extractedSpreadValue[1]) : undefined,
          });

          area.markets.push(market);
          marketMatches.push(
            new MarketMatchMap({
              marketId: selection.MarketId,
              matchIds: [selection.MatchId],
            })
          );
        } else {
          const marketMatchesFoundIndex = marketMatches.findIndex(m => m.marketId === selection.MarketId);
          if (!marketMatches[marketMatchesFoundIndex].matchIds.includes(selection.MatchId)) {
            marketMatches[marketMatchesFoundIndex].matchIds.push(selection.MatchId);
          }
        }
      }
    });

    this.sortMatches(sports);
    this.couponStore.updateSelections(sports);
    this.couponStore.updateMarketMatches(marketMatches);
  }

  sortMatches(sports: SportModel[]): void {
    sports.forEach(s => {
      s.categories[0].tournaments.forEach(t => {
        t.matches = t.matches.sort((m1: MatchModel, m2: MatchModel) => m1.id - m2.id);
        t.matches.sort((m1: MatchModel, m2: MatchModel) => m1.id - m2.id);
      });
    });

    sports.sort((s1: SportModel, s2: SportModel) => s1.id - s2.id);
  }

  isBankerDisabled(allowFixed: boolean, fixed: boolean): boolean {
    if (
      !allowFixed ||
      this.couponQuery.couponData.CouponType !== this.couponType.System ||
      this.couponQuery.groupingsTabSelected !== CouponGroupingType.Combination
    ) {
      // In these cases banker should be disabled, no further logic is required
      return true;
    }

    if (!fixed) {
      // Check if odd can be set as banker or not
      const combGroupings = [];
      this.couponQuery.couponData.AllGroupings.forEach((g, idx) => {
        const isLastGrouping = idx === this.couponQuery.couponData.AllGroupings.length - 1;

        if (g.Grouping > 0 && g.Combinations > 0 && this.couponService.isGroupingVisible(g, isLastGrouping)) {
          combGroupings.push(g);
        }
      });

      // If only 1 grouping is available for selection, stop any further Bankers
      if (combGroupings.length <= 1) {
        return true;
      }
    }

    return false;
  }
}
