import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';

@Injectable({
  providedIn: 'root',
})
export class CouponPreviousPageResolver implements Resolve<any> {
  constructor(private readonly location: Location, private readonly couponService: CouponService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
    const path = this.location.path();
    if (path.includes('/coupon') || path.includes('/my-bets')) {
      this.couponService.updatePreviousPage('/');
    } else {
      this.couponService.updatePreviousPage(this.location.path());
    }
  }
}
