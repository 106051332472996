export enum APIType {
  BetRadarResults,
  BetRadarStatistics,
  CMS,
  Guardian,
  F2PGuardian,
  Local,
  Platform,
  Sportsbook,
  SportsbookFeed,
  Virtuals,
  VirtualsFeed,
  JackpotBets,
  JackpotBetsCms,
  Website,
  Engagement,
  SuperPicksValidationDomain,
  Casino,
}

export class APISettings {
  contentType: string;
  playSource: number;
  noAuthToken: boolean;
  forceAuthToken: string;
  inBehalfOf: string;
  sendActivitySource: boolean;
  timeout: number;

  constructor(init?: Partial<APISettings>) {
    Object.assign(this, init);
  }
}

export class APIResult<T> {
  success = false;
  data: T;
  error: string;
}
