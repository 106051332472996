import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, takeUntil, timeout } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { VirtualsQuery } from 'src/app/core/state/virtuals/virtuals.query';
import { VirtualsStore } from 'src/app/core/state/virtuals/virtuals.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { VirtualsJackpot, VirtualsLatestJackpotWinnersInfo } from 'src/app/shared/models/virtuals.model';
import { mapToCamelCase } from 'src/app/shared/utils/map-to-camel-case';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';
import { APIService } from './api.service';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class VirtualsService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly virtualsStore: VirtualsStore,
    private readonly virtualsQuery: VirtualsQuery,
    private readonly appConfig: AppConfigService
  ) {}

  get isVirtualsEnabled(): boolean {
    return this.appConfig.get('virtuals')?.enabled;
  }

  get isScheduledLeagueEnabled(): boolean {
    return this.appConfig.get('virtuals')?.scheduledLeague?.enabled;
  }

  get isInstantLeagueEnabled(): boolean {
    return this.appConfig.get('virtuals')?.instantLeague?.enabled;
  }

  getVirtualsGameFrameUrl(clientType: string = 'web'): Observable<any> {
    return this.apiService
      .get(APIType.Platform, `/api/GamingVendors/GlobalBet/GameFrameUrl?globalBetClientType=${clientType}`)
      .pipe(takeUntil(this.destroy$));
  }

  getVirtualsLatestJackpotWinnerSection(): Observable<VirtualsLatestJackpotWinnersInfo> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const language: string = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService
      .get(APIType.CMS, `/Virtuals/GetVirtualsLatestJackpotWinnerSection?language=${language}`, apiSettings)
      .pipe(takeUntil(this.destroy$));
  }

  getGroupEntityId(userId: number): Observable<any> {
    return this.apiService.get(APIType.Platform, `/api/GamingVendors/GoldenRace/GroupEntityID?userID=${userId}`).pipe(
      map(response => {
        if (response && response.Result) {
          this.virtualsStore.updateGroupEntityId(response.Result);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getCategoryEventsTimings(categoryIds: (number | string)[]): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    if (!categoryIds?.length) {
      return of({});
    }

    const threshold: number = this.appConfig.get('virtuals')?.eventsTimingsThreshold || 0;
    return this.apiService
      .get(APIType.Virtuals, `api/virtuals/getCategoryEventsTimings/${categoryIds?.join(',')}/${threshold}`, apiSettings)
      .pipe(
        map(mapToCamelCase),
        map(response => {
          if (response?.categoryEventsTimings) {
            this.virtualsStore.updateCategoryEventsTimings(response.categoryEventsTimings);
          } else {
            this.virtualsStore.removeCategoryEventsTimings(categoryIds);
          }
        }),
        catchError(error => {
          this.virtualsStore.removeCategoryEventsTimings(categoryIds);
          return throwError(error);
        }),
        takeUntil(this.destroy$)
      );
  }

  getJackpots(): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });

    return this.apiService.get(APIType.VirtualsFeed, 'api/virtuals/getjackpotvalues', apiSettings).pipe(
      map(mapToCamelCase),
      map(response => {
        if (response?.jackpots) {
          const jackpots: VirtualsJackpot[] = [];
          response.jackpots.forEach(jackpot => {
            jackpots.push({
              id: jackpot.jackpotId,
              name: jackpot.jackpotName,
              value: jackpot.jackpotValue,
              dropFrequency: jackpot.jackpotDropFrequencyInSeconds,
            });
          });

          this.virtualsStore.updateJackpots(jackpots);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getGameLobby(includeDisabled: boolean = false): Observable<void> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    // Retrieve the data only once
    if (this.virtualsQuery.lobbyData) {
      return of(undefined);
    }

    const language = this.languageService.selectedLanguage.locale.toLowerCase();
    return this.apiService.get(APIType.CMS, `/Virtuals/GetLobby?language=${language}&includeDisabled=${includeDisabled}`, apiSettings).pipe(
      map(lobbyData => {
        if (lobbyData) {
          this.virtualsStore.updateLobbyData(lobbyData);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  getBreadcrumbNavigation(): Observable<void> {
    if (this.virtualsQuery.breadcrumbNavigation) {
      return of(undefined);
    }
    const language = this.languageService.selectedLanguage.locale.toLowerCase();

    return this.apiService.get(APIType.CMS, `/Virtuals/GetBreadcrumbNavigation?language=${language}`).pipe(
      timeout(5000),
      catchError(err => {
        this.setDefaultBreadcrumbNavigation();
        return throwError(err);
      }),
      map((breadcrumbData: BreadcrumbNavigation) => {
        if (!breadcrumbData || !Object.values(breadcrumbData).some(propertyValue => propertyValue)) {
          this.setDefaultBreadcrumbNavigation();
        } else {
          this.virtualsStore.updateBreadcrumbNavigation(breadcrumbData);
        }
      }),
      takeUntil(this.destroy$)
    );
  }

  private setDefaultBreadcrumbNavigation(): void {
    this.virtualsStore.updateBreadcrumbNavigation(this.appConfig.get('virtuals').breadcrumbNavigationDefaults);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
