import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CashoutModel, CashoutState } from 'src/app/shared/models/cashout.model';
import { ProductType } from 'src/app/shared/models/product.model';
import { CashoutStore } from './cashout.store';

@Injectable({ providedIn: 'root' })
export class CashoutQuery extends QueryEntity<CashoutState> {
  pendingCashoutList$ = this.select(state => state.pendingCashoutList);

  constructor(
    protected store: CashoutStore,
    private readonly appConfigService: AppConfigService,
    private readonly applicationQuery: ApplicationQuery
  ) {
    super(store);
  }

  get isEnabled(): boolean {
    switch (this.applicationQuery.activeProduct) {
      case ProductType.Virtuals:
        return this.appConfigService.get('virtuals').cashout.enabled;
      case ProductType.SportsBook:
        return this.appConfigService.get('sports').cashout.enabled;
      case ProductType.JackpotBets:
      default:
        return false;
    }
  }

  getCashout(code: string): Observable<CashoutModel> {
    return this.selectAll({
      filterBy: e => e.couponCode === code,
    }).pipe(map(cashouts => cashouts[0]));
  }

  hasCashout(code: string): boolean {
    return this.getAll().filter(cashout => cashout.couponCode === code).length > 0;
  }

  pendingCashoutList(): CashoutModel[] {
    return this.getValue().pendingCashoutList;
  }

  isInPendingList(cashoutId: number, couponCode: string): boolean {
    // supports checking by either the cashoutId or the couponCode
    const isInPending = this.pendingCashoutList().find(
      i => (cashoutId && i.cashoutId === cashoutId) || (couponCode && i.couponCode === couponCode)
    );

    return isInPending !== undefined;
  }
}
