import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CouponDetailsService } from 'src/app/core/services/coupon/coupon-details.service';
import { CouponDetailsQuery } from 'src/app/core/state/coupon-details/coupon-details.query';
import { RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { CouponDetailsModel, CouponDetailsUIState } from 'src/app/shared/models/coupon-details.model';
import { ProductType, VirtualsLeagueType } from 'src/app/shared/models/product.model';
import { determineCouponProduct } from 'src/app/shared/utils/determine-coupon-product';

@Component({
  selector: 'my-bets-recent-bet-wrapper',
  templateUrl: './recent-bet-wrapper.component.html',
  styleUrls: ['./recent-bet-wrapper.component.scss'],
})
export class RecentBetWrapperComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  item$ = new BehaviorSubject<RecentBetModel>(undefined);
  isInstant$ = new BehaviorSubject<boolean>(false);

  constructor(
    readonly couponDetailsQuery: CouponDetailsQuery,
    private readonly activatedRoute: ActivatedRoute,
    private readonly couponDetailsService: CouponDetailsService
  ) {}

  ngOnInit(): void {
    const noCheck = this.activatedRoute.snapshot.data.noCheck;

    this.activatedRoute.paramMap
      .pipe(
        switchMap(params => {
          const couponCode = params.get('couponCode');
          const couponProduct = determineCouponProduct(couponCode);

          if (couponProduct.productType === ProductType.Virtuals && couponProduct.virtualsLeagueType === VirtualsLeagueType.Instant) {
            this.isInstant$.next(true);
            return this.couponDetailsService.getInstantCouponDetails(couponCode);
          } else {
            this.isInstant$.next(false);
            return this.couponDetailsService.getCouponDetails(couponCode, noCheck);
          }
        }),
        catchError(this.handleError),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.couponDetailsQuery.couponDetails$
      .pipe(
        filter(details => !!details),
        tap(details => this.item$.next(this.mapToRecentBetModel(details)))
      )
      .subscribe();
  }

  isRebetEnabled(): boolean {
    return this.couponDetailsQuery.couponDetails?.rebetEnabled;
  }

  getBetDetails(): void {
    this.setCollapsed(true);
  }

  ngOnDestroy(): void {
    this.couponDetailsService.clearCashouts();
    this.setCollapsed(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setCollapsed(collapsed: boolean) {
    const item = this.item$.getValue();
    if (item) {
      item.collapsed = collapsed;
      this.item$.next(item);
    }
  }

  private mapToRecentBetModel(details: CouponDetailsModel): RecentBetModel {
    return this.couponDetailsService.mapToRecentBetsModel(details);
  }

  private readonly handleError = (): Observable<any> => {
    const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: true, isLoading: false });
    this.couponDetailsService.updateCouponDetailsUI(uiUpdate2);

    return of(false);
  };
}
