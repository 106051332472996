/* eslint-disable complexity */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { LanguageService } from 'src/app/core/services/language.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly appConfig: AppConfigService,
    readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService
  ) {}

  get<T>(apiType: APIType, apiEndpoint: string, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings),
    };

    return settings?.timeout
      ? this.httpClient.get<T>(apiUrl, httpOptions).pipe(timeout(settings.timeout))
      : this.httpClient.get<T>(apiUrl, httpOptions);
  }

  post<T>(apiType: APIType, apiEndpoint: string, data: any, settings?: APISettings): Observable<any> {
    const apiSettings: APISettings = settings || new APISettings();
    if (!apiSettings.contentType) {
      apiSettings.contentType = 'application/json';
    }

    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, apiSettings),
    };

    return this.httpClient.post<T>(apiUrl, data, httpOptions);
  }

  put<T>(apiType: APIType, apiEndpoint: string, data: any, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings),
    };

    return this.httpClient.put<T>(apiUrl, data, httpOptions);
  }

  delete<T>(apiType: APIType, apiEndpoint: string, settings?: APISettings): Observable<any> {
    const apiUrl = this.generateUrl(apiType, apiEndpoint);
    const httpOptions: any = {
      headers: this.generateHeaders(apiType, settings),
    };

    return this.httpClient.delete<T>(apiUrl, httpOptions);
  }

  getAPIUrl(apiType: APIType): string {
    const apiBaseUrl = this.appConfig.get('apiBaseUrl');

    switch (apiType) {
      case APIType.BetRadarResults:
        return apiBaseUrl.betRadarResults;
      case APIType.BetRadarStatistics:
        return apiBaseUrl.betRadarStatistics;
      case APIType.CMS:
        if (environment.production) {
          return apiBaseUrl.cms;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdCMSUrl');
          return overrideUrl || apiBaseUrl.cms;
        }
      case APIType.Guardian:
        if (environment.production) {
          return apiBaseUrl.guardian;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdGuardianUrl');
          return overrideUrl || apiBaseUrl.guardian;
        }
      case APIType.F2PGuardian:
        if (environment.production) {
          return apiBaseUrl.f2pGuardian;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdF2PGuardianUrl');
          return overrideUrl || apiBaseUrl.f2pGuardian;
        }
      case APIType.Platform:
        if (environment.production) {
          return apiBaseUrl.platform;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdPlatformUrl');
          return overrideUrl || apiBaseUrl.platform;
        }
      case APIType.Sportsbook:
        if (environment.production) {
          return apiBaseUrl.sportsbook;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookUrl');
          return overrideUrl || apiBaseUrl.sportsbook;
        }
      case APIType.SportsbookFeed:
        if (environment.production) {
          return apiBaseUrl.sportsbookFeed;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSportsbookFeedUrl');
          return overrideUrl || apiBaseUrl.sportsbookFeed;
        }
      case APIType.Virtuals:
        if (environment.production) {
          return apiBaseUrl.virtuals;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdVirtualsUrl');
          return overrideUrl || apiBaseUrl.virtuals;
        }
      case APIType.VirtualsFeed:
        if (environment.production) {
          return apiBaseUrl.virtualsFeed;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdVirtualsFeedUrl');
          return overrideUrl || apiBaseUrl.virtualsFeed;
        }
      case APIType.Website:
        if (environment.production) {
          return apiBaseUrl.website;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdFrontendServicesUrl');
          return overrideUrl || apiBaseUrl.website;
        }
      case APIType.Engagement:
        if (environment.production) {
          return apiBaseUrl.engagement;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdEngagementUrl');
          return overrideUrl || apiBaseUrl.engagement;
        }
      case APIType.JackpotBets:
        if (environment.production) {
          return apiBaseUrl.jackpotBets;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdJackpotBetsUrl');
          return overrideUrl || apiBaseUrl.jackpotBets;
        }
      case APIType.JackpotBetsCms:
        if (environment.production) {
          return apiBaseUrl.jackpotBetsCms;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdJackpotbetsCmsUrl');
          return overrideUrl || apiBaseUrl.jackpotBetsCms;
        }
      case APIType.SuperPicksValidationDomain:
        if (environment.production) {
          return apiBaseUrl.superPicksValidationDomain;
        } else {
          const overrideUrl = this.getLocalStorageOverride('ovrdSuperPicksValidationDomain');
          return overrideUrl || apiBaseUrl.superPicksValidationDomain;
        }
      case APIType.Casino:
        return apiBaseUrl.casino;
      case APIType.Local:
      default:
        return '';
    }
  }

  private generateUrl(apiType: APIType, apiEndpoint: string): string {
    const baseUrl: string = this.getAPIUrl(apiType);
    return this.languageService.enableLanguageSelection$.value && apiType === APIType.CMS
      ? apiEndpoint.indexOf('?') > -1
        ? `${baseUrl}/${apiEndpoint}&language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
        : `${baseUrl}/${apiEndpoint}?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
      : `${baseUrl}/${apiEndpoint}`;
  }

  private getLocalStorageOverride(key: string): string {
    // making use of window.localStorage directly in order to avoid having
    // to include the ngx-webstorage prefix value to the keys
    return window.localStorage.getItem(key);
  }

  private generateHeaders(apiType: APIType, settings?: APISettings): HttpHeaders {
    // using VND naming convention for headers which are used only for application logic,
    // following the convention specified in RFC6648 - https://tools.ietf.org/html/rfc6648
    const apiSettings: APISettings = settings || new APISettings();
    let httpHeaders = new HttpHeaders();

    if (
      apiType === APIType.Platform ||
      apiType === APIType.Guardian ||
      apiType === APIType.F2PGuardian ||
      apiType === APIType.Website ||
      apiType === APIType.SuperPicksValidationDomain ||
      apiType === APIType.Engagement
    ) {
      const isGhanaOrKenya = this.applicationQuery.isBrand(['Kenya']) || this.applicationQuery.isBrand(['Ghana']);
      const brandId =
        apiType === APIType.F2PGuardian && !isGhanaOrKenya
          ? this.appConfig.get('f2pBrandId').toString()
          : this.appConfig.get('brandId').toString();

      if (brandId) {
        httpHeaders = httpHeaders.append('X-BRAND-ID', brandId);
      }
    }

    if (apiType === APIType.JackpotBets) {
      httpHeaders = httpHeaders.append('X-API-BRAND', this.appConfig.get('brandId').toString());
      httpHeaders = httpHeaders.append(
        'X-API-PLAYSOURCE',
        this.appConfig.get('jackpotBets') ? this.appConfig.get('jackpotBets').playSourceHeader.toString() : '1'
      );
    }

    if (apiType === APIType.Casino) {
      httpHeaders = httpHeaders.append('X-API-BRAND', this.appConfig.get('brandId').toString());
    }

    if (apiSettings.contentType) {
      httpHeaders = httpHeaders.append('Content-Type', apiSettings.contentType);
    }

    if (apiSettings.inBehalfOf) {
      httpHeaders = httpHeaders.append('inBehalfOf', apiSettings.inBehalfOf);
    }

    if (apiSettings.noAuthToken) {
      httpHeaders = httpHeaders.append('VND.noAuthToken', 'true');
    } else if (apiSettings.forceAuthToken) {
      httpHeaders = httpHeaders.append('VND.forceAuthToken', apiSettings.forceAuthToken);
    }

    if (apiSettings.sendActivitySource) {
      httpHeaders = httpHeaders.append('VND.sendActivitySource', 'true');
    }

    return httpHeaders;
  }
}
