import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { LiveStore } from 'src/app/core/state/live/live.store';
import { OddModel } from 'src/app/shared/models/coupon.model';
import {
  EventModel,
  FavouritesEventListModel,
  LiveFeedModel,
  LiveState,
  MarketModel,
  SelectionModel,
  TournamentModel,
  MarketGroupingDetailsModel,
} from 'src/app/shared/models/live.model';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Injectable({ providedIn: 'root' })
export class LiveQuery extends Query<LiveState> {
  liveFeeds$ = this.select(state => state.liveFeeds);
  selectedFeedId$ = this.select(state => state.selectedFeedId);
  selectedMarkets$ = this.select(state => state.selectedMarkets);
  favouritesEventsList$ = this.select(state => state.favouritesEventsList);
  selectedFeeds$ = combineLatest(this.liveFeeds$, this.selectedFeedId$, this.selectedMarkets$).pipe(
    map(([feeds, selectedFeedId, markets]) => this.mapFeedToSelectedMarket(feeds, selectedFeedId, markets))
  );
  selectedAreaInMatchView$ = this.select(state => state.selectedAreaInMatchView);
  selectedLiveEvent$ = this.select(state => state.selectedLiveEvent);
  selectedLiveEventAreas$ = this.select(state => state.selectedLiveEvent.areas);
  liveViewEnabledByUser$ = this.select(state => state.liveViewEnabledByUser);
  noLiveFeedData$ = this.select(state => state.ui.noLiveFeedData);
  notValidEventId$ = this.select(state => state.ui.notValidEventId);
  liveFeedsLoading$ = this.select(state => state.ui.liveFeedsLoading);
  selectedLiveEventLoading$ = this.select(state => state.ui.selectedLiveEventLoading);
  marketGroupingDetails$ = this.select(state => state.marketGroupingDetails);

  constructor(protected store: LiveStore) {
    super(store);
  }

  get liveFeeds(): any {
    return this.getValue().liveFeeds;
  }

  get selectedFeedId(): any {
    return this.getValue().selectedFeedId;
  }

  get selectedMarkets(): any {
    return this.getValue().selectedMarkets;
  }

  get selectedAreaInMatchView(): any {
    return this.getValue().selectedAreaInMatchView;
  }

  get selectedLiveEvent(): any {
    return this.getValue().selectedLiveEvent;
  }

  get selectedLiveEventAreas(): any {
    return this.getValue().selectedLiveEvent.areas;
  }

  get liveViewEnabledByUser(): any {
    return this.getValue().liveViewEnabledByUser;
  }

  get noLiveFeedData(): any {
    return this.getValue().ui.noLiveFeedData;
  }

  get notValidEventId(): any {
    return this.getValue().ui.notValidEventId;
  }

  get liveFeedsLoading(): any {
    return this.getValue().ui.liveFeedsLoading;
  }

  get marketGroupingDetails(): MarketGroupingDetailsModel[] {
    return this.getValue().marketGroupingDetails;
  }

  get favouritesEventsList(): FavouritesEventListModel[] {
    return this.getValue().favouritesEventsList;
  }

  get liveAreaSelectionIds(): any {
    return this.getValue().liveAreaSelectionIds;
  }

  /**
   * Check if we have a custom match status, else use the one from BE
   * Also check if we are still using old match statuses
   * @param event Match data object
   * @returns Match status label
   */
  getLiveMatchStatusLabel(event: MatchModel): string {
    if (event.eventStatus) {
      const sportMatchStatuses = this.getValue().liveMatchStatuses[this.getValue().selectedFeedId];
      if (sportMatchStatuses) {
        const currentMatchStatus = sportMatchStatuses.find(statuses => statuses.statusId === event.eventStatus.Id);
        return currentMatchStatus ? currentMatchStatus.statusLabel : event.eventStatus.Description;
      } else {
        return event.eventStatus.Description;
      }
    } else {
      return this.getValue().oldLiveMatchStatuses[event.matchStatus];
    }
  }

  private readonly mapFeedToSelectedMarket = (
    feeds: LiveFeedModel[],
    selectedFeedId: number,
    selectedMarkets: MarketModel[]
  ): LiveFeedModel[] => {
    let foundFeeds: LiveFeedModel[];

    if (selectedFeedId === 0) {
      // Id: 0 - 'favourites'
      foundFeeds = feeds.filter(item => this.favouritesEventsList.find(f => f.sportId === item.id));
    } else {
      foundFeeds = feeds.filter(f => f.id === selectedFeedId);
    }

    return foundFeeds.map(f => this.mapFeed(f, this.selectedMarket(selectedMarkets, f.id)));
  };

  private readonly selectedMarket = (selectedMarkets: MarketModel[], sportId: number): MarketModel =>
    selectedMarkets.find(sm => sm.sportId === sportId);

  private readonly mapFeed = (feed: LiveFeedModel, selectedMarket: MarketModel): LiveFeedModel => ({
    ...feed,
    tournaments: feed.tournaments.map(t => this.mapTournament(t, selectedMarket)),
  });

  private readonly mapTournament = (tournament: TournamentModel, selectedMarket: MarketModel): TournamentModel => ({
    ...tournament,
    events: tournament.events.map(e => this.mapEvent(e, selectedMarket)),
  });

  private readonly mapEvent = (event: EventModel, selectedMarket: MarketModel): EventModel => ({
    ...event,
    markets: this.mapToSelectedMarket(event.markets, selectedMarket),
  });

  private readonly mapToSelectedMarket = (markets: MarketModel[], selectedMarket: MarketModel): MarketModel[] => {
    let market = markets.find(m => m.typeId === selectedMarket.typeId);
    market = {
      ...(market || selectedMarket),
      selections: [...this.mapSelections(selectedMarket.selections, market)],
    };

    return [market];
  };

  private readonly mapSelections = (selections: SelectionModel[], market?: MarketModel) =>
    selections.map(s => {
      let selection;

      if (market) {
        selection = market.selections.find(ms => ms.typeId === s.typeId);
      } else {
        selection = {
          ...s,
          specialValue: '',
          odds: [
            new OddModel({
              id: 0,
              value: undefined,
              unboostedValue: undefined,
              enabled: false,
            }),
          ],
        };
      }

      return selection;
    });
}
