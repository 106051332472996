<div class="info-modal" [@fadeInOut] *ngIf="show">
  <div class="info-modal-overlay" (click)="closeModal.emit()"></div>
  <div class="info-modal-container" [class.external]="bodyHTML === ''">
    <div class="header" *ngIf="title !== ''">
      <div class="text">
        <div class="subtitle" [innerText]="subtitle"></div>
        <div class="title" [innerText]="title"></div>
      </div>
      <div class="icon" *ngIf="materialIcon || icon">
        <div class="material-icons modal-icon" *ngIf="materialIcon">{{ materialIcon }}</div>
        <div class="modal-icon" *ngIf="icon" [style.background-image]="'url(' + icon + ')'"></div>
      </div>
    </div>
    <div class="header-external" *ngIf="title === ''">
      <ng-content select="[header]"></ng-content>
    </div>
    <div class="body" *ngIf="bodyHTML !== ''" [innerHTML]="bodyHTML | safeHTML"></div>
    <div class="body-external" *ngIf="bodyHTML === ''">
      <ng-content select="[body]"></ng-content>
    </div>
    <div class="actions">
      <app-button [text]="buttonText" (click)="closeModal.emit()" [buttonType]="buttonType.Transparent"></app-button>
    </div>
  </div>
</div>
