import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatchService } from 'src/app/core/services/match.service';
import { StatisticsQuery } from 'src/app/core/state/statistics/statistics.query';
import { expandCollapse, fadeIn } from 'src/app/shared/animations';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-correct-score-event',
  templateUrl: './correct-score-event.component.html',
  styleUrls: ['./correct-score-event.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeIn(), expandCollapse()],
})
export class CorrectScoreEventComponent {
  @Input() event: MatchModel;
  @Input() sportId: number = undefined;
  @Input() showDetails: boolean = true;

  constructor(readonly statisticsQuery: StatisticsQuery, readonly matchService: MatchService) {}
}
