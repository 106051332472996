import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { XSellAccessConfiguration, XSellContent } from 'src/app/modules/x-sell/models/x-sell-configs.model';
import { XSellState } from 'src/app/modules/x-sell/models/x-sell-state.model';

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'x-sell' })
export class XSellStore extends Store<XSellState> {
  constructor(private readonly appConfigService: AppConfigService) {
    super({
      casinoAccessConfiguration: appConfigService.get('xSell').casino,
      casinoContent: {
        contentURL: undefined,
        spbColor: undefined,
        spbIcon: undefined,
        xSellColor: undefined,
        xSellIcon: undefined,
      },
      casinoXSellInitialised: false,
    });
  }

  setCasinoAccessConfiguration(casinoAccessConfiguration: XSellAccessConfiguration): void {
    this.update({ casinoAccessConfiguration });
  }

  setCasinoContent(casinoContent: XSellContent): void {
    this.update({ casinoContent });
  }

  setCasinoXSellInitialised(casinoXSellInitialised: boolean): void {
    this.update({ casinoXSellInitialised });
  }
}
