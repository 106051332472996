import { NgModule } from '@angular/core';
import { VirtualsGameLobbyComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-game-lobby/virtuals-game-lobby.component';
import { VirtualsSlideUpComponent } from 'src/app/modules/virtuals-widgets/components/virtuals-slide-up/virtuals-slide-up.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [VirtualsGameLobbyComponent, VirtualsSlideUpComponent],
  imports: [SharedModule],
  exports: [VirtualsGameLobbyComponent, VirtualsSlideUpComponent],
})
export class VirtualsWidgetsModule {}
