import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { SportStore } from 'src/app/core/state/sport/sport.store';

import { OddModel } from 'src/app/shared/models/coupon.model';

import {
  AreaModel,
  CategoryModel,
  CorrectScoreOddsModel,
  EventSummaryModel,
  FlattenedSportModel,
  MarketModel,
  MarketModelSpreadValueGroup,
  MatchModel,
  PlayerViewDataModel,
  PlayerViewModel,
  RegionModel,
  SportModel,
  SportQuicklink,
  SportQuicklinks,
  SportState,
  TournamentModel,
} from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class SportQuery extends Query<SportState> {
  selectedPrematch$ = this.select(store => store.selectedPrematch);

  sportsList$ = this.select(store => store.sportsList);

  // Event selection
  selectedSport$ = this.select(store => store.eventSelection.selectedSport);
  isSportsListOpened$ = this.select(store => store.eventSelection.isSportsListOpened);
  eventSelectionQuicklinks$ = this.select(store => store.eventSelection.eventSelectionQuicklinks);
  eventSelectionQuicklinksIsVisible$ = this.eventSelectionQuicklinks$.pipe(
    filter(data => !!data && !!data.quicklinks),
    map(data => this.getQuicklinksWithVisibility(data))
  );
  selectedQuicklink$ = this.select(store => store.eventSelection.selectedQuicklink);
  eventSelectionDepth$ = this.select(store => store.eventSelection.eventSelectionDepth);
  quickLinksState$ = this.select(store => store.eventSelection.quickLinksState);
  selectedMarket$ = this.select(store => store.eventSelection.selectedMarket);
  selectedArea$ = this.select(store => store.eventSelection.selectedArea);
  selectedAreaId$ = this.select(store => store.eventSelection.selectedAreaId);
  areaMarkets$ = this.select(store => store.eventSelection.areaMarkets);
  filterToDepthFour$ = this.select(store => store.eventSelection.filterToDepthFour);
  topCompetitions$ = this.select(store => store.eventSelection.topCompetitions);
  competitionsAZ$ = this.select(store => store.eventSelection.competitionsAZ);
  allCompetitionByCountry$ = this.select(store => store.eventSelection.allCompetitionByCountry);
  outrights$ = this.select(store => store.eventSelection.outrights);
  selectedSportOutrights$ = this.select(store =>
    store.eventSelection.outrights.filter(outright => outright.id === store.eventSelection.selectedSport.id)
  );
  specialSports$ = this.select(store => store.eventSelection.specialSports);
  selectedSpecialSports$ = this.select(store =>
    store.eventSelection.specialSports.filter(s => s.id === store.eventSelection.selectedSport.id)
  );
  goalscorerSport$ = this.select(store => store.eventSelection.goalscorerSport);
  oddsBoostSport$ = this.select(store => store.eventSelection.oddsBoostSport);
  playerSpecialsSport$ = this.select(store => store.eventSelection.playerSpecialsSport);
  areaAndRegionCache$ = this.select(store => store.eventSelection.areaAndRegionCache);
  visibleCardsAtDepthFour$ = this.select(store => store.eventSelection.visibleCardsAtDepthFour);
  selectedIdsAtDepthFour$ = this.select(store => store.eventSelection.selectedIdsAtDepthFour);
  restoreAreaRegionsCache$ = this.select(store => store.eventSelection.restoreAreaRegionsCache);
  eventSelectionLoaded$ = this.select(store => store.eventSelection.eventSelectionLoaded);
  autoForwardHappened$ = this.select(store => store.eventSelection.autoForwardHappened);
  isPlayerArea$ = this.selectedPrematch$.pipe(map(preMatch => (preMatch ? preMatch[0].groupingType === 2 : false)));
  oddsBoostInfoModalCMSContent$ = this.select(store => store.oddsBoostInfoModalCMSContent);
  marketTypeIds$ = this.select(store => store.marketTypeIds);

  topCompetitionsSelectedElements$ = this.topCompetitions$.pipe(
    map(data => {
      if (!data) {
        return;
      }
      return data.filter(elem => elem.selectedInView);
    })
  );

  selectedPrematchSelectedElementCount$ = this.select(store => store.selectedPrematch).pipe(
    map(selectedPrematch => {
      if (!selectedPrematch) {
        return 0;
      }
      let count = 0;
      selectedPrematch.forEach(prematch => {
        prematch.matches.forEach(match => {
          if (match.selectedInView) {
            count++;
          }
        });
      });

      return count;
    })
  );

  selectedPrematchPlayersDataSelectedElementCount$ = this.select(store => store.playersData).pipe(
    map(playersData => {
      if (!playersData) {
        return 0;
      }
      let count = 0;
      playersData.forEach(data => {
        data.data.forEach((matchData: any) => {
          if (matchData.length) {
            matchData.forEach(match => {
              if (match.data) {
                match.data.forEach(player => {
                  if (player.selectedInView) {
                    count++;
                  }
                });
              }
            });
          }
        });
      });

      return count;
    })
  );

  competitionsAZSelectedElements$ = this.select(store => store.eventSelection.competitionsAZ).pipe(
    map(data => {
      if (!data || !this.selectedSport) {
        return;
      }
      const dataFilteredBySport = data.filter(sport => sport.id === this.selectedSport.id)[0];

      return dataFilteredBySport ? dataFilteredBySport.tournaments.filter(tournament => tournament.selectedInView) : undefined;
    })
  );

  allCompetitionByCountrySelectedElements$ = this.select(store => store.eventSelection.allCompetitionByCountry).pipe(
    map(data => {
      if (!data || !this.selectedSport) {
        return;
      }
      const dataFilteredBySport = data.filter(sport => sport.id === this.selectedSport.id)[0];

      return dataFilteredBySport ? dataFilteredBySport.categories.filter(category => category.selectedInView) : undefined;
    })
  );

  allCompetitionByCountrySelectedElementsDepth2$ = this.select(store => store.eventSelection.allCompetitionByCountry).pipe(
    map(data => {
      if (!data || !this.selectedSport) {
        return;
      }
      const dataFilteredBySport = data.filter(sport => sport.id === this.selectedSport.id)[0];
      const selectedTournaments = [];

      if (dataFilteredBySport) {
        const dataFilteredBySelected = dataFilteredBySport.categories.filter(category => category.selectedInView);

        dataFilteredBySelected.forEach(category => {
          category.tournaments.filter(tournament => {
            if (tournament.selectedInView) {
              selectedTournaments.push(tournament);
            }
          });
        });
      }

      return selectedTournaments;
    })
  );

  outrightsSelectedElementsDepth2$ = this.select(store => store.eventSelection.outrights).pipe(
    map(data => {
      if (!data || data.length === 0) {
        return;
      }
      const dataFilteredBySport = data.filter(sport => (this.selectedSport ? sport.id === this.selectedSport.id : false))[0];
      const selectedTournaments = [];

      if (dataFilteredBySport) {
        const dataFilteredBySelected = dataFilteredBySport.categories.filter(category => category.selectedInView);

        dataFilteredBySelected.forEach(category => {
          category.tournaments.filter(tournament => {
            if (tournament.selectedInView) {
              selectedTournaments.push(tournament);
            }
          });
        });
      }

      return selectedTournaments;
    })
  );
  outrightsSelectedElements$ = this.select(store => store.eventSelection.outrights).pipe(
    map(data => {
      if (!data) {
        return;
      }

      const dataFilteredBySport = data.filter(sport => sport.id === this.selectedSport.id)[0];

      return dataFilteredBySport ? dataFilteredBySport.categories.filter(category => category.selectedInView) : undefined;
    })
  );

  specialSportsSelectedElements$ = this.select(store => store.eventSelection.specialSports).pipe(
    map(data => this.mapSpecialSportSelections(data))
  );

  goalscorerSportSelectedElements$ = this.select(store => store.eventSelection.goalscorerSport).pipe(
    map(data => this.mapSpecialSportSelections(data))
  );

  oddsBoostSportSelectedElements$ = this.select(store => store.eventSelection.oddsBoostSport).pipe(
    map(data => this.mapSpecialSportSelections(data))
  );

  playerSpecialsSportSelectedElements$ = this.select(store => store.eventSelection.playerSpecialsSport).pipe(
    map(data => this.mapSpecialSportSelections(data))
  );

  specialSportsSelectedElementsDepth2$ = this.select(store => store.eventSelection.specialSports).pipe(
    map(data => this.mapSpecialSportSelectionsDepthTwo(data))
  );

  goalscorerSportSelectedElementsDepth2$ = this.select(store => store.eventSelection.goalscorerSport).pipe(
    map(data => this.mapSpecialSportSelectionsDepthTwo(data))
  );

  oddsBoostSelectedElementsDepth2$ = this.select(store => store.eventSelection.oddsBoostSport).pipe(
    map(data => this.mapSpecialSportSelectionsDepthTwo(data))
  );

  playerSpecialsSelectedElementsDepth2$ = this.select(store => store.eventSelection.playerSpecialsSport).pipe(
    map(data => this.mapSpecialSportSelectionsDepthTwo(data))
  );

  // Prematch
  selectedPlayerIds$ = this.select(store => store.selectedPlayerIds);
  isItCorrectScore$ = this.select(store => store.isItCorrectScore);
  areas$ = this.select(store => store.areas);
  regions$ = this.select(store => store.regions);
  match$ = this.select(store => (store.match ? store.match.categories[0].tournaments[0].matches[0] : undefined));
  matchSportId$ = this.select(store => (store.match ? store.match.id : undefined));
  matchGroupingType$ = this.select(store => (store.match ? store.match.groupingType : undefined));
  matchMarkets$ = this.select(store => {
    const marketsGroupedBySpreadValue: MarketModelSpreadValueGroup[] = [];
    const handledMarkets: number[] = [];

    if (store.match) {
      (store.ui.filterQuery === ''
        ? store.match.categories[0].tournaments[0].regions[0].areas[0].markets
        : store.match.categories[0].tournaments[0].regions[0].areas[0].markets.filter(market =>
            market.name.toLowerCase().includes(store.ui.filterQuery.toLowerCase())
          )
      ).map(market => {
        const marketIndex = handledMarkets.indexOf(market.id);

        if (marketIndex !== -1 && market.selections.length < 4) {
          marketsGroupedBySpreadValue[marketIndex].name = marketsGroupedBySpreadValue[marketIndex].name.replace(/ *\([^)]*\) */g, '');
          marketsGroupedBySpreadValue[marketIndex].spreadValues.push({
            spreadValue: market.spreadValue,
            spreadDisplayValue: market.spreadDisplayValue,
          });
        } else {
          handledMarkets.push(market.id);
          const newGroup = new MarketModelSpreadValueGroup({
            ...market,
            spreadValue: undefined,
            spreadDisplayValue: undefined,
          });
          newGroup.spreadValues.push({
            spreadValue: market.spreadValue,
            spreadDisplayValue: market.spreadDisplayValue,
          });
          marketsGroupedBySpreadValue.push(newGroup);
        }
      });
      return marketsGroupedBySpreadValue.sort(
        (marketGroup1: MarketModelSpreadValueGroup, marketGroup2: MarketModelSpreadValueGroup) => marketGroup1.order - marketGroup2.order
      );
    } else {
      return undefined;
    }
  });

  matchBoostedMarkets$ = this.matchMarkets$.pipe(
    map(markets =>
      markets
        .map(market =>
          market.selections.filter(selection =>
            market.spreadValues.length > 1
              ? market.spreadValues
                  .map(spread => this.getMatchOddsByMarket(market.id, spread.spreadValue))
                  .map(odds => odds.filter(odd => !!odd.unboostedValue).length > 0)
                  .some(isBoosted => isBoosted)
              : this.getBoostedMatchOddsByMarketAndSelection(market.id, selection.spreadValue, selection.name).length > 0
          ).length > 0
            ? market
            : undefined
        )
        .filter(boostedMarkets => !!boostedMarkets)
    )
  );

  showMatchBoostedOddsSection$ = this.matchBoostedMarkets$.pipe(
    map(
      (boostedMarkets: MarketModelSpreadValueGroup[]) =>
        boostedMarkets.length > 1 ||
        (boostedMarkets.length > 0 &&
          boostedMarkets[0].selections.filter(
            selection =>
              this.getBoostedMatchOddsByMarketAndSelection(boostedMarkets[0].id, selection.spreadValue, selection.name).length > 0
          ).length > 1)
    )
  );

  preMatchCloseAll$ = this.select(state => state.ui.preMatchCloseAll);
  loading$ = this.selectLoading();
  error$ = this.selectError();
  favouriteSportIds$ = this.select(state => state.favouriteSports);
  favouriteSports$: Observable<SportModel[]>;

  constructor(protected store: SportStore) {
    super(store);

    this.favouriteSports$ = combineLatest(this.favouriteSportIds$, this.sportsList$).pipe(
      filter(([favs, sports]) => !!sports),
      map(this.mapIdsToSports),
      map(this.sortSports)
    );
  }

  get sportsList(): SportModel[] {
    return this.getValue().sportsList;
  }

  get areaAndRegionCache(): { areas: AreaModel[]; regions: RegionModel[]; visible: boolean }[] {
    return this.getValue().eventSelection.areaAndRegionCache;
  }

  get areas(): AreaModel[] {
    return this.getValue().areas;
  }

  get selectedIdsAtDepthFour(): number[] {
    return this.getValue().eventSelection.selectedIdsAtDepthFour;
  }

  get autoForwardHappened(): boolean {
    return this.getValue().eventSelection.autoForwardHappened;
  }

  get regions(): RegionModel[] {
    return this.getValue().regions;
  }

  get eventSelectionQuicklinks(): SportQuicklinks {
    return this.getValue().eventSelection.eventSelectionQuicklinks;
  }

  get restoreAreaRegionsCache(): boolean {
    return this.getValue().eventSelection.restoreAreaRegionsCache;
  }

  get selectedArea(): AreaModel {
    return this.getValue().eventSelection.selectedArea;
  }

  get selectedMarket(): MarketModel {
    return this.getValue().eventSelection.selectedMarket;
  }

  get selectedAreaId(): number {
    return this.getValue().eventSelection.selectedAreaId;
  }

  get topCompetitions(): MatchModel[] {
    return this.getValue().eventSelection.topCompetitions;
  }

  get filterToDepthFour(): boolean {
    return this.getValue().eventSelection.filterToDepthFour;
  }

  get areaMarkets(): MarketModel[] {
    return this.getValue().eventSelection.areaMarkets;
  }

  get selectedPrematch(): EventSummaryModel[] {
    return this.getValue().selectedPrematch;
  }

  get competitionsAZ(): FlattenedSportModel[] {
    return this.getValue().eventSelection.competitionsAZ;
  }

  get allCompetitionByCountry(): SportModel[] {
    return this.getValue().eventSelection.allCompetitionByCountry;
  }

  get outrights(): SportModel[] {
    return this.getValue().eventSelection.outrights;
  }

  get specialSports(): SportModel[] {
    return this.getValue().eventSelection.specialSports;
  }

  get goalscorerSport(): SportModel[] {
    return this.getValue().eventSelection.goalscorerSport;
  }

  get oddsBoostSport(): SportModel[] {
    return this.getValue().eventSelection.oddsBoostSport;
  }

  get playerSpecialsSport(): SportModel[] {
    return this.getValue().eventSelection.playerSpecialsSport;
  }

  get isSportsListOpened(): boolean {
    return this.getValue().eventSelection.isSportsListOpened;
  }

  get selectedSport(): SportModel {
    return this.getValue().eventSelection.selectedSport;
  }

  get selectedQuicklink(): SportQuicklink {
    return this.getValue().eventSelection.selectedQuicklink;
  }

  get preMatchMarketFilter(): string {
    return this.getValue().ui.filterQuery;
  }

  get selectedPlayerIds(): number[] {
    return this.getValue().selectedPlayerIds;
  }

  get preMatchCloseAll(): boolean {
    return this.getValue().ui.preMatchCloseAll;
  }

  get favouriteSportIds(): number[] {
    return this.getValue().favouriteSports;
  }

  get eventSelectionDepth(): number {
    return this.getValue().eventSelection.eventSelectionDepth;
  }

  get hasPlayersData(): boolean {
    return !!this.getValue().playersData.length;
  }

  get playersData(): PlayerViewModel[] {
    return this.getValue().playersData;
  }

  get visibleCardsAtDepthFour(): boolean[] {
    return this.getValue().eventSelection.visibleCardsAtDepthFour;
  }

  getPlayersData(tournamentId: number): PlayerViewDataModel[] {
    return this.getPlayerViewData(this.getValue().playersData, tournamentId);
  }

  getMatchOddsByMarket(marketTypeId: number, spreadValue: number): OddModel[] {
    return this.getValue().match.categories[0].tournaments[0].matches[0].odds.filter(
      (odd: OddModel) => odd.marketTypeId === marketTypeId && odd.spreadValue === spreadValue
    );
  }

  getBoostedMatchOddsByMarketAndSelection(marketTypeId: number, spreadValue: number, selectionName?: string): OddModel[] {
    return this.getValue().match.categories[0].tournaments[0].matches[0].odds.filter(
      (odd: OddModel) =>
        odd.marketTypeId === marketTypeId &&
        odd.spreadValue === spreadValue &&
        (selectionName ? odd.selectionName === selectionName : true) &&
        odd.isBoosted
    );
  }

  getMatchCorrectScoreOddsByMarket(marketTypeId: number, spreadValue: number): CorrectScoreOddsModel {
    return this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.homeToWin.findIndex(
      (csOddHTW: OddModel) => csOddHTW.marketTypeId === marketTypeId && csOddHTW.spreadValue === spreadValue
    ) > -1
      ? new CorrectScoreOddsModel({
          homeToWin: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.homeToWin.filter(
            (csOddHTW: OddModel) => csOddHTW.marketTypeId === marketTypeId && csOddHTW.spreadValue === spreadValue
          ),
          awayToWin: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.awayToWin.filter(
            (csOddATW: OddModel) => csOddATW.marketTypeId === marketTypeId && csOddATW.spreadValue === spreadValue
          ),
          draw: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.draw.filter(
            (csOddD: OddModel) => csOddD.marketTypeId === marketTypeId && csOddD.spreadValue === spreadValue
          ),
          others: this.getValue().match.categories[0].tournaments[0].matches[0].correctScoreOdds.others.filter(
            (csOddO: OddModel) => csOddO.marketTypeId === marketTypeId && csOddO.spreadValue === spreadValue
          ),
        })
      : undefined;
  }

  selectIsFavourite(sportId: number): Observable<boolean> {
    return this.select(store => store.favouriteSports.indexOf(sportId) > -1);
  }

  getPlayersData$(tournamentId: number): Observable<PlayerViewDataModel[]> {
    return this.select(store => this.getPlayerViewData(store.playersData, tournamentId));
  }

  getOutrightSportIndex(sportId: number): number {
    return this.getValue().eventSelection.outrights.findIndex(outright => outright.id === sportId);
  }

  private readonly getQuicklinksWithVisibility = (eventSelectionQuicklinks: SportQuicklinks) =>
    eventSelectionQuicklinks.quicklinks.map(element => {
      const newQuicklink = { ...element } as SportQuicklink;

      if (element.type === 0) {
        switch (element.id) {
          case 0:
            newQuicklink.visible = this.topCompetitions && !!this.topCompetitions.length;
            break;
          case 2:
            newQuicklink.visible = this.quicklinkVisibility('competitionsAZ');
            break;
          case 4:
            newQuicklink.visible = this.quicklinkVisibility('allCompetitionByCountry');
            break;
          case 5:
            newQuicklink.visible = this.quicklinkVisibility('outrights');
            break;
          case 6:
            newQuicklink.visible = this.quicklinkVisibility('specialSports');
            break;
          case 7:
            newQuicklink.visible = this.quicklinkVisibility('goalscorerSport');
            break;
          case 8:
            newQuicklink.visible = this.quicklinkVisibility('oddsBoostSport');
            break;
          case 9:
            newQuicklink.visible = this.quicklinkVisibility('playerSpecialsSport');
            break;
          default:
            break;
        }
      } else if (element.type === 1) {
        newQuicklink.visible = true;
      }

      return newQuicklink;
    });

  private readonly mapIdsToSports = ([ids, sports]: [number[], SportModel[]]): SportModel[] => {
    const favourites: SportModel[] = [];

    if (ids) {
      ids.forEach(id => {
        const sport = sports.find(s => s.id === id);
        sport && favourites.push(sport);
      });
    }

    return favourites;
  };

  private readonly sortSports = (sports: SportModel[]): SportModel[] => {
    const sorted = [...sports];

    sorted.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    for (let i = 0; i < sorted.length; i++) {
      if (sorted[i].name === 'Soccer') {
        const soccer = sorted.splice(i, 1);
        sorted.unshift(...soccer);
        break;
      }
    }

    return sorted;
  };

  private getPlayerViewData(set: PlayerViewModel[], tournamentId: number): PlayerViewDataModel[] {
    const dataItem = set.find(data => data.tournamentId === tournamentId);
    return dataItem ? dataItem.data : undefined;
  }

  private quicklinkVisibility(key: string): boolean {
    const quicklinkVisibility = this[key] ? this[key].filter(sport => sport.id === this.selectedSport.id) : undefined;

    return quicklinkVisibility && !!quicklinkVisibility.length;
  }

  private mapSpecialSportSelections(data: SportModel[]): CategoryModel[] {
    if (!data) {
      return;
    }

    const selectedCategories = [];
    data.forEach(sport => {
      const selected = sport.categories.filter(category => category.selectedInView);
      if (selected.length) {
        selectedCategories.push(...selected);
      }
    });

    return selectedCategories;
  }

  private mapSpecialSportSelectionsDepthTwo(data: SportModel[]): TournamentModel[] {
    if (!data) {
      return;
    }

    const selectedTournaments = [];
    data.forEach(sport => {
      const selected = sport.categories.filter(category => category.selectedInView);
      if (selected.length) {
        selected.forEach(category => {
          category.tournaments.filter(tournament => {
            if (tournament.selectedInView) {
              selectedTournaments.push(tournament);
            }
          });
        });
      }
    });

    return selectedTournaments;
  }
}
