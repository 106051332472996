<ng-container *ngIf="!bookedCouponData && (couponQuery.hasCouponSelections$ | async)">
  <div class="coupon-header">
    <div class="coupon-title" i18n>
      <span>Betslip</span>
      &nbsp;
      <span class="selections-count">{{ couponQuery.couponData.Odds.length }}</span>
    </div>
    <span class="coupon-close material-icons" (click)="goBack()">{{ 'close' }}</span>
  </div>
  <div class="top-panel" [class.no-tabs]="couponQuery.couponData.CouponType === couponType.Single" appResizeOnScroll [minHeight]="68">
    <div class="top-content">
      <div class="clear-all" (click)="clearCouponData()">
        <span class="material-icons icon">{{ 'delete' }}</span>
        &nbsp;
        <span class="text" (click)="clearCouponData()" i18n>Clear All</span>
      </div>
    </div>

    <div class="groupings-tab-control" *ngIf="couponQuery.couponData.CouponType !== couponType.Single">
      <!-- We can only determine if it's a multiple or split bet based on the number of combinations available for the last grouping -->
      <div
        class="groupings-tab"
        *ngIf="groupingTabsVisible.multiple"
        [class.selected]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Multiple"
        (click)="groupingTabClicked(groupingType.Multiple)"
        i18n
      >
        Multiple
      </div>
      <!-- We can only determine if it's a multiple or split bet based on the number of combinations available for the last grouping -->
      <div
        class="groupings-tab"
        *ngIf="groupingTabsVisible.split"
        [class.selected]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Split"
        (click)="groupingTabClicked(groupingType.Split)"
        i18n
      >
        Split Bet
      </div>
      <div
        class="groupings-tab"
        *ngIf="groupingTabsVisible.singles && allowCombinationBets"
        [class.selected]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Singles"
        (click)="groupingTabClicked(groupingType.Singles)"
        i18n
      >
        Singles
      </div>
      <div
        class="groupings-tab"
        *ngIf="groupingTabsVisible.combination && allowCombinationBets"
        [class.selected]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Combination"
        (click)="groupingTabClicked(groupingType.Combination)"
        i18n
      >
        Comb. Bet
      </div>
    </div>
  </div>
  <div class="coupon-wrapper" cdkScrollable>
    <div class="main-content" [ngClass]="{ authenticated: (accountQuery.isAuthenticated$ | async) }">
      <app-coupon-selections></app-coupon-selections>

      <div class="coupon-settings" [class.full-width]="!showStakeReduction">
        <div class="setting">
          <div class="label" i18n>Allow Odds to Change</div>
          <app-toggle-switch
            [switch]="(couponQuery.couponSettings$ | async).allowOddChanges"
            (click)="updateCouponSetting('allowOddChanges', !couponQuery.couponSettings.allowOddChanges)"
          >
          </app-toggle-switch>
        </div>
        <div class="setting" *ngIf="showStakeReduction">
          <div class="label" i18n>Allow stake reduction</div>
          <app-toggle-switch
            [switch]="(couponQuery.couponSettings$ | async).allowStakeReduction"
            (click)="updateCouponSetting('allowStakeReduction', !couponQuery.couponSettings.allowStakeReduction)"
          >
          </app-toggle-switch>
        </div>
      </div>

      <coupon-freebets-available [productType]='freeBetProductType.SportsBook'></coupon-freebets-available>

      <app-coupon-groupings
        *ngIf="couponQuery.couponData.CouponType !== couponType.Single"
        [hidden]="(couponQuery.groupingsTabSelected$ | async) === groupingType.Multiple"
        [groups]="couponQuery.couponData.AllGroupings"
      ></app-coupon-groupings>

      <div class="coupon-actions">
        <div [@fadeInOut] class="waiting-freebet-overlay" *ngIf="showFreebetsOverlay$ | async">
          <span class="text" i18n>Checking for freebets</span>
          <i class="fa fa-spin fa-spinner"></i>
        </div>

        <app-coupon-totals [couponData]="couponQuery.couponDataFiltered$ | async"></app-coupon-totals>

        <div class="app-message-container" *ngIf="couponQuery.hasLockedOdds$ | async">
          <app-message i18n-text i18n-title type="error" title="Bet cannot be placed" text="One or more of your selections are suspended">
          </app-message>
        </div>

        <div class="app-message-container" *ngIf="couponQuery.hasInvalidFreebetSelections$ | async">
          <app-message
            i18n-text
            i18n-title
            type="error"
            title="Selections not valid for Free Bet"
            text="Please remove invalid selections to place a Free Bet"
          >
          </app-message>
        </div>

        <div class="buttons">
          <ng-container *ngIf="(hasOddChanges$ | async) === false || (couponQuery.couponSettings$ | async).allowOddChanges">
            <app-button
              i18n-text
              text="Book"
              class="book-bet"
              [customButtonStyle]="actionButtonStyle"
              [buttonType]="buttonType.Tertiary"
              [disabled]="(canBookCoupon$ | async) === false"
              (btnClick)="bookCoupon()"
              *ngIf="
                (canBookWhenLoggedIn || (accountQuery.isAuthenticated$ | async) === false) &&
                (couponQuery.couponHasLiveEvents$ | async) === false
              "
            ></app-button>

            <app-button
              i18n-text
              text="Place Bet"
              class="place-bet"
              [customButtonStyle]="actionButtonStyle"
              [disabled]="
                (canPostCoupon$ | async) === false ||
                (couponQuery.hasLockedOdds$ | async) === true ||
                (couponQuery.hasInvalidFreebetSelections$ | async)
              "
              [buttonType]="buttonType.Highlight"
              (btnClick)="postCoupon()"
            >
            </app-button>
          </ng-container>

          <ng-container *ngIf="(hasOddChanges$ | async) && (couponQuery.couponSettings$ | async).allowOddChanges === false">
            <div class="message" i18n>
              Some of the selected odds have changed. Please accept the changes to proceed or remove the items.
            </div>
            <app-button
              i18n-text
              text="Accept"
              class="accept-bet"
              [customButtonStyle]="actionButtonStyle"
              [buttonType]="buttonType.Highlight"
              (btnClick)="acceptOddChanges()"
            ></app-button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!bookedCouponData && (couponQuery.hasCouponSelections$ | async) === false">
  <div class="coupon-empty-wrapper">
    <div class="coupon-header">
      <div class="coupon-title" i18n>
        <span>Betslip</span>
      </div>
      <span class="coupon-close material-icons" (click)="goBack()">{{ 'close' }}</span>
    </div>
    <div class="coupon-empty">
      <div class="coupon-empty-title" i18n>Your Betslip is currently empty</div>
      <div class="coupon-empty-content" i18n>Close Betslip and click on the odds to add your selections.</div>
    </div>
    <app-button i18n-text text="Continue Betting" [customButtonStyle]="actionButtonStyle"
                [buttonType]="buttonType.Highlight" (btnClick)="continueBetting()"></app-button>
  </div>
</ng-container>

<div class="coupon-booked-wrapper" *ngIf="bookedCouponData">
  <div class="booked-info">
    <div class="header-text" i18n>Congratulations! Your bet has been booked.</div>
    <div class="description-text" i18n *ngIf="showBookedBetRetailMessage">
      For your bet to be valid, take your booking code to any {{ brandInfo.brandName }} shop to place your bet. Note that odds might change.
    </div>

    <div class="book-another-bet">
      <app-button i18n-text text="Book Another Bet" (btnClick)="continueBetting()" [buttonType]="buttonType.Secondary"> </app-button>
    </div>

    <div *ngIf="(accountQuery.isAuthenticated$ | async) === false">
      <div class="header-text" i18n>Want to Bet Online?</div>
      <div class="description-text" i18n>Log in to your account or register to be able to place your bet directly.</div>

      <div class="login-register">
        <app-button i18n-text text="Login" (btnClick)="applicationService.showLogin()"></app-button>
        <app-button
          i18n-text
          text="Register"
          (btnClick)="close()"
          [routerLink]="[this.appConfigService.get('registrationRedirect') || '/register']"
          [buttonType]="buttonType.Accent"
        >
        </app-button>
      </div>
    </div>
  </div>

  <div class="coupon-totals-container">
    <app-coupon-totals
      [couponData]="bookedCouponData.couponData"
      [bookedCouponCode]="bookedCouponData.bookedCouponCode"
      [bookedCouponDate]="bookedCouponData.bookedCouponDate"
      [bookedCoupon]="true"
    ></app-coupon-totals>
  </div>

  <app-coupon-selections [bookedCoupon]="true"></app-coupon-selections>
</div>

<ng-template #betInProgress>
  <app-loading-bar [show]="true" [transparent]="true"></app-loading-bar>
  <div class="loading-text" i18n>Your bet is being processed</div>
</ng-template>

<app-loading-circle [show]="showLoadingOverlay$ | async"></app-loading-circle>
