<div class="overlay" *ngIf="show" [@fadeInOutBy]="{ value: '', params: { duration: 200 } }" (click)="closeDialog(false)"
     data-testid="login-dialog">
  <div class="login-dialog" (click)="loginClicked($event)">
    <div class="header-section">
      <span i18n>Please Login</span>
    </div>

    <div class="login-section">
      <div class="login-form" [formGroup]="loginForm" (keydown.enter)="login()">
        <ng-container [ngSwitch]="applicationQuery.loginType">
          <div *ngSwitchCase="loginType.Username" class="form-field username">
            <input
              type="text"
              formControlName="userName"
              placeholder="Username"
              i18n-placeholder
              class="login-element"
              autocomplete="username"
              [class.login-error]="errorMessage$ | async"
            />
          </div>
          <div *ngSwitchCase="loginType.Mobile" class="form-field mobile-number">
            <div class="inputs">
              <app-mobile-prefix></app-mobile-prefix>
              <input
                type="text"
                formControlName="userName"
                placeholder="Mobile Number"
                i18n-placeholder
                class="login-element"
                autocomplete="username"
                [class.login-error]="errorMessage$ | async"
              />
            </div>
          </div>
          <div *ngSwitchCase="loginType.UsernameOrMobile" class="form-field username-or-mobile">
            <input
              type="text"
              formControlName="userName"
              placeholder="Username or Verified Mobile"
              i18n-placeholder
              class="login-element"
              autocomplete="username"
              [class.login-error]="errorMessage$ | async"
            />
          </div>
        </ng-container>

        <app-form-message *ngIf="errorMessage$ | async" type="danger" class="error-message" [text]="errorMessage$.value">
        </app-form-message>

        <div class="form-field password-field">
          <input
            [type]="(showPassword$ | async) ? 'text' : 'password'"
            class="password"
            formControlName="password"
            placeholder="Password" />
          <span
            (click)="showPassword$.next(!showPassword$.value)"
            class="fa toggle-password"
            [class.fa-eye]="(showPassword$ | async) === false"
            [class.fa-eye-slash]="showPassword$ | async"
          ></span>
        </div>
        <div class="buttons-section">
          <app-button
            class="cancel-button"
            (btnClick)="closeDialog(false)"
            [buttonType]="buttonType.TransparentSecondary"
            i18n-text
            text="Cancel"
          >
          </app-button>
          <app-button
            class="login-button"
            (click)="login()"
            [disabled]="loginForm.invalid && loginForm.touched"
            i18n-text
            text="Login"
            [fullWidth]="true"
            [loading]="loginInProgress$ | async"
            [buttonType]="buttonType.Highlight"
          ></app-button>
        </div>

        <div class="forgot-password-link">
          <span (click)="onForgetPasswordClick()" i18n>Forgotten Password?</span>
        </div>
      </div>
    </div>

    <div class="register-section">
      <div class="register-text" i18n>Need an account?</div>
      <app-button
        (btnClick)="onRegisterClick()"
        class="register-button"
        [buttonType]="buttonType.TransparentSecondary"
        [customButtonStyle]="ctaRegisterAdditionalStyles"
        i18n-text
        text="Register"
      >
      </app-button>
    </div>
  </div>
</div>
