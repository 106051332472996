import { Injectable } from '@angular/core';
import { BetCouponGroup, ClientsideCouponService, CouponAction, UpdateCouponRequest, UpdateCouponResponse } from 'clientside-coupon';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { VirtualsCouponQuery } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.query';
import { VirtualsCouponStore } from 'src/app/core/state/virtuals-coupon/virtuals-coupon.store';

@Injectable({
  providedIn: 'root',
})
export class VirtualsCouponStakeHandlerService {
  constructor(
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly appConfig: AppConfigService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly virtualsCouponQuery: VirtualsCouponQuery,
    private readonly virtualsCouponStore: VirtualsCouponStore
  ) {}

  updateStakeValue(stakeValue: number): UpdateCouponResponse {
    if (stakeValue >= 0) {
      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.UpdateStakeValue,
          brandID: this.appConfig.get('brandId'),
          coupon: this.virtualsCouponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.virtualsCouponQuery.globalVariables,
          marketExceptions: this.virtualsCouponQuery.marketExceptions,
          correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
          stakeValue: stakeValue,
        })
      );

      this.virtualsCouponStore.updateCouponData(response.updatedCoupon);

      return response;
    }
  }

  updateGroupingStakeValue(grouping: BetCouponGroup): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupingStakeValue,
        brandID: this.appConfig.get('brandId'),
        coupon: this.virtualsCouponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.virtualsCouponQuery.globalVariables,
        marketExceptions: this.virtualsCouponQuery.marketExceptions,
        correctScoreOddsMatrix: this.virtualsCouponQuery.correctScoreOddsMatrixData,
        groupings: [grouping],
        groupingStakeValue: grouping.Stake,
      })
    );

    this.virtualsCouponStore.updateCouponData(response.updatedCoupon);
    return response;
  }
}
