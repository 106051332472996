import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreadcrumbNavigation } from 'src/app/shared/models/breadcrumb-navigation';
import { BreadcrumbNavigationService } from 'src/app/core/services/breadcrumb-navigation.service';
@Component({
  selector: 'app-breadcrumb-navigation',
  templateUrl: './breadcrumb-navigation.component.html',
  styleUrls: ['./breadcrumb-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbNavigationComponent implements OnInit, AfterViewInit {
  @Input() breadcrumbNavigationStructure: BreadcrumbNavigation;
  @ViewChild('scrollableContainer') scrollableContainer: ElementRef;

  isMainParentActive: boolean = false;

  constructor(
    readonly breadcrumbNavigationService: BreadcrumbNavigationService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  ngOnInit() {
    if (this.router.url.split('?')[0] === this.breadcrumbNavigationStructure.url) {
      this.isMainParentActive = true;
    }
  }

  ngAfterViewInit() {
    // Scroll to be aligned on the right position
    const scrollableContainer = this.scrollableContainer?.nativeElement;
    scrollableContainer && scrollableContainer.scroll(scrollableContainer.clientWidth, 0);
  }

  onScroll() {
    this.breadcrumbNavigationService.closeOpenDropdown();
  }

  goToPage(item): void {
    this.breadcrumbNavigationService.closeOpenDropdown();
    this.router.navigateByUrl(item);
  }

  goBack(): void {
    this.location.back();
  }
}
