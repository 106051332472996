<app-breadcrumb-navigation *ngIf="showBreadcrumbs && virtualsQuery.breadcrumbNavigation$ | async as breadcrumbNavigation" [breadcrumbNavigationStructure]='breadcrumbNavigation'></app-breadcrumb-navigation>

<app-loading-bar [show]="(virtualsQuery.lobbyData$ | async) === undefined" [transparent]="true" [delay]="1000">
</app-loading-bar>

<div *ngIf="virtualsQuery.lobbyData$ | async as lobbyData" class="lobby-container">
  <h1 *ngIf="showPageTitle && lobbyData.pageTitle" class="header">{{ lobbyData.pageTitle }}</h1>

  <div class="section-container">
    <div *ngFor="let section of lobbyData.sections" class="section">
      <div class="section-header">
        <div class="title-container">
          <div *ngIf="section.title" class="title" [class.is-new]="section.isNew">
            <h1>{{ section.title }}</h1>
            <span *ngIf="section.isNew" class="new-badge" i18n>NEW</span>
          </div>
          <h1 *ngIf="section.subTitle" class="subtitle">{{ section.subTitle }}</h1>
        </div>
        <div *ngIf="virtualsQuery.jackpotByCode$(section.jackpot) | async as jackpotData" class="jackpot-container"
          (click)="jackpotClick(section.jackpotClickUrl)">
          <div class="jackpot" [ngClass]="'jackpot-' + section.jackpot">
            <span *ngIf="section.jackpotLabel" class="jackpot-label">{{ section.jackpotLabel }}</span>
            <span class="jackpot-value">{{jackpotData.value | currencyFormat: '1.0-0'}}</span>
          </div>
          <span *ngIf="getDropFrequency(section.jackpotDropFrequencyText, jackpotData.dropFrequency) as dropFrequency"
            class="jackpot-frequency">
            {{ dropFrequency }}
          </span>
        </div>
      </div>

      <div class="subsection-container">
        <div *ngFor="let subSection of section.subSections" class="subsection">
          <h1 *ngIf="subSection.title" class="title">{{ subSection.title }}</h1>
          <div class="game-container" [style.--games-per-row]="subSection.gamesPerRow || 2">
            <div *ngFor="let game of subSection.games" class="game" [class.disabled]="isGameDisabled(game)"
              [ngClass]="['game-' + game.code, 'type-' + game.gameType]"
              [attr.data-testid]="'game-' + (game.code | kebabCase)" (click)="openGame(game)">
              <div class="logo">
                <img *ngIf="game.logoUrl" [src]="game.logoUrl" [alt]="game.code" />
              </div>
              <div class="extra-fields" *ngIf="game.showCountdown || game.showPlayButton">
                <app-pie-countdown *ngIf="virtualsQuery.categoryEventTiming$(game.providerId) | async as countdown"
                  class="countdown" [targetDate]="countdown.nextEventDate" [pieFullDuration]="countdown.eventInterval"
                  [pieSize]="7" [textSize]="12" [textLineHeight]="10" [borderSpacing]="1" [borderThickness]="1"
                  [showCountdownText]="true" [showBackground]="false" [color]="'rgba(255, 255, 255, 0.56)'"
                  [textColor]="'rgba(255, 255, 255, 0.56)'" (timeElapsed)="refreshEventTiming(game.providerId)">
                </app-pie-countdown>
                <span *ngIf="game.showPlayButton" class="play-button" i18n>Play</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="cta-container" *ngIf="section.ctaButtonText && section.ctaClickUrl">
        <span *ngIf="section.ctaLabel" class="label">{{ section.ctaLabel }}</span>
        <span class="button" data-testid="cta-button" (click)="ctaClick(section.ctaClickUrl)">
          {{ section.ctaButtonText }}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="showInfoBubble && (lobbyData.infoBubbleTitle || lobbyData.infoBubbleContent)" class="info-bubble">
    <h1 *ngIf="lobbyData.infoBubbleTitle" class="title">{{ lobbyData.infoBubbleTitle }}</h1>
    <span *ngIf="lobbyData.infoBubbleContent" [innerHTML]="lobbyData.infoBubbleContent | safeHTML"
      class="content"></span>
  </div>
</div>
