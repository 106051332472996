import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { BetCoupon, BetCouponGlobalVariable, BetCouponOdd, CouponType, Dictionary, StringDictionary } from 'clientside-coupon';
import { map } from 'rxjs/operators';

import { DefaultCouponStake } from 'src/app/shared/models/coupon.model';
import { VirtualsCouponSettings, VirtualsCouponState, VirtualsCouponUIState } from 'src/app/shared/models/virtuals-coupon.model';
import { VirtualsCouponStore } from './virtuals-coupon.store';

@Injectable({ providedIn: 'root' })
export class VirtualsCouponQuery extends Query<VirtualsCouponState> {
  bookedCoupons$ = this.select(state => state.bookedCoupons);
  couponInitialized$ = this.select(state => state.couponInitialized);
  hasCouponSelections$ = this.select(state => state.couponData && state.couponData.Odds.length > 0);
  couponData$ = this.select(state => state.couponData);
  couponDataFiltered$ = this.select(state => state.couponData).pipe(
    map(couponData => {
      if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
        return undefined;
      }
      return couponData;
    })
  );
  couponSelectionIds$ = this.select(state => (state.couponData ? state.couponData.Odds.map(odd => odd.SelectionId) : [])); //
  couponSettings$ = this.select(state => state.couponSettings);
  defaultCouponStake$ = this.select(state => state.defaultCouponStake);
  groupingsTabSelected$ = this.select(state => state.groupingsTabSelected);
  showCoupon$ = this.select(state => state.ui.showCoupon);
  showQuickCoupon$ = this.select(state => state.ui.showQuickCoupon);
  selections$ = this.select(state => state.selections);
  minBetStake$ = this.select(state => (state.globalVariables ? state.globalVariables.MinBetStake : false));
  couponTotalOdds$ = this.select(state => {
    if (state.couponData) {
      return state.couponData.CouponType === CouponType.System ? state.couponData.MaxOdd : state.couponData.TotalOdds;
    } else {
      return 0;
    }
  });

  constructor(protected store: VirtualsCouponStore) {
    super(store);
  }

  get showCoupon(): boolean {
    return this.getValue().ui.showCoupon;
  }

  get showQuickCoupon(): boolean {
    return this.getValue().ui.showQuickCoupon;
  }

  get uiState(): VirtualsCouponUIState {
    return this.getValue().ui;
  }

  get couponData(): BetCoupon {
    const couponData: BetCoupon = this.getValue().couponData;
    if (couponData === null || couponData === undefined || couponData.Odds.length <= 0) {
      return undefined;
    }
    return couponData;
  }

  get couponSettings(): VirtualsCouponSettings {
    const couponSettings: VirtualsCouponSettings = this.getValue().couponSettings;
    if (couponSettings === null || couponSettings === undefined) {
      return new VirtualsCouponSettings();
    }
    return couponSettings;
  }

  get defaultCouponStake(): DefaultCouponStake {
    return this.getValue().defaultCouponStake;
  }

  get couponInitialized(): boolean {
    return this.getValue().couponInitialized;
  }

  get globalVariables(): BetCouponGlobalVariable {
    return this.getValue().globalVariables;
  }

  get marketExceptions(): Dictionary<number, number[]> {
    return this.getValue().marketExceptions;
  }

  get correctScoreOddsMatrix(): any {
    return this.getValue().correctScoreOddsMatrix;
  }

  get groupingsTabSelected(): any {
    return this.getValue().groupingsTabSelected;
  }

  get correctScoreOddsMatrixData(): StringDictionary<string[]> {
    const correctScoreOddsMatrix = this.getValue().correctScoreOddsMatrix;
    if (!correctScoreOddsMatrix) {
      return correctScoreOddsMatrix;
    }
    return correctScoreOddsMatrix.data;
  }

  getSameMatchSelection(matchId: number): BetCouponOdd {
    return this.getValue().couponData ? this.getValue().couponData.Odds.find(o => o.MatchId === matchId) : undefined;
  }
}
