import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { first, map, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CouponReceiptService } from 'src/app/core/services/coupon/coupon-receipt.service';
import { CouponService } from 'src/app/core/services/coupon/coupon.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { ButtonType } from 'src/app/shared/models/button.model';
import { CouponReceiptContentModel } from 'src/app/shared/models/coupon-receipt.model';
import { circleLoaderSuccess } from 'src/assets/json/lottie-configs';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';

enum ReceiptState {
  Confirmation,
  PreVerification,
  InputNumber,
  InputOTP,
  NumberVerified,
  ContentUnavailable, // For if CMS content is not retrieved
}

@Component({
  selector: 'coupon-successful-bet',
  templateUrl: './coupon-successful-bet.component.html',
  styleUrls: ['./coupon-successful-bet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponSuccessfulBetComponent implements OnInit, OnDestroy {
  currencyFormatPipe: CurrencyFormatPipe;

  buttonType = ButtonType;
  inputOTPMessage: string[];
  inputOTPMessageHasUserPhoneNumber: boolean;
  otpCodeLength = this.appConfig.get('otp').otpCodeLength;
  readonly invalidCode$ = new BehaviorSubject<boolean>(false);
  readonly limitExceeded$ = new BehaviorSubject<boolean>(false);
  readonly mobileNumber$ = new BehaviorSubject('');
  readonly mobilePrefix$ = new BehaviorSubject('');
  readonly otpInputGenericError$ = new BehaviorSubject<boolean>(false);
  readonly receiptContent$ = new BehaviorSubject<CouponReceiptContentModel>(undefined);
  readonly receiptState$ = new BehaviorSubject<ReceiptState>(ReceiptState.ContentUnavailable);
  readonly verifyingCode$ = new BehaviorSubject<boolean>(false);
  receiptStates = ReceiptState;
  showTelExtDropdownArrow = true;
  winNotificationSetting: boolean = this.appConfig.get('account').enableWinNotificationSetting;
  hasFreeBetSelected$ = this.couponQuery.lastPlacedCoupon$.pipe(map(coupon => coupon?.BetDetails?.FreeBetDetails?.code !== undefined));

  betslipLoadCompleteOptions = circleLoaderSuccess;

  ctaAdditionalStyles = {
    fontSize: '14px',
    fontWeight: 'normal',
    minWidth: '110px',
    height: '40px',
    padding: '15px 24px',
  };

  private numberChanged = false;
  private readonly ACCUMULATOR_BONUS_PERCENTAGE_KEY = '[[Accumulator_Bonus_Percentage]]';
  private readonly FREEBET_STAKE_KEY = '[[stake]]';
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  private rebetCoupon = false;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    readonly couponQuery: CouponQuery,
    readonly registrationQuery: RegistrationQuery,
    private readonly accountService: AccountService,
    private readonly appConfig: AppConfigService,
    private readonly couponReceiptService: CouponReceiptService,
    private readonly couponService: CouponService,
    private readonly dataLayerService: DataLayerService,
    private readonly router: Router,
    private readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly notificationService: NotificationService,
    private readonly ngNavigatorShareService: NgNavigatorShareService
  ) {
    // This is placed here as putting in the OnInit lifecycle method will cause a visual glitch
    // where the navbar hiding is delayed
    this.couponReceiptService.hideNavbar();
    this.currencyFormatPipe = new CurrencyFormatPipe(this.applicationQuery, this.languageService, this.currencyService);
  }

  ngOnInit(): void {
    combineLatest([this.couponQuery.couponReceiptContent$, this.couponQuery.couponReceiptPhoneVerificationContent$])
      .pipe(
        tap(([receiptContent, receiptPhoneVerificationContent]) => {
          this.receiptContent$.next(
            receiptContent
              ? {
                  ...receiptContent,
                  receiptConfirmationAccumulatorBonusSubtitle: this.prepareReceiptConfirmationAccumulatorBonusSubtitle(
                    receiptContent.receiptConfirmationAccumulatorBonusSubtitle
                  ),
                  receiptConfirmationFreebetSubtitle: this.prepareReceiptConfirmationFreebetSubtitle(
                    receiptContent.receiptConfirmationFreebetSubtitle
                  ),
                }
              : undefined
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          // success
          this.receiptState$.next(
            this.accountQuery.isPhoneVerified || !this.winNotificationSetting
              ? this.couponQuery.hasCouponReceiptContent
                ? ReceiptState.Confirmation
                : ReceiptState.ContentUnavailable
              : this.couponQuery.hasCouponReceiptContent && this.couponQuery.hasCouponReceiptPhoneVerificationContent
              ? ReceiptState.PreVerification
              : ReceiptState.ContentUnavailable
          );
        },
        () => {
          // error
          this.receiptState$.next(ReceiptState.ContentUnavailable);
        }
      );
  }

  ngOnDestroy(): void {
    if (!this.rebetCoupon) {
      // Clear coupon here instead of straight after coupon creation, as we need some coupon information in the betslip receipt
      // We can assume that if the user is here, the coupon was created successfully.
      this.couponService.clearCouponData();
    }

    this.couponReceiptService.showNavbar();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  navigateBack(): void {
    this.router.navigateByUrl(this.couponQuery.previousPagePath);
  }

  proceedFromVerifyNumber(event: { prefix: string; number: string; numberChanged: boolean }): void {
    this.mobilePrefix$.next(event.prefix);
    this.mobileNumber$.next(event.number);
    this.numberChanged = event.numberChanged;

    this.navigateForward();
  }

  postOTP(): void {
    this.updateWinNotification();
    if (this.numberChanged) {
      // Refresh user data
      this.accountService.getUserData(this.accountQuery.accessToken).subscribe();
    } else {
      // Just set phone and account statuses
      this.accountService.updatePhoneVerificationStatus('VER');
      this.accountService.updateUserStatus('ACT');
    }
  }

  navigateForward(): void {
    switch (this.receiptState$.value) {
      case ReceiptState.PreVerification:
        this.receiptState$.next(ReceiptState.InputNumber);
        break;
      case ReceiptState.InputNumber:
        this.receiptState$.next(ReceiptState.InputOTP);
        break;
      case ReceiptState.Confirmation:
      case ReceiptState.NumberVerified:
      default:
        this.router.navigate([this.couponQuery.couponReceiptForwardCTAURL]);
        break;
    }
  }

  retainSelections(): void {
    if (this.couponQuery.lastPlacedCouponCode) {
      // Clear coupon here instead of straight after coupon creation, as we need some coupon information in the betslip receipt
      // We can assume that if the user is here, the coupon was created successfully.
      this.couponService.clearCouponData();
      this.rebetCoupon = true;
      this.dataLayerService.createDataLayerEvent({
        event: 'Re-use Selections',
        couponCode: this.couponQuery.lastPlacedCouponCode,
      });
      this.couponService.rebetCoupon(this.couponQuery.lastPlacedCouponCode).pipe(first()).subscribe();
    }
  }

  performShareBet(): void {
    if (!this.couponQuery.lastPlacedCouponCode) {
      return;
    }

    if (!this.ngNavigatorShareService.canShare()) {
      const message = $localize`This service is not supported in your browser!`;
      this.notificationService.showErrorMessage(message);
      return;
    }

    const couponCode = this.couponQuery.lastPlacedCouponCode;
    const selection = this.couponQuery.lastPlacedCoupon.Odds.length;
    const stake = this.currencyFormatPipe.transform(this.couponQuery.lastPlacedCoupon.StakeGross);
    const potentialWin = this.currencyFormatPipe.transform(this.couponQuery.lastPlacedCoupon.MaxWinNet);

    this.dataLayerService.createDataLayerEvent({
      event: 'Share-bet-initiated',
      couponCode,
    });

    this.ngNavigatorShareService
      .share({
        title: $localize`Check out my BetKing bet! `,
        text: $localize`Check out my ${selection} selection bet! \nStake: ${stake} \nPotential Win: ${potentialWin}\n`,
        url: `/rebet/${couponCode}`,
      })
      .then(() => {
        this.dataLayerService.createDataLayerEvent({
          event: 'Share-bet-success',
          couponCode: this.couponQuery.lastPlacedCouponCode,
        });
      });
  }

  private updateWinNotification(): void {
    this.accountService.setWinNotification(true).subscribe(
      () => {
        // Success
        this.receiptState$.next(ReceiptState.NumberVerified);
      },
      () => {
        // Error
        this.otpInputGenericError$.next(true);
      }
    );
  }

  private prepareReceiptConfirmationAccumulatorBonusSubtitle(receiptConfirmationAccumulatorBonusSubtitle: string): string {
    if (
      receiptConfirmationAccumulatorBonusSubtitle.includes(this.ACCUMULATOR_BONUS_PERCENTAGE_KEY) &&
      this.accumulatorBonusQuery.hasAccumulatorBonus
    ) {
      const splitString = receiptConfirmationAccumulatorBonusSubtitle.split(this.ACCUMULATOR_BONUS_PERCENTAGE_KEY);
      return this.accumulatorBonusQuery.minAccumulatorBonus > 0 &&
        this.accumulatorBonusQuery.minAccumulatorBonus !== this.accumulatorBonusQuery.maxAccumulatorBonus
        ? `${splitString[0]}${this.accumulatorBonusQuery.minAccumulatorBonus}% - ${this.accumulatorBonusQuery.maxAccumulatorBonus}%${splitString[1]}`
        : `${splitString[0]}${this.accumulatorBonusQuery.maxAccumulatorBonus}%${splitString[1]}`;
    } else {
      return receiptConfirmationAccumulatorBonusSubtitle;
    }
  }

  private prepareReceiptConfirmationFreebetSubtitle(receiptConfirmationFreebetSubtitle: string): string {
    if (receiptConfirmationFreebetSubtitle.includes(this.FREEBET_STAKE_KEY)) {
      const stake = this.currencyFormatPipe.transform(this.couponQuery.lastPlacedCoupon.StakeGross);
      return receiptConfirmationFreebetSubtitle.replace(this.FREEBET_STAKE_KEY, stake);
    } else {
      return receiptConfirmationFreebetSubtitle;
    }
  }
}
