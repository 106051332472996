import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';

@Injectable()
export class HttpOAuth2Interceptor implements HttpInterceptor {
  constructor(private readonly accountQuery: AccountQuery, private readonly appConfig: AppConfigService) {}

  intercept(initialRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let accessToken: string;
    let sendActivitySource: boolean = false;
    let request: HttpRequest<any> = initialRequest.clone();

    const activitySource: string = this.appConfig.get('activitySource');
    const appActivitySource: string = this.appConfig.get('appActivitySource');
    const apiBaseUrl = this.appConfig.get('apiBaseUrl');

    if (request.headers.has('VND.noAuthToken')) {
      // no auth token should be added in this case
      request = request.clone({ headers: request.headers.delete('VND.noAuthToken') });
    } else if (request.headers.has('VND.forceAuthToken')) {
      accessToken = request.headers.get('VND.forceAuthToken');
      request = request.clone({ headers: request.headers.delete('VND.forceAuthToken') });
    } else if (this.accountQuery.isAuthenticated) {
      accessToken = this.accountQuery.accessToken;
    }

    if (request.headers.has('VND.sendActivitySource')) {
      sendActivitySource = true;
      request = request.clone({ headers: request.headers.delete('VND.sendActivitySource') });
    }

    if (accessToken) {
      // casino requests dont accept Bearer keyword in the Authorization header
      if (initialRequest.url.startsWith(apiBaseUrl.casino)) {
        request = request.clone({ setHeaders: { Authorization: `${accessToken}` } });
      } else request = request.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
    }

    // The ActivitySource header will be added if the sendActivitySource is set to true OR if the
    // Authorization header (accessToken) will be sent as part of the call
    if (Capacitor.isNativePlatform()) {
      if (appActivitySource && (sendActivitySource || accessToken)) {
        request = request.clone({ setHeaders: { ActivitySource: appActivitySource } });
      }
    } else {
      if (activitySource && (sendActivitySource || accessToken)) {
        request = request.clone({ setHeaders: { ActivitySource: activitySource } });
      }
    }

    return next.handle(request);
  }
}
