import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { format } from 'date-fns';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, finalize, first, map, takeUntil, tap } from 'rxjs/operators';
import { LoginService } from 'src/app/core/services/account/login.service';
import { MobileNumberInputService } from 'src/app/core/services/account/mobile-number-input.service';
import { APIService } from 'src/app/core/services/api.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { RegistrationQuery } from 'src/app/core/state/registration/registration.query';
import { RegistrationStore } from 'src/app/core/state/registration/registration.store';
import { LoginResponseModel } from 'src/app/shared/models/account.model';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { CityModel, CountryModel, IdTypeModel, StateModel } from 'src/app/shared/models/location.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import {
  CurrencyModel,
  F2PRegistrationModel,
  F2PRegistrationModelGhana,
  F2PRegistrationModelKenya,
  KYCRegistrationModel,
  QuickRegistrationModel,
  RegistrationModel,
  RegistrationSuccessContentModel,
  SplitRegistrationContentModel,
  UpsellRegistrationSuccessContentModel,
} from 'src/app/shared/models/registration.model';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  private readonly endpoints = {
    ACTIVATION_OPTIONS: 'api/Security/Registration/RegistrationActivationOptions/Current',
    CURRENCIES: 'api/Security/Registration/RegistrationCurrencies',
    COUNTRIES: 'api/Security/Registration/RegistrationCountries',
    STATES: 'api/Location/Countries/{0}/States',
    CITIES: 'api/Location/States/{0}_{1}/Cities',
    CHECK_USERNAME: 'api/Security/Registration/ValidateUsername?username={0}',
    CHECK_EMAIL: 'api/Security/Registration/ValidateEmailAddress?emailAddress={0}',
    CHECK_PASSWORD: 'api/Security/Registration/ValidatePassword',
    REGISTER_USER: 'api/Security/Registration/RegisterNewUser',
    REGISTER_F2P_USER: 'api/User/Profile/Agents/RegisterF2PAccountIntoBetking',
    REGISTER_BY_EMAIL: 'api/Security/Registration/RegistrationByEmail',
    REGISTER_BY_SMS: 'api/Security/Registration/RegistrationBySMS',
    NEW_AGENT_FORM: 'NewAgent/NewAgentForm',
    ID_TYPES: 'api/Registration/IDDocument/Types',
    CHECK_TYPE: 'api/Registration/IDDocument/Validate',
    VALIDATE_PROMO_CODE: 'api/user-registration/promo-code/validate',
    FIND_SUPER_PICK_USER: 'api/F2P/User/Exists',
    CHECK_SUPER_PICK_PASSWORD: 'oauth/token',
    USER_DETAILS: 'API/Betking/GetF2PUserBasicDetails',
    REGISTER_SUPER_PICK_USER: 'API/Betking/RegisterF2PAccountIntoBetking',
  };

  private readonly loadingQueue: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly apiService: APIService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly dataLayerService: DataLayerService,

    private readonly languageService: LanguageService,
    private readonly loginService: LoginService,
    private readonly mobileNumberInputService: MobileNumberInputService,
    private readonly notificationService: NotificationService,
    private readonly registrationQuery: RegistrationQuery,
    private readonly registrationStore: RegistrationStore,
    private readonly router: Router
  ) {}

  initService(): void {
    this.destroy$ = new Subject<boolean>();
    this.loadingQueue
      .pipe(
        map(m => {
          this.registrationStore.setLoading(m > 0);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  initSplitRegistration(): void {
    if (!this.registrationQuery.hasSplitRegistrationContent) {
      this.queueLoading();
      this.apiService
        .get(APIType.CMS, `Registration/SplitRegistration?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
        .pipe(
          tap((response: SplitRegistrationContentModel) => {
            this.registrationStore.updateSplitRegistrationContent(response);
          }),
          finalize(() => {
            this.dequeueLoading();
          })
        )
        .subscribe();
    }
  }

  isSuperPickUserService(phoneNumber: string): Observable<boolean> {
    this.queueLoading();
    const apiSettings = new APISettings({ sendActivitySource: true });
    return this.apiService
      .post(APIType.SuperPicksValidationDomain, this.endpoints.FIND_SUPER_PICK_USER, { Username: phoneNumber }, apiSettings)
      .pipe(
        first(),
        map(responseData => {
          this.dequeueLoading();
          this.registrationStore.updateIsSuperPickUser(!!responseData?.ExtraProperties?.IsActive);
          return !!responseData?.ExtraProperties?.IsActive;
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.updateIsSuperPickUser(false);
          return throwError(err.message);
        })
      );
  }

  initRegistrationSuccess(): void {
    if (!this.registrationQuery.hasRegistrationSuccessContent) {
      this.apiService
        .get(APIType.CMS, `Registration/RegistrationSuccess?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
        .pipe(
          tap((response: RegistrationSuccessContentModel) => {
            this.registrationStore.updateRegistrationSuccessContent(response);
          })
        )
        .subscribe();
    }
  }

  initUpsellRegistrationSuccess(): void {
    if (!this.registrationQuery.hasRegistrationSuccessContent) {
      this.apiService
        .get(APIType.CMS, `Registration/UpsellRegistrationSuccess?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`)
        .pipe(
          tap((response: UpsellRegistrationSuccessContentModel) => {
            this.registrationStore.updateUpsellRegistrationSuccessContent(response);
          })
        )
        .subscribe();
    }
  }

  clearOnDestroy(): void {
    this.registrationStore.updateVerificationInProgress(false);
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getActivationOption(): Observable<void> {
    if (!this.registrationQuery.hasActivationOption) {
      const apiSettings = new APISettings({ sendActivitySource: true });

      this.queueLoading();
      return this.apiService.get(APIType.Platform, this.endpoints.ACTIVATION_OPTIONS, apiSettings).pipe(
        first(),
        map(responseData => {
          this.registrationStore.updateActivationOption(responseData.Result);
          this.registrationStore.updateRegisterOptionTriggered(true);
          this.dequeueLoading();
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      );
    }
  }

  updateRegisterOptionTriggered(registerOptionTriggered: boolean): void {
    this.registrationStore.updateRegisterOptionTriggered(registerOptionTriggered);
  }

  getCurrencies(): void {
    if (!this.registrationQuery.hasCurrencies) {
      const apiSettings = new APISettings({ sendActivitySource: true });

      this.queueLoading();
      this.apiService
        .get(APIType.Platform, this.endpoints.CURRENCIES, apiSettings)
        .pipe(
          first(),
          map(responseData => {
            this.registrationStore.updateCurrencies(this.mapCurrencyDataToModel(responseData));
            this.dequeueLoading();
          }),
          catchError((err: HttpErrorResponse) => {
            this.dequeueLoading();
            this.registrationStore.setError(err);
            return throwError(err.message);
          })
        )
        .subscribe();
    }
  }

  getCountries(): void {
    if (!this.registrationQuery.hasCountries) {
      const apiSettings = new APISettings({ sendActivitySource: true });

      this.queueLoading();
      this.apiService
        .get(APIType.Platform, this.endpoints.COUNTRIES, apiSettings)
        .pipe(
          first(),
          map(responseData => {
            this.registrationStore.updateCountries(this.mapCountryDataToModel(responseData));
            this.dequeueLoading();
          }),
          catchError((err: HttpErrorResponse) => {
            this.dequeueLoading();
            this.registrationStore.setError(err);
            return throwError(err.message);
          })
        )
        .subscribe();
    }
  }

  getIdTypes(): void {
    this.queueLoading();
    this.apiService
      .get(APIType.Website, this.endpoints.ID_TYPES)
      .pipe(
        first(),
        map(responseData => {
          this.registrationStore.updateIdTypes(this.mapIdTypesDataToModel(responseData));
          this.dequeueLoading();
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      )
      .subscribe();
  }

  getStates(country: string): void {
    const apiSettings = new APISettings({ sendActivitySource: true });

    this.queueLoading();
    this.apiService
      .get(APIType.Platform, this.endpoints.STATES.replace('{0}', country), apiSettings)
      .pipe(
        first(),
        map(responseData => {
          this.registrationStore.updateStates(this.mapStateDataToModel(responseData));
          this.dequeueLoading();
        }),
        catchError((err: HttpErrorResponse) => {
          this.dequeueLoading();
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      )
      .subscribe();
  }

  getCities(country: string, state: string): Observable<void> {
    const apiSettings = new APISettings({ sendActivitySource: true });

    this.queueLoading();
    return this.apiService.get(APIType.Platform, this.endpoints.CITIES.replace('{0}', country).replace('{1}', state), apiSettings).pipe(
      first(),
      map(responseData => {
        this.registrationStore.updateCities(this.mapCityDataToModel(responseData));
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.registrationStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  checkUserName(username: string): Observable<boolean> {
    return this.performCheckCall(this.endpoints.CHECK_USERNAME.replace('{0}', username));
  }

  checkEmail(email: string): Observable<boolean> {
    return this.performCheckCall(this.endpoints.CHECK_EMAIL.replace('{0}', email));
  }

  checkPassword(password: string): Observable<boolean> {
    const callBody = {
      Password: password,
    };

    this.queueLoading();
    return this.performCheckCallPost(this.endpoints.CHECK_PASSWORD, callBody).pipe(
      tap(() => {
        this.dequeueLoading();
      })
    );
  }
  checkSuperPickPasswordPassword(callbody: any): Observable<boolean> {
    const callBody = {
      ...callbody,
    };
    let formBody: string[] | string = [];
    for (const property in callBody) {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(callBody[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
    }
    formBody = formBody.join('&');

    this.queueLoading();
    return this.performSuperPickCheckCallPost(this.endpoints.CHECK_SUPER_PICK_PASSWORD, formBody).pipe(
      tap(respo => {
        this.dequeueLoading();
        return true;
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  checkPasswordWithoutLoader(password: string): Observable<boolean> {
    const callBody = {
      Password: password,
    };
    return this.performCheckCallPost(this.endpoints.CHECK_PASSWORD, callBody);
  }

  checkPhoneNumber(mobileExtension: string, mobileNumber: string): Observable<boolean> {
    this.queueLoading();

    return this.mobileNumberInputService.checkPhoneNumber(mobileExtension, mobileNumber).pipe(
      finalize(() => {
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  checkPhoneNumberWithoutLoader(mobileExtension: string, mobileNumber: string): Observable<boolean> {
    return this.mobileNumberInputService.checkPhoneNumber(mobileExtension, mobileNumber).pipe(
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  checkIdentification(value: string, code: string): Observable<any> {
    const callBody = {
      IDDocumentValue: value,
      IDDocumentTypeCode: code,
    };

    this.queueLoading();
    return this.apiService.post(APIType.Website, this.endpoints.CHECK_TYPE, callBody).pipe(
      first(),
      map(responseData => {
        this.dequeueLoading();
        return responseData;
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.registrationStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  validatePromoCode(code: string): Observable<any> {
    const callBody = {
      Code: code,
    };

    this.queueLoading();
    return this.apiService.post(APIType.Website, this.endpoints.VALIDATE_PROMO_CODE, callBody).pipe(
      first(),
      map(responseData => {
        this.dequeueLoading();
        return responseData;
      }),
      catchError((err: HttpErrorResponse) => {
        this.dequeueLoading();
        this.registrationStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  registerUser(user: RegistrationModel | QuickRegistrationModel | KYCRegistrationModel): Observable<void> {
    this.registrationStore.setError(undefined);

    let callBody = undefined;
    if (user instanceof RegistrationModel) {
      callBody = this.mapRegistrationModelToRequestBody(user);
    } else if (user instanceof QuickRegistrationModel) {
      callBody = this.mapQuickRegistrationModelToRequestBody(user);
    } else if (user instanceof KYCRegistrationModel) {
      callBody = this.mapKYCRegistrationModelToRequestBody(user);
    }

    this.queueLoading();
    return this.apiService.post(APIType.Platform, this.endpoints.REGISTER_USER, callBody).pipe(
      first(),
      tap(() => {
        this.dequeueLoading();
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        this.dequeueLoading();
        return throwError(err.message);
      })
    );
  }

  registerF2PUser(user: F2PRegistrationModel): Observable<void> {
    this.registrationStore.setError(undefined);
    const apiSettings = new APISettings({ sendActivitySource: true });

    this.queueLoading();
    return this.apiService
      .post(APIType.Website, this.endpoints.REGISTER_F2P_USER, this.mapF2PRegistrationModelToRequestBody(user), apiSettings)
      .pipe(
        first(),
        finalize(() => {
          this.dequeueLoading();
        }),
        tap(response => {
          if (!response.IsSuccessfull) {
            this.registrationStore.setError(response.ResponseCode);
          }
        }),
        catchError((err: HttpErrorResponse) => {
          this.registrationStore.setError(err);
          return throwError(err.message);
        })
      );
  }

  registerSuperPickUser(user: F2PRegistrationModelGhana | F2PRegistrationModelKenya): Observable<void> {
    this.registrationStore.setError(undefined);
    const apiSettings = new APISettings({ sendActivitySource: true });

    this.queueLoading();
    return this.apiService.post(APIType.Website, this.endpoints.REGISTER_SUPER_PICK_USER, user, apiSettings).pipe(
      first(),
      finalize(() => {
        this.dequeueLoading();
      }),
      tap(response => {
        if (!response.IsSuccessfull) {
          this.registrationStore.setError(response.ResponseCode);
        }
        return response;
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err.message);
      })
    );
  }

  registerByEmail(userName: string): Observable<boolean> {
    const body: any = {
      Username: userName,
    };
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.post(APIType.Platform, this.endpoints.REGISTER_BY_EMAIL, body, apiSettings);
  }

  registerBySms(userName: string): Observable<boolean> {
    const body: any = {
      Username: userName,
    };
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.post(APIType.Platform, this.endpoints.REGISTER_BY_SMS, body, apiSettings);
  }

  updateCredentials(credentialsData: any): void {
    this.registrationStore.updateCredentials(credentialsData);
  }

  clearCredentials(): void {
    this.registrationStore.clearCredentials();
  }

  getNewAgentForm(): void {
    if (!this.registrationQuery.hasNewAgentData) {
      this.apiService
        .get(APIType.CMS, this.endpoints.NEW_AGENT_FORM)
        .pipe(
          first(),
          map(data => {
            this.registrationStore.updateNewAgentForm(data);
            this.dequeueLoading();
          }),
          catchError((err: HttpErrorResponse) => {
            this.registrationStore.setError(err);
            this.dequeueLoading();
            return throwError(err.message);
          })
        )
        .subscribe();
    }
  }

  onRegisterSuccess(username: string, password: string, userId: string, createDataLayerEvents: boolean = true): void {
    if (
      this.applicationQuery.enableOTPBySMS &&
      this.registrationQuery.activationOption === 'BySms' &&
      !this.applicationQuery.skipRegistrationOTP
    ) {
      this.registerBySms(username)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            // success
            this.updateCredentials({
              user: username,
              pass: password,
            });

            this.registrationStore.updateVerificationInProgress(true);
            if (createDataLayerEvents) {
              this.dataLayerService.createDataLayerEvent({
                event: 'registration-step-2',
                stepName: 'OTP Validation',
              });
            }
          },
          () => {
            // error
            this.notificationService.showNotification(
              new NotificationSettings({
                allowBackdropClose: true,
                contentText: $localize`Registration successful! Could not verify Phone number. Please verify phone number when login.`,
                type: 'success',
              })
            );

            if (createDataLayerEvents) {
              // Although OTP was not done, user was still registered
              this.dataLayerService.createDataLayerEvent({
                event: 'registration-step-3',
                userID: userId,
              });
            }

            this.router.navigateByUrl('/');
          }
        );
    } else {
      this.registeredSuccess(username, password, userId, createDataLayerEvents).subscribe();
    }
  }

  onF2PRegisterSuccess(
    username: string,
    password: string,
    userId: string,
    createDataLayerEvents: boolean = true
  ): Observable<LoginResponseModel> {
    return this.registeredSuccess(username, password, userId, createDataLayerEvents);
  }

  onUserJourneyRegisterSuccess(
    username: string,
    password: string,
    userId: string,
    createDataLayerEvents: boolean = true
  ): Observable<LoginResponseModel> {
    return this.registeredSuccess(username, password, userId, createDataLayerEvents);
  }

  getF2PUserProfile(accessToken: string): Observable<any> {
    if (!accessToken) {
      return undefined;
    }
    this.queueLoading();
    return this.apiService
      .post<any>(
        APIType.Website,
        this.endpoints.USER_DETAILS,
        {
          token: accessToken,
        },
        new APISettings({
          forceAuthToken: accessToken,
          contentType: 'application/json',
        })
      )
      .pipe(
        first(),
        tap(value => {
          this.dequeueLoading();
          return value;
        }),
        catchError(err => {
          this.dequeueLoading();
          return throwError(err);
        })
      );
  }
  updateIsF2PUpsellRegistration(isF2PUpsellRegistration: boolean): void {
    this.registrationStore.updateIsF2PUpsellRegistration(isF2PUpsellRegistration);
  }

  private registeredSuccess(
    username: string,
    password: string,
    userId: string,
    createDataLayerEvents: boolean = true
  ): Observable<LoginResponseModel> {
    if (createDataLayerEvents && !this.applicationQuery.isBrand(['Nigeria'])) {
      this.dataLayerService.createDataLayerEvent({
        event: 'registration-step-3',
        userID: userId,
      });
    }
    return this.loginService.login(username, password, true).pipe(
      tap(response => {
        if (response.success) {
          this.router.navigate(['register-success']);
        }
      })
    );
  }

  private mapRegistrationModelToRequestBody(user: RegistrationModel): object {
    if (this.applicationQuery.enableOTPBySMS && this.registrationQuery.activationOption === 'BySms') {
      return {
        FirstName: user.firstName,
        LastName: user.lastName,
        DateOfBirth: format(new Date(user.dateOfBirth), 'yyyy-MM-dd'),
        Addresses: [
          {
            CountryCode: user.country,
            Region: user.state,
            AddressTypeCode: 'MAIN',
          },
        ],
        Email: user.email,
        PhoneContacts: [
          {
            PhoneContactTypeCode: 'MB',
            Prefix: user.mobilePrefix.replace('+', ''),
            Number: user.mobileNumber,
          },
        ],
        Username: user.username,
        Password: user.password,
        CurrencyISOCode: user.currency,
        BannerTag: user.bannerTag,
        ExtraRegistrationValues: {
          PromotionalCode: user.extraDetails.promotionCode,
          ReceiveMarketingMessages: user.extraDetails.receiveMarketingMessages,
        },
        CommunicationPrefferedLanguageISOCode: 'eng',
        PersonalQuestionCode: 'QST1',
        PersonalQuestionAnswer: 'Not Provided',
      };
    } else {
      return {
        FirstName: user.firstName,
        LastName: user.lastName,
        GenderCode: user.gender,
        DateOfBirth: format(new Date(user.dateOfBirth), 'yyyy-MM-dd'),
        Addresses: [
          {
            CountryCode: user.country,
            Region: user.state,
            Province: '',
            City: user.city,
            Line1: user.address,
            PostalCode: 'N/A',
            AddressTypeCode: 'MAIN',
          },
        ],
        Email: user.email,
        PhoneContacts: [
          {
            PhoneContactTypeCode: 'MB',
            Prefix: user.mobilePrefix,
            Number: user.mobileNumber,
          },
        ],
        Username: user.username,
        Password: user.password,
        CurrencyISOCode: user.currency,
        BannerTag: user.bannerTag,
        ExtraRegistrationValues: {
          PromotionalCode: user.extraDetails.promotionCode,
          ReceiveMarketingMessages: user.extraDetails.receiveMarketingMessages,
        },
        TaxCode: 'N/A',
        SiteDiscoveryMethodCode: '',
        CommunicationPrefferedLanguageISOCode: 'eng',
        PersonalQuestionCode: 'QST1',
        PersonalQuestionAnswer: 'Not Provided',
      };
    }
  }

  private mapQuickRegistrationModelToRequestBody(user: QuickRegistrationModel): object {
    return {
      Addresses: [
        {
          CountryCode: user.country,
          AddressTypeCode: 'MAIN',
        },
      ],
      PhoneContacts: [
        {
          PhoneContactTypeCode: 'MB',
          Prefix: user.mobilePrefix.replace('+', ''),
          Number: user.mobileNumber,
        },
      ],
      Password: user.password,
      CurrencyISOCode: user.currency,
      BannerTag: user.bannerTag,
      ExtraRegistrationValues: {
        PromotionalCode: user.extraDetails.promotionCode,
        ReceiveMarketingMessages: user.extraDetails.receiveMarketingMessages,
      },
    };
  }

  mapKYCRegistrationModelToRequestBody(user: KYCRegistrationModel): object {
    return {
      Addresses: [
        {
          CountryCode: user.country,
          Region: '',
          AddressTypeCode: 'MAIN',
          Line1: user.address,
        },
      ],
      PhoneContacts: [
        {
          PhoneContactTypeCode: 'MB',
          Prefix: user.mobilePrefix,
          Number: user.mobileNumber,
        },
      ],
      Password: user.password,
      CurrencyISOCode: user.currency,
      ExtraRegistrationValues: {
        PromotionalCode: user.extraDetails.promotionCode,
        ReceiveMarketingMessages: user.extraDetails.receiveMarketingMessages,
      },
      IDDocumentValue: user.idDocumentValue,
      IDDocumentTypeCode: user.idDocumentTypeCode,
      FirstName: user.firstName,
      LastName: user.lastName,
      BannerTag: user.bannerTag,
    };
  }

  private mapF2PRegistrationModelToRequestBody(user: F2PRegistrationModel): object {
    return {
      token: this.accountQuery.f2pUserData.accessToken,
      firstName: user.firstName,
      lastName: user.lastName,
      state: user.state,
      receiveMarketingMessages: user.extraDetails.receiveMarketingMessages,
    };
  }

  private performCheckCall(api: string): Observable<boolean> {
    const apiSettings = new APISettings({ sendActivitySource: true });

    return this.apiService.get(APIType.Platform, api, apiSettings).pipe(
      first(),
      map(responseData => responseData.Result === null),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  private performCheckCallPost(api: string, callBody: any): Observable<boolean> {
    // const apiSettings = new APISettings({ sendActivitySource: true });
    return this.apiService.get(APIType.Platform, `${api}?password=${callBody.Password}`).pipe(
      first(),
      map(responseData => responseData.Result === null),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  private performSuperPickCheckCallPost(api: string, callBody: string[] | string): Observable<boolean> {
    const apiSettings = new APISettings({ sendActivitySource: true, contentType: 'application/x-www-form-urlencoded' });
    return this.apiService.post(APIType.F2PGuardian, api, callBody, apiSettings).pipe(
      first(),
      map(responseData => {
        this.registrationStore.updateIsSuperToken(responseData.access_token);
        return responseData.Result === null;
      }),
      catchError((err: HttpErrorResponse) => {
        this.registrationStore.setError(err);
        return throwError(err);
      })
    );
  }

  private mapCurrencyDataToModel(responseData: any): CurrencyModel[] {
    const currencies: CurrencyModel[] = [];
    responseData.Result.forEach(currency => {
      currencies.push(
        new CurrencyModel({
          isoCode: currency.IsoCode,
          name: currency.Name,
          symbol: currency.Symbol,
        })
      );
    });

    return currencies;
  }

  private mapCountryDataToModel(responseData: any): CountryModel[] {
    const countries: CountryModel[] = [];
    responseData.Result.forEach(country => {
      countries.push(
        new CountryModel({
          threeLetterCode: country.IsoCode,
          name: country.Name,
        })
      );
    });

    return countries;
  }

  private mapStateDataToModel(responseData: any): StateModel[] {
    const states: StateModel[] = [];
    responseData.Result.forEach(state => {
      states.push(
        new StateModel({
          code: state.Code,
          name: state.Name,
        })
      );
    });

    return states;
  }

  private mapCityDataToModel(responseData: any): CityModel[] {
    const cities: CityModel[] = [];
    responseData.Result.forEach(city => {
      cities.push(
        new CityModel({
          id: city.Id,
          name: city.Name,
        })
      );
    });

    return cities;
  }

  private mapIdTypesDataToModel(responseData: any): IdTypeModel[] {
    const idTypes: IdTypeModel[] = [];
    responseData.IDDocumentList.forEach(type => {
      idTypes.push(
        new IdTypeModel({
          id: type.Id,
          name: type.Name,
          code: type.Code,
        })
      );
    });

    return idTypes;
  }

  private queueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value + 1);
  }

  private dequeueLoading(): void {
    this.loadingQueue.next(this.loadingQueue.value - 1);
  }
}
