import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { BannerStore } from 'src/app/core/state/banner/banner.store';
import { BannerModel, BannerVisibility, BannerState } from 'src/app/shared/models/banner.model';

@Injectable({ providedIn: 'root' })
export class BannerQuery extends Query<BannerState> {
  loading$ = this.selectLoading();
  error$ = this.selectError();
  bannerDetails$ = this.select(state => (state.bannerDetails ? state.bannerDetails.filter(banner => this.authCheck(banner)) : []));

  constructor(protected store: BannerStore, private readonly accountQuery: AccountQuery) {
    super(store);
  }

  private authCheck(banner: BannerModel): boolean {
    return (
      banner.display === BannerVisibility.Always ||
      (banner.display === BannerVisibility.LoggedIn && this.accountQuery.isAuthenticated) ||
      (banner.display === BannerVisibility.LoggedOut && !this.accountQuery.isAuthenticated)
    );
  }
}
