import { BreadcrumbNavigation } from './breadcrumb-navigation';

export class VirtualsMenuGame {
  code: string;
  title: string;
  thumbnailUrl: string;
  isNew: boolean;
  showLoginDialog: boolean;
  showDemoCTA: boolean;
  isOnDemandGame: boolean;

  constructor(init: VirtualsMenuGame) {
    Object.assign(this, init);
  }
}

export class VirtualsMenuSection {
  name: string;
  icon: string;
  games: VirtualsMenuGame[] = [];

  constructor(init: Partial<VirtualsMenuSection>) {
    Object.assign(this, init);
  }
}

export class VirtualsGoldenRaceGame {
  id: number;
  code: string;
  title: string;

  constructor(init: VirtualsGoldenRaceGame) {
    Object.assign(this, init);
  }
}

export class VirtualsLatestJackpotWinnersInfo {
  infoTitle: string;
  infoDescription: string;
  currentJackpotsSectionTitle: string;

  constructor(init: VirtualsLatestJackpotWinnersInfo) {
    Object.assign(this, init);
  }
}

export interface VirtualsState {
  lobbyData: VirtualsLobby;
  groupEntityId: number;
  instantLeagueMap: VirtualsInstantLeagueMap;
  instantUserData: any; // TODO: Define a proper Interface based on the data we store
  categoryEventsTimings: CategoryEventsTimings;
  jackpots: VirtualsJackpot[];
  breadcrumbNavigation: BreadcrumbNavigation;
  ui: VirtualsUIState;
}

export interface VirtualsInstantLeagueMap {
  playlists: VirtualsInstantLeaguePlaylistMap[];
  areas: VirtualsInstantLeagueAreaMap[];
}

export interface VirtualsInstantLeaguePlaylistMap {
  playlistID: string;
  playlistName: string;
}

export interface VirtualsInstantLeagueAreaMap {
  areaID: string;
  areaName: string;
  markets: VirtualsInstantLeagueMarketMap[];
}

export interface VirtualsInstantLeagueMarketMap {
  marketID: string;
  marketName: string;
  marketDescription: string;
  selections: VirtualsInstantLeagueSelectionMap[];
}

export interface VirtualsInstantLeagueSelectionMap {
  selectionID: string;
  selectionUniqueId: string;
  selectionName: string;
  selectionOrder: string;
}

export interface CurrencySetting {
  userLimits: UserLimitsResponse;
  userBonusList: BonusList[];
}

export interface BonusList {
  NumberOfEvents: number;
  Percentage: number;
}

export interface UserLimitsResponse {
  maxPayout: number;
  maxStake?: number;
  minStake?: number;
  minGroupingsBetStake?: number;
  maxGroupingsBetStake?: number;
}

export interface InstantUser {
  id: string;
  unitId: number;
  calculationId: number;
  walletId: number;
  tagsId: number;
  userLimits: UserLimitsResponse;
  userBonusList: BonusList[];
  name: string;
  extId: string;
  extData: string;
  status: string;
  displays: UserDisplaysResponse[];
}

export interface UserDisplaysResponse {
  classType: string;
  displayId: string;
  enabled: boolean;
  monitor: number;
  content: {
    classType: string;
    playlistId: number;
  };
}

export class UserLoginResponse {
  auth: {
    unit: {
      id: number;
      name: string;
      extId: string;
      status: string;
    };
  };
  clientId: string;
  calculationId: number;
  sessionStatus: {
    wallets: Wallet[];
  };
  tagsId: number;
  calculationContext: {
    ticketContext: {
      currencySetting: CurrencySettings[];
    };
  };

  constructor(init: UserLoginResponse) {
    Object.assign(this, init);
  }
}

export interface Wallet {
  balance: number;
  walletId: number;
}

export interface CurrencySettings {
  key: string;
  limits: [];
}

export interface OnlineHashRequestBody {
  userId: number;
  bearerToken: string;
}

export interface InstantCategory {
  url: string;
  theme: {
    themeColorPrimary: string;
    themeColorSecondary: string;
    textColor: string;
  };
  name: string;
  displayOrder: number;
}

export interface VirtualsLobby {
  pageTitle: string;
  sections: VirtualsLobbySection[];
  infoBubbleTitle: string;
  infoBubbleContent: string;
}

export interface VirtualsLobbySection {
  title: string;
  subTitle: string;
  isEnabled: boolean;
  isNew: boolean;
  jackpot: 'kingmaker' | 'duke'; // Values need to be kept in sync with any changes on the CMS
  jackpotLabel: string;
  jackpotDropFrequencyText: string;
  jackpotClickUrl: string;
  subSections: VirtualsLobbySubSection[];
  ctaLabel: string;
  ctaButtonText: string;
  ctaClickUrl: string;
}

export interface VirtualsLobbySubSection {
  title: string;
  gamesPerRow: number;
  games: VirtualsLobbyGame[];
}

export interface VirtualsLobbyGame {
  code: string;
  gameType: 'scheduled-league' | 'instant-league' | 'other'; // Values need to be kept in sync with any changes on the CMS
  providerId: string;
  title: string;
  logoUrl: string;
  isEnabled: boolean;
  isNew: boolean;
  showPlayButton: boolean;
  showCountdown: boolean;
  showLoginDialog: boolean;
}

export interface CategoryEventsTimings {
  [key: string | number]: CategoryEventsTiming;
}

export interface CategoryEventsTiming {
  nextEventDate: string;
  eventInterval: number;
}

export interface VirtualsJackpot {
  id: number;
  name: string;
  value: number;
  dropFrequency: number;
}

export interface VirtualsUIState {
  slideUps: VirtualsSlideUpState;
}

export interface VirtualsSlideUpState {
  [slideUpType: string]: boolean;
}

export enum VirtualsSlideUpType {
  Coupon = 'coupon',
  MyBets = 'myBets',
}
