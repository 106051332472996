import { Component, Input } from '@angular/core';
import { MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss'],
})
export class EventDetailsComponent {
  @Input() event: MatchModel;
  @Input() showDate = true;

  teamNameCanBeSplit(name: string): boolean {
    return /\s/g.test(name);
  }
}
