<div class="markets" [class.show-edit]="showEdit$ | async" [class.edit-mode]="(showOddsTable$ | async) === false"
  [class.one-market]="matchOddsLength === 1"
  [class.one-selection]="selectionCount(marketSelections.marketId, marketSelections.odds)">
  <mvs-loader class="loader" *ngIf="isLoading$ | async"></mvs-loader>
  <div class="details-container">
    <div class="market-details">
      <div class="edit-odd" [class.no-odds]="noOddsForEdit$ | async" data-testid="coupon-selections-edit-odd">
        <i class="fa fa-pencil" (click)="handleEditClick()" data-testid="coupon-selections-edit-odd-btn"></i>
      </div>
      <div class="market-name" data-testid="coupon-selections-market-name">
        <span>{{ marketSelections.marketName }}:</span>
      </div>
    </div>
    <div class="selection-details-container" [@preventInitialChildAnimations]>
      <div class="selection-details" *ngIf="showOddsTable$ | async" [@slideInOut]
        (@slideInOut.done)="handleSlideDone()">
        <ng-container *ngFor="let odd of marketSelections.odds; trackBy: oddTrackBy">
          <div class="selection-details-content">
            <div class="odd-details-row">
              <div class="odd-details">
                <div class="market-selection" data-testid="coupon-selections-selection-name">
                  <span>{{ parseSelectionName(odd.selectionName, matchName) }}</span>
                </div>
                <div class="odd-value" data-testid="coupon-selections-odd-value">{{ odd.value | number:'1.2-2' }}</div>
              </div>
              <span class="close-odd material-icons" (click)="removeOdd(odd.id)"
                data-testid="coupon-selections-remove-odd">
                {{'delete'}}
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="showEdit$ | async" class="edit" [class.single-line]="isSingleLine$ | async" [@fadeIn] data-testid="coupon-selections-edit">
      <mvs-odds-layout [isOpen]="true" [layout]="layout$ | async" [odds]="oddsForEdit$ | async" [withHeader]="true">
      </mvs-odds-layout>
    </div>
  </div>
</div>
