import { Injectable, OnDestroy } from '@angular/core';
import {
  BetCoupon,
  BetCouponGlobalVariable,
  BetCouponGroup,
  BetCouponOdd,
  Bonus,
  ClientsideCouponService,
  CouponAction,
  Dictionary,
  Selection,
  CouponType,
  UpdateCouponRequest,
  UpdateCouponResponse,
} from 'clientside-coupon';
import { format } from 'date-fns';
import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, first, map, takeUntil, tap } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { CouponStakeHandlerService } from 'src/app/core/services/coupon/coupon-stake-handler.service';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { EvaluationService } from 'src/app/core/services/evaluation.service';
import { LoadingService } from 'src/app/core/services/loading.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { AccumulatorBonusQuery } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.query';
import { AccumulatorBonusStore } from 'src/app/core/state/accumulator-bonus/accumulator-bonus.store';
import { CouponQuery } from 'src/app/core/state/coupon/coupon.query';
import { CouponStore } from 'src/app/core/state/coupon/coupon.store';
import { LiveQuery } from 'src/app/core/state/live/live.query';
import { LiveStore } from 'src/app/core/state/live/live.store';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { SportsbookFreeBetService } from 'src/app/modules/freebets/services/sportsbook-free-bet.service';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import {
  BookedCoupon,
  CouponGroupingType,
  CouponOddsModel,
  CouponSettings,
  CouponUIState,
  ExpiredEvents,
  ExpiredEventsModel,
  OddChanges,
  OddModel,
} from 'src/app/shared/models/coupon.model';
import { MarketTypeIdsModel } from 'src/app/shared/models/sport.model';
import { INSERT_COUPON_STATUS_CODES, UPDATE_COUPON_STATUS_CODES } from 'src/app/shared/utils/coupon-status-codes';
import { FreeBetProductType } from 'src/app/modules/freebets/models/freebets.model';
import { BetslipActions, DataLayerProduct } from 'src/app/shared/models/datalayer.model';

const EventBasedProduct = {
  l: DataLayerProduct.SportsBookLive, // Live
  f: DataLayerProduct.SportsBookPrematch, // Prematch
  m: DataLayerProduct.SportsBookMixed, // Both live & prematch
};

const BetslipProduct = (acc: string, curr) => {
  const previous = acc.trim().toLowerCase();
  const current = (curr.EventCategory || '').trim().toLowerCase();
  return previous === current ? previous : 'm';
};

const BetslipSports = (acc: string, curr) => {
  const previous = acc.trim().toLowerCase();
  const current = (curr.SportName || '').trim().toLowerCase();
  return previous === current ? previous : 'mixed';
};
const BetslipTournaments = (acc: string, curr) => {
  const previous = acc.trim().toLowerCase();
  const current = (curr.TournamentName || '').trim().toLowerCase();
  return previous === current ? previous : 'mixed';
};
@Injectable({
  providedIn: 'root',
})
export class CouponService implements OnDestroy {
  loading: boolean = false;
  enforceSingleCombination: boolean = false;
  allowCompleteDeselectOfEventOdds: boolean = true;

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly accountQuery: AccountQuery,
    private readonly accountService: AccountService,
    private readonly accumulatorBonusQuery: AccumulatorBonusQuery,
    private readonly accumulatorBonusStore: AccumulatorBonusStore,
    private readonly apiService: APIService,
    private readonly appConfig: AppConfigService,
    private readonly applicationService: ApplicationService,
    private readonly clientsideCouponService: ClientsideCouponService,
    private readonly couponQuery: CouponQuery,
    private readonly couponStakeHandlerService: CouponStakeHandlerService,
    private readonly couponStore: CouponStore,
    private readonly dataLayerService: DataLayerService,
    private readonly evaluationService: EvaluationService,
    private readonly freebetService: SportsbookFreeBetService,
    private readonly liveQuery: LiveQuery,
    private readonly liveStore: LiveStore,
    private readonly loadingService: LoadingService,
    private readonly myBetsService: MyBetsService,
    private readonly notificationService: NotificationService,
    private readonly sportStore: SportStore
  ) {
    this.couponQuery.couponSettings$.subscribe(sub => {
      this.couponStore.updateCouponSettings(sub);
    });
  }

  get currencySymbol(): string {
    return this.accountQuery.userData && this.accountQuery.userData.currency.symbol
      ? this.accountQuery.userData.currency.symbol
      : this.appConfig.get('sports').coupon.defaultCurrency;
  }

  initialize(): void {
    if (this.couponQuery.couponInitialized) {
      // Get bonus list, because we are storing bonus list for sports/virtuals/instant at one place in the store
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/bonuslist`).subscribe(bonusListData => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }
      });
    } else {
      this.populateSportsbookVariables()
        .pipe(first())
        .subscribe(() => {
          this.couponStore.updateCouponInitialized(true);
        });
    }

    // If logged out, reset stale time so that freebet vouchers are retrieved next time
    this.accountQuery.isAuthenticated$
      .pipe(
        filter(isAuth => !isAuth),
        tap(() => this.freebetService.resetVouchersandGetUserVouchersStaleTime()),
        takeUntil(this.destroy$)
      )
      .subscribe();

    // Set SportsBook as active Free Bet Product
    this.freebetService.setActiveFreeBetProduct(FreeBetProductType.SportsBook);
  }

  rebetCoupon(couponCode: string, language: string = 'en'): Observable<any> {
    return this.apiService.get(APIType.Sportsbook, `api/coupons/rebet/byCode/${couponCode}/language/${language}`).pipe(
      map(data => {
        if (data.BetCoupon) {
          data.BetCoupon.MaxPercentageBonus = isNaN(data.BetCoupon.MaxBonusPerc) ? 0 : data.BetCoupon.MaxBonusPerc;
          data.BetCoupon.MinPercentageBonus = isNaN(data.BetCoupon.MinBonusPerc) ? 0 : data.BetCoupon.MinBonusPerc;
          delete data.BetCoupon.MaxBonusPerc;
          delete data.BetCoupon.MinBonusPerc;

          const updatedCoupon = this.clientsideCouponService.formatCoupon(data.BetCoupon);
          this.couponStore.updateCouponData(updatedCoupon);

          this.applicationService.showCoupon();
          return data;
        } else {
          return false;
        }
      })
    );
  }

  isOddInCoupon(oddId: number): boolean {
    if (this.couponQuery.couponData === undefined || this.couponQuery.couponData.Odds === undefined) {
      return false;
    }
    return this.couponQuery.couponData.Odds.findIndex(o => o.SelectionId === oddId) > -1;
  }

  addOdd(odd: OddModel, language: string = 'en'): UpdateCouponResponse {
    if (this.appConfig.get('live').useServerSideCoupon) {
      this.addOddServerSide(odd, language).subscribe();
      return new UpdateCouponResponse({});
    } else {
      return this.addOddClientSide(odd);
    }
  }

  addToOddChanges(oddId: number, oddValue: number): void {
    this.couponStore.addToOddChanges(oddId, oddValue);
  }

  removeOdd(oddId: number, marketId?: number): UpdateCouponResponse {
    let proceedWithRemoval = true;
    if (!this.allowCompleteDeselectOfEventOdds) {
      // See whether this market has any selections left in the coupon
      proceedWithRemoval = this.couponQuery.couponData.Odds.filter(o => o.MarketId === marketId).length > 1;
    }

    if (proceedWithRemoval) {
      this.couponStore.clearGroupingTab();

      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: this.couponQuery.couponData,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      this.couponStore.updateCouponData(response.updatedCoupon);

      this.removeFromOddChanges(oddId);
      this.liveStore.removeLiveAreaSelectionIds(oddId);

      this.applicationService.showQuickCoupon(false);

      this.couponStore.updateInvalidFreebetSelections(
        this.couponQuery.getInvalidFreebetSelections().filter(invalidSelection => invalidSelection !== oddId)
      );

      return response;
    } else {
      return undefined;
    }
  }

  removeOdds(oddIds: number[]): UpdateCouponResponse {
    let updatedCoupon: BetCoupon = this.couponQuery.couponData;
    let allOddsRemoved: boolean = true;

    this.couponStore.clearGroupingTab();

    oddIds.forEach(oddId => {
      const selection = new Selection();
      selection.oddId = oddId;

      const response = this.clientsideCouponService.updateCoupon(
        new UpdateCouponRequest({
          action: CouponAction.RemoveOdd,
          brandID: this.appConfig.get('brandId'),
          coupon: updatedCoupon,
          bonusList: this.accumulatorBonusQuery.bonusList,
          globalVariables: this.couponQuery.globalVariables,
          marketExceptions: this.couponQuery.marketExceptions,
          correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
          selection: selection,
        })
      );

      updatedCoupon = response.updatedCoupon;
      if (response.success) {
        this.removeFromOddChanges(oddId);
        this.liveStore.removeLiveAreaSelectionIds(oddId);
      } else {
        allOddsRemoved = false;
      }
    });

    this.couponStore.updateCouponData(updatedCoupon);
    this.applicationService.showQuickCoupon(false);

    this.couponStore.updateInvalidFreebetSelections(
      this.couponQuery.getInvalidFreebetSelections().filter(invalidSelection => !oddIds.some(odd => odd === invalidSelection))
    );

    return new UpdateCouponResponse({
      success: allOddsRemoved,
      updatedCoupon: updatedCoupon,
    });
  }

  removeFromOddChanges(oddId: number): void {
    this.couponStore.removeFromOddChanges(oddId);
  }

  updateOddBankerStatus(oddId: number, isBanker: boolean): UpdateCouponResponse {
    const selection = new Selection();
    selection.oddId = oddId;

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateOddBankerStatus,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: selection,
        isBanker: isBanker,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  clearAllBankers(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ClearAllBankers,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateGroupings(groupings: BetCouponGroup[]): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.UpdateGroupings,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        groupings: groupings,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  isGroupingVisible(grouping: BetCouponGroup, isLast: boolean): boolean {
    const groupingsTabSelected = this.couponQuery.groupingsTabSelected;

    if (groupingsTabSelected === CouponGroupingType.Split && isLast) {
      return true;
    } else if (groupingsTabSelected === CouponGroupingType.Combination) {
      if (!isLast && grouping.Grouping !== 1) {
        return true;
      }
    } else if (groupingsTabSelected === CouponGroupingType.Singles && grouping.Grouping === 1) {
      return true;
    }

    return false;
  }

  validateCoupon(): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );

    return response;
  }

  validateAndPostCoupon(isQuickCoupon: boolean = false): Observable<boolean> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const validation = this.validateCoupon();

    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      this.addBetSubmittedEvent(this.couponQuery.couponData, isQuickCoupon);
      return this.postCoupon();
    }
  }

  validateAndPostBookCoupon(): Observable<any> {
    if (this.couponQuery.couponData === null) {
      return of(false);
    }

    const bookedCouponCount = this.couponQuery.bookedCoupons.length;
    const maxNumberOfBookedBets = 20;

    if (bookedCouponCount >= maxNumberOfBookedBets) {
      this.notificationService.showErrorNotification(
        $localize`Maximum number of booked bets reached. Please remove previously booked bets.`
      );
      return of(false);
    }

    const validation = this.validateCoupon();
    if (!validation.success) {
      this.handleErrorMessage(validation.statusCode);
      return of(false);
    } else {
      return this.postBookCoupon();
    }
  }

  updateUI(ui: CouponUIState): void {
    this.couponStore.update({ ui });
  }

  updateCouponSetting(couponSettingKey: string, couponSetting: any): void {
    const setting = new CouponSettings({});
    setting[couponSettingKey] = couponSetting;
    this.couponStore.updateCouponSetting(setting);
  }

  clearCouponData(): void {
    this.couponStore.clearCouponData();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  removeBookedCoupon(couponCode: string): void {
    this.couponStore.removeBookedCoupon(couponCode);
  }

  getStatus(statusCode: number): string {
    return this.getInsertCouponStatus(statusCode);
  }

  updateCoupon(couponData: any): UpdateCouponResponse {
    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.ValidateCoupon,
        brandID: this.appConfig.get('brandId'),
        coupon: couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrix,
      })
    );
    this.couponStore.updateCouponData(response.updatedCoupon);
    return response;
  }

  updateExpiredEvents(expiredEvents: ExpiredEventsModel): void {
    this.couponStore.updateExpiredEvents(expiredEvents);
  }

  updateInvalidFreebetSelections(freebetDisabledSelections: number[]): void {
    this.couponStore.updateInvalidFreebetSelections(freebetDisabledSelections);
  }

  clearExpiredEvents(): void {
    this.couponStore.clearExpiredEvents();
  }

  getOddsChanged(couponOdds: CouponOddsModel[], language: string = 'en'): Observable<any> {
    const apiSettings: APISettings = new APISettings({
      noAuthToken: true,
    });
    const bodyData = couponOdds;

    return this.apiService.post<any>(APIType.Sportsbook, `api/feeds/oddschanged/${language}`, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData || responseData.length === 0) {
          return;
        }
        const couponDataCopy: BetCoupon = cloneDeep(this.couponQuery.couponData);

        responseData.forEach(changedOdd => {
          const couponOdd = couponDataCopy.Odds.find(o => o.SelectionId === changedOdd.SelectionID);

          if (couponOdd) {
            couponOdd.OddValue = changedOdd.SelectionValue;
            couponOdd.ConfirmedOddValue = changedOdd.SelectionValue;
            couponOdd.IsLocked = changedOdd.IsLocked;
            couponOdd.IsExpired = changedOdd.IsExpired;
          }

          this.couponStore.updateOddChange(changedOdd.SelectionID, changedOdd.SelectionValue);
        });
        this.couponStore.updateCouponData(couponDataCopy);

        // TODO:this has to be changed with a new clientside method.
        this.couponStakeHandlerService.updateStakeValue(this.couponQuery.couponData.StakeGross);
      })
    );
  }

  acceptOddChanges(): void {
    const oddChangesCopy: OddChanges[] = cloneDeep(this.couponQuery.oddChanges);

    oddChangesCopy.forEach(o => {
      o.initialOddValue = o.latestOddValue;
      o.valueChanged = false;
    });

    this.couponStore.updateOddChanges(oddChangesCopy);
  }

  getInsertCouponStatus(statusCode: number): string {
    return INSERT_COUPON_STATUS_CODES[statusCode];
  }

  getUpdateCouponStatus(statusCode: number): string {
    return statusCode === 21 ? $localize`Over maximum winning amount` : UPDATE_COUPON_STATUS_CODES[statusCode];
  }

  showExpiredNotification(): void {
    let expiredMessage;
    const numOfExpiredEvents = this.couponQuery.expiredEvents.originalEventCount - this.couponQuery.expiredEvents.availableEventCount;
    if (numOfExpiredEvents === 1) {
      expiredMessage = $localize`1 selection has expired. The selection has been removed from the Betslip.`;
    } else if (numOfExpiredEvents > 1) {
      const numOfExpired = this.couponQuery.expiredEvents.originalEventCount - this.couponQuery.expiredEvents.availableEventCount;
      expiredMessage = $localize`${numOfExpired} selections have expired and we have recalculated your returns accordingly.`;
    }
    this.sendBetslipErrorEvent(expiredMessage);
    this.notificationService.showWarningMessage(expiredMessage, 5000, false);
  }

  createCouponFromSelectionIds(selectionIds: { SelectionId: number }[], stake: number, language: string = 'en'): Observable<void> {
    this.loadingService.enqueueLoader();
    return this.apiService
      .post(
        APIType.Sportsbook,
        `api/coupons/create/language/${language}`,
        stake
          ? {
              Selections: selectionIds,
              Stake: stake,
            }
          : {
              Selections: selectionIds,
            },
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(
        map(responseData => {
          if (!responseData || responseData.length === 0 || !responseData.BetCoupon) {
            return;
          }

          this.couponStore.clearCouponData();

          const betCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
          this.couponStore.updateCouponData(betCoupon);
          betCoupon.Odds.forEach((odd: BetCouponOdd) => {
            this.couponStore.addToOddChanges(odd.SelectionId, odd.OddValue);
          });
        }),
        finalize(() => {
          this.loadingService.dequeueLoader();
        })
      );
  }

  updatePreviousPage(path: string): void {
    this.couponStore.updatePreviousPage(path);
  }

  private addOddClientSide(odd: OddModel): UpdateCouponResponse {
    this.couponStore.clearGroupingTab();

    const response = this.clientsideCouponService.updateCoupon(
      new UpdateCouponRequest({
        action: CouponAction.AddOdd,
        brandID: this.appConfig.get('brandId'),
        coupon: this.couponQuery.couponData,
        bonusList: this.accumulatorBonusQuery.bonusList,
        globalVariables: this.couponQuery.globalVariables,
        marketExceptions: this.couponQuery.marketExceptions,
        correctScoreOddsMatrix: this.couponQuery.correctScoreOddsMatrixData,
        selection: odd.toSelection(),
        allowSameMatchSelections: this.appConfig.get('sports').allowSameMatchSelections,
      })
    );

    this.couponStore.updateCouponData(response.updatedCoupon);

    if (!response.success) {
      this.handleErrorMessage(response.statusCode);
    }

    const newOdd = response.updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
    if (newOdd) {
      this.addToOddChanges(odd.id, newOdd.OddValue);
    }

    if (response.updatedCoupon.Odds.length === 1) {
      this.applicationService.showQuickCoupon(true, response.updatedCoupon.Odds[0]);
    } else {
      this.applicationService.showQuickCoupon(false);
    }

    return response;
  }

  private addOddServerSide(odd: OddModel, language: string = 'en'): Observable<UpdateCouponResponse> {
    // If allowSameMatchSelections is set to false and there is already an odd with the same
    // match of the new odd, remove the previous odd before calling the server
    if (!this.appConfig.get('sports').allowSameMatchSelections) {
      const prevSelection = this.couponQuery.getSameMatchSelection(odd.matchId);
      if (prevSelection) {
        this.removeOdd(prevSelection.SelectionId);
      }
    }

    const couponEmpty = this.couponQuery.couponData === undefined;
    const url = `api/LiveCoupons/${couponEmpty ? 'AddSelections' : 'AddSelectionsToCoupon'}/${language}?selectionIds[0]=${odd.id}`;
    const bodyData = this.couponQuery.couponData || {};
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });

    return this.apiService.post<any>(APIType.Sportsbook, url, bodyData, apiSettings).pipe(
      map(responseData => {
        if (!responseData.BetCoupon) {
          return new UpdateCouponResponse({
            success: false,
            statusCode: responseData.ResponseStatus,
          });
        }

        responseData.BetCoupon.MaxPercentageBonus = isNaN(responseData.BetCoupon.MaxBonusPerc) ? 0 : responseData.BetCoupon.MaxBonusPerc;
        responseData.BetCoupon.MinPercentageBonus = isNaN(responseData.BetCoupon.MinBonusPerc) ? 0 : responseData.BetCoupon.MinBonusPerc;
        delete responseData.BetCoupon.MaxBonusPerc;
        delete responseData.BetCoupon.MinBonusPerc;

        const updatedCoupon = this.clientsideCouponService.formatCoupon(responseData.BetCoupon);
        this.couponStore.updateCouponData(updatedCoupon);
        const newOdd = updatedCoupon.Odds.find(o => o.SelectionId === odd.id);
        if (newOdd) {
          this.addToOddChanges(odd.id, newOdd.OddValue);
        }

        const selectedAreaInMatchView = this.liveQuery.selectedAreaInMatchView;
        if (selectedAreaInMatchView) {
          this.liveStore.updateLiveAreaSelectionIds({ [odd.id]: selectedAreaInMatchView.id });
        }

        if (updatedCoupon.Odds.length === 1) {
          this.applicationService.showQuickCoupon(true, updatedCoupon.Odds[0]);
        } else {
          this.applicationService.showQuickCoupon(false);
        }

        return new UpdateCouponResponse({
          success: true,
          updatedCoupon: updatedCoupon,
          statusCode: responseData.ResponseStatus,
        });
      })
    );
  }

  private postCoupon(): Observable<boolean> {
    this.loading = true;
    return this.syncCouponToServer().pipe(
      concatMap(syncResponse => {
        if (!syncResponse.success) {
          this.loading = false;
          this.handleErrorMessage(syncResponse.statusCode);
          return of(false);
        }

        return this.insertCoupon().pipe(
          map(insertResponse => {
            this.loading = false;
            if (!insertResponse.success) {
              const errorMessage = this.getInsertCouponStatus(insertResponse.statusCode);
              this.sendBetFailureEvent(insertResponse, errorMessage);
              this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
              return false;
            }
            const betResponseStatus = insertResponse.statusCode;
            const coupon = insertResponse.updatedBetCoupon;

            if (betResponseStatus === 1) {
              // Coupon saved successfully.
              const inEvaluation = coupon !== undefined && coupon.CurrentEvalReason !== 0;
              if (inEvaluation) {
                coupon.CouponId = insertResponse.couponId;
                coupon.CouponCode = insertResponse.couponCode;
                coupon.CouponDate = insertResponse.couponDate;

                this.evaluationService.addToEvaluation(coupon);

                this.notificationService.showInfoNotification(
                  $localize`Coupon is being evaluated by operator`,
                  $localize`Coupon in Evaluation`
                );
              } else {
                this.accountService.updateBalance();
                this.myBetsService.addNewBet(insertResponse.couponCode);
                this.dataLayerService.createDataLayerEvent({
                  event: 'btk.betPlaced',
                  betPlaced: insertResponse.updatedBetCoupon.StakeGross,
                });
                this.dataLayerService.createDataLayerEvent({
                  event: 'user-place-bet',
                  userId: this.accountQuery.userData.id,
                  couponID: insertResponse.couponCode,
                  totalStake: insertResponse.updatedBetCoupon.StakeGross,
                });

                this.couponStore.updateLastPlacedCoupon({
                  ...insertResponse.updatedBetCoupon,
                  BetDetails: {
                    ...insertResponse.updatedBetCoupon?.BetDetails,
                    FreeBetDetails: {
                      code: insertResponse.updatedBetCoupon?.BetDetails?.FreeBetDetails?.Code,
                      name: insertResponse.updatedBetCoupon?.BetDetails?.FreeBetDetails?.Name,
                      type: insertResponse.updatedBetCoupon?.BetDetails?.FreeBetDetails?.Type,
                    },
                  },
                });
                this.couponStore.update({ lastPlacedCouponCode: insertResponse.couponCode });
              }

              this.freebetService.resetVouchersandGetUserVouchersStaleTime();
              this.freebetService.getUserVouchers().pipe(first(), takeUntil(this.destroy$)).subscribe();
              this.couponStore.update({ isLastPlacedCouponInEvaluation: inEvaluation });
              this.sendBetSuccessEvent(insertResponse);
            } else if (betResponseStatus === 3) {
              // One of the chosen events has expired.
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }
              const errorMessage = this.getStatus(betResponseStatus);
              this.sendBetFailureEvent(insertResponse, errorMessage);
              this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
            } else if (betResponseStatus === 4) {
              // One of the odds has changed.
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }
              const errorMessage = this.getStatus(betResponseStatus);
              this.sendBetFailureEvent(insertResponse, errorMessage);
              this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
            } else {
              if (coupon !== undefined) {
                this.updateCoupon(coupon);
              }
              const errorMessage = this.getStatus(betResponseStatus);
              this.sendBetFailureEvent(insertResponse, errorMessage);
              this.notificationService.showErrorNotification(errorMessage, $localize`Coupon Not Posted`);
            }
            return true;
          }),
          catchError(err => {
            // In case bet was still placed but for some reason call timed out, best check the current voucher status
            this.freebetService.getUserVouchers().pipe(first(), takeUntil(this.destroy$)).subscribe();
            return throwError(err);
          })
        );
      })
    );
  }

  private postBookCoupon(): Observable<any> {
    this.loading = true;

    return this.bookCoupon().pipe(
      map(bookResponse => {
        this.loading = false;
        if (!bookResponse.success) {
          this.notificationService.showErrorNotification(this.getInsertCouponStatus(bookResponse.statusCode), $localize`Coupon Not Booked`);
          return false;
        }

        const bookedCoupon = new BookedCoupon({
          couponCode: bookResponse.couponCode,
          date: format(new Date(), 'dd/MM/yy'),
        });

        this.couponStore.updateBookedCoupon(bookedCoupon);
        return bookedCoupon;
      })
    );
  }

  private handleErrorMessage(statusCode: number): void {
    const errorMessage = this.getUpdateCouponStatus(statusCode);

    // Show error message according to the status code returned
    if (statusCode === 18) {
      // 18 => 'One of the chosen events has expired'. In that case we include the number of expired events
      this.showExpiredNotification();
    } else if (statusCode === 19) {
      // 19 => 'Stake under minimum amount allowed'. In that case we include the minimum stake amount
      const message = `${errorMessage} of ${this.couponStakeHandlerService.getFormattedMinStake()}`;
      this.sendBetslipErrorEvent(message);
      this.notificationService.showErrorNotification(message, $localize`Coupon Error`);
    } else if (statusCode === 22) {
      // 22 => 'Group stake under minimum amount allowed'. In that case we include the minimum group stake amount
      const minMessage = $localize`The minimum acceptable total stake is ${this.couponStakeHandlerService.getFormattedTotalStake()}.`;
      const message = `${errorMessage} of ${this.couponStakeHandlerService.getFormattedMinGroupStake()}. ${minMessage}`;
      this.sendBetslipErrorEvent(message);
      this.notificationService.showErrorNotification(message, $localize`Coupon Error`);
    } else {
      this.sendBetslipErrorEvent(errorMessage);
      this.notificationService.showErrorNotification(
        errorMessage,
        statusCode === 21 ? $localize`Maximum Win Exceeded` : $localize`Coupon Error`
      );
    }
  }

  private populateSportsbookVariables(): Observable<void> {
    const apiCalls = [
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/bonuslist`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/globalvariables`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/markettypeids`),
      this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/incompatiblemarketexceptions`),
    ];
    const correctScoreOddsMatrix = this.couponQuery.correctScoreOddsMatrix;
    if (
      !correctScoreOddsMatrix ||
      correctScoreOddsMatrix.cacheVersion !== this.appConfig.get('siteVersion') ||
      correctScoreOddsMatrix.data === undefined
    ) {
      apiCalls.push(this.apiService.get<any>(APIType.SportsbookFeed, `api/settings/selectionCompatibilityMatrix`));
    }

    return forkJoin(apiCalls).pipe(
      map(([bonusListData, globalVariablesData, marketTypeIdsData, marketExceptionsData, correctScoreOddsMatrixData]) => {
        if (bonusListData !== undefined) {
          const bonusList: Bonus[] = bonusListData;
          this.accumulatorBonusStore.updateBonusList(bonusList);
        }

        if (globalVariablesData !== undefined) {
          const globalVariables: BetCouponGlobalVariable = globalVariablesData;
          this.couponStore.updateGlobalVariables(globalVariables);
        }

        if (marketTypeIdsData !== undefined) {
          const marketTypeIdsModel: MarketTypeIdsModel[] = [];
          marketTypeIdsData.forEach(response => {
            const marketTypeIds = new MarketTypeIdsModel({
              marketTypeId: response.IDMarketType,
              lookupCode: response.LookupCode,
            });

            marketTypeIdsModel.push(marketTypeIds);
          });

          this.sportStore.updateMarketTypeIds(marketTypeIdsModel);
        }

        if (marketExceptionsData !== undefined) {
          const marketExceptions: Dictionary<number, number[]> = marketExceptionsData;
          this.couponStore.updateMarketExceptions(marketExceptions);
        }

        if (correctScoreOddsMatrixData !== undefined) {
          this.couponStore.updateCorrectScoreOddsMatrix({
            cacheVersion: this.appConfig.get('siteVersion'),
            data: correctScoreOddsMatrixData,
          });
        }
      })
    );
  }

  private syncCouponToServer(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    return this.apiService.put<any>(APIType.Sportsbook, `api/BetCoupons/UpdateCoupon`, this.couponQuery.couponData, apiSettings).pipe(
      map(response => {
        let couponSuccess = false;
        if (response.ResponseStatus === 0) {
          // BTK-1299: The following line has been temporarily commented out because the response returned by the api
          // doesn't yet match our model structure, resulting in lost/misplaced data
          // this.setCouponData(this.clientsideCouponService.formatCoupon(response.BetCoupon));

          // BTK-1419: Temporarily creating a copy of the OddValue field in a new field called ConfirmedOddValue.
          // This is usually done by the api but for now we have to do it manually due to the above commented out line
          const couponData = cloneDeep(this.couponQuery.couponData);
          couponData.Odds.forEach(odd => {
            odd.ConfirmedOddValue = odd.OddValue;
          });
          this.couponStore.updateCouponData(couponData);

          couponSuccess = true;
        } else if (response.ResponseStatus === 17) {
          // One of the odds has changed.
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (this.couponQuery.couponSettings.allowOddChanges) {
            couponSuccess = true;
          }
        } else if (response.ResponseStatus === 18) {
          // One of the chosen events has expired
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }

          if (response.ExpiredEvents !== undefined && response.ExpiredEvents.length > 0) {
            const expiredEventsModel = new ExpiredEventsModel({
              availableEventCount: response.AvailableEventCount,
              originalEventCount: response.OriginalEventCount,
              expiredEvents: response.ExpiredEvents.map(
                event =>
                  new ExpiredEvents({
                    eventId: event.IDEvent,
                    smartBetCode: event.SmartCode,
                    eventName: event.EventName,
                    eventDate: event.EventDate,
                    eventCategory: event.EventCategory,
                    marketName: event.MarketName,
                    selectionName: event.SelectionName,
                    oddValue: event.OddValue,
                  })
              ),
            });

            this.updateExpiredEvents(expiredEventsModel);
          }
        } else if (response.ResponseStatus === 416) {
          // Freebet invalid selections
          this.updateInvalidFreebetSelections(response.ErrorsList[416].map(selection => selection.IDSelection));
        } else {
          if (response.BetCoupon !== undefined) {
            this.updateCoupon(response.BetCoupon);
          }
        }

        return {
          success: couponSuccess,
          statusCode: response.ResponseStatus,
        };
      })
    );
  }

  private insertCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const bodyData = this.appConfig.get('sports').coupon.sendBookedCouponCode
      ? {
          AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
          AllowStakeReduction: this.couponQuery.couponSettings.allowStakeReduction,
          // Sportbook requested that a null value is sent - [SB-6184]
          BookedCouponCode: this.couponQuery.couponSettings.bookedCouponCode ? this.couponQuery.couponSettings.bookedCouponCode : null,
          BetCoupon: {
            ...this.couponQuery.couponData,
          },
          RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
          TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true,
        }
      : {
          AllowOddChanges: this.couponQuery.couponSettings.allowOddChanges,
          AllowStakeReduction: this.couponQuery.couponSettings.allowStakeReduction,
          BetCoupon: {
            ...this.couponQuery.couponData,
          },
          RequestTransactionId: this.clientsideCouponService.generateTransactionId(), // TODO: don't regenerate every time
          TransferStakeFromAgent: this.couponQuery.couponSettings.transferUserId === null ? false : true,
        };

    return this.apiService.post<any>(APIType.Sportsbook, `api/coupons/InsertCoupon`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponPosted = false;
        if (responseData.ResponseStatus === 1) {
          couponPosted = true;
        }

        return {
          success: couponPosted,
          statusCode: responseData.ResponseStatus,
          couponId: responseData.CouponId,
          couponCode: responseData.CouponCode,
          couponDate: responseData.CouponDate,
          updatedBetCoupon: responseData.UpdatedBetCoupon,
        };
      }),
      catchError(() =>
        of({
          success: false,
        })
      )
    );
  }

  private bookCoupon(): Observable<any> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: this.couponQuery.couponSettings.transferUserId });
    const requestTransactionId = this.clientsideCouponService.generateTransactionId(); // TODO: don't regenerate every time
    const bodyData = this.couponQuery.couponData;

    return this.apiService.post<any>(APIType.Sportsbook, `api/BetCoupons/Book/${requestTransactionId}`, bodyData, apiSettings).pipe(
      map(responseData => {
        let couponBooked = false;
        if (responseData.ResponseStatus === 1) {
          couponBooked = true;
        }

        return {
          success: couponBooked,
          statusCode: responseData.ResponseStatus,
          couponCode: responseData.BookedCouponCode,
        };
      })
    );
  }

  private addBetSubmittedEvent(couponData, isQuickCoupon: boolean): void {
    const odds = couponData.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];
    const eventObj = {
      event: BetslipActions.BetSubmitted,
      userID: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData.currency.name,
      betType: isQuickCoupon ? 'Single_quick_bet' : CouponType[couponData.CouponType],
      selections: odds.length,
      isFreeBet: Boolean(couponData.BetDetails?.FreeBetDetails?.Code),
      voucherCode: couponData.BetDetails?.FreeBetDetails?.Code,
      product,
      category,
      subCategory,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendBetslipErrorEvent(errorMessage): void {
    const odds = this.couponQuery?.couponData?.Odds || [];
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];

    this.dataLayerService.createDataLayerEvent({
      event: BetslipActions.BetslipError,
      userId: this.accountQuery.userData?.id,
      errorMessage,
      product,
      category,
      subCategory,
    });
  }

  private sendBetSuccessEvent(response): void {
    const odds = response.updatedBetCoupon.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];

    const eventObj = {
      event: BetslipActions.BetSuccess,
      userID: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData.currency.name,
      couponId: response.couponCode,
      betStake: response.updatedBetCoupon.StakeGross,
      betType: CouponType[response.updatedBetCoupon.CouponTypeId],
      selections: odds.length,
      isFreeBet: Boolean(response.updatedBetCoupon.BetDetails?.FreeBetDetails?.Code),
      voucherCode: response.updatedBetCoupon.BetDetails?.FreeBetDetails?.Code,
      product,
      category,
      subCategory,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }

  private sendBetFailureEvent(response, errorMessage): void {
    const odds = response.updatedBetCoupon.Odds;
    const category = odds.reduce(BetslipSports, odds[0].SportName);
    const subCategory = odds.reduce(BetslipTournaments, odds[0].TournamentName);
    const product = EventBasedProduct[odds.reduce(BetslipProduct, odds[0].EventCategory)];

    const eventObj = {
      event: BetslipActions.BetFailure,
      userID: this.accountQuery.userData?.id,
      currency: this.accountQuery.userData.currency.name,
      betStake: response.updatedBetCoupon.StakeGross,
      betType: CouponType[response.updatedBetCoupon.CouponTypeId],
      selections: odds.length,
      isFreeBet: Boolean(response.updatedBetCoupon.BetDetails?.FreeBetDetails?.Code),
      voucherCode: response.updatedBetCoupon.BetDetails?.FreeBetDetails?.Code,
      product,
      category,
      subCategory,
      errorMessage,
    };
    this.dataLayerService.createDataLayerEvent(eventObj);
  }
}
