<div class="coupon-details-container">
  <app-breadcrumb i18n-title title="Bet List" icon="coupon-check"></app-breadcrumb>

  <div class="title-header">
    <span class="title" i18n>Coupon Details</span>
    <ng-container *ngIf="item$ | async">
      <span class="divider">-</span>
      <span class="coupon-code">({{ (item$ | async).couponCode }})</span>
    </ng-container>
  </div>

  <div
    class="coupon-details-wrapper"
    *ngIf="(couponDetailsQuery.wrongCouponCode$ | async) === false && (couponDetailsQuery.isLoading$ | async) === false"
  >
    <my-bets-recent-bet
      [item]="item$ | async"
      [isRebetEnabled]="isRebetEnabled()"
      [loadCollapsed]="false"
      (getBetDetails)="getBetDetails($event)"
      [liveDetails]="(couponDetailsQuery.couponDetails$ | async)?.liveDetails"
      [isVirtualsInstantLeague]="(isInstant$ | async)"
    ></my-bets-recent-bet>
  </div>
  <app-form-message
    type="warning"
    faIcon="warning"
    i18n-text
    text="Coupon code is invalid."
    *ngIf="couponDetailsQuery.wrongCouponCode$ | async"
    padding="big-padding"
  >
  </app-form-message>
  <app-loading-bar [show]="couponDetailsQuery.isLoading$ | async"></app-loading-bar>
</div>
