import { Injectable, OnDestroy } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { iif, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, delay, map, retryWhen, takeUntil } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoggerService } from 'src/app/core/services/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ABTestService implements OnDestroy {
  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly appConfig: AppConfigService, private readonly loggerService: LoggerService) {}

  /**
   * Get the value of an experiment for the current user
   * @param experimentId The ID of the experiment
   * @param defaultValue The default value for the experiment
   * @returns Returns the value of the experiment if it is available for the current user, else returns the defaultValue specified
   */
  getValue(experimentId: string, defaultValue: string): Observable<string> {
    if (!experimentId) {
      // avoid querying if an experiment id was not provided
      return of(defaultValue);
    }

    return this.getGoogleOptimize().pipe(
      map((googleOptimize: any) => {
        // initialize return value with the default
        let value = defaultValue;

        if (googleOptimize) {
          const experimentValue = googleOptimize.get(experimentId);
          if (experimentValue !== undefined) {
            // save experiment value if it's available for current user
            value = experimentValue;
          }
        }

        return value;
      }),
      takeUntil(this.destroy$)
    );
  }

  /**
   * Get the value of multiple experiments for the current user. This is more efficient
   * than calling the getValue() multiple times concurrently.
   * @param experiments An array of experiment IDs and their default values
   * @returns Returns an object containing the values for all experiments.
   * The defaultValue is returned for experiments which are not available for the current user
   */
  getValues(experiments: { experimentId: string; defaultValue: string }[]): Observable<any> {
    if (!experiments?.length) {
      // avoid querying if there are no experiments
      return of({});
    }

    return this.getGoogleOptimize().pipe(
      map((googleOptimize: any) => {
        // initialize return values with defaults for all experiments
        const values = Object.assign({}, ...experiments.map(experiment => ({ [experiment.experimentId]: experiment.defaultValue })));

        if (googleOptimize) {
          experiments.forEach(experiment => {
            const experimentValue = googleOptimize.get(experiment.experimentId);
            if (experimentValue !== undefined) {
              // save experiment value if it's available for current user
              values[experiment.experimentId] = experimentValue;
            }
          });
        }

        return values;
      }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private getGoogleOptimize(): Observable<any> {
    const config = this.appConfig.get('googleOptimize');
    const retryAttempts = config?.retryAttempts !== undefined ? config.retryAttempts : 3;
    const retryDelay = config?.retryDelay !== undefined ? config.retryDelay : 500;

    return of({}).pipe(
      map(() => {
        const googleOptimize = window['google_optimize'];
        if (!googleOptimize) {
          throw googleOptimize;
        }
        return googleOptimize;
      }),
      retryWhen(errors =>
        errors.pipe(
          concatMap((e, i) =>
            iif(
              () => i < retryAttempts,
              of(e).pipe(delay(retryDelay)), // if under the retry count, try again after a delay
              throwError(e) // otherwise throw an error to catch it below
            )
          )
        )
      ),
      catchError(err => {
        this.loggerService.logEvent('ABTestService Exception', 'Unable to retrieve instance of google_optimize', SeverityLevel.Error);
        return of(undefined);
      }),
      takeUntil(this.destroy$)
    );
  }
}
