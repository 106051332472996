import { Injectable } from '@angular/core';
import { first, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { SportStore } from 'src/app/core/state/sport/sport.store';
import { APIType } from 'src/app/shared/models/api.model';
import { OddsBoostInfoModalContent } from 'src/app/shared/models/sport.model';

@Injectable({
  providedIn: 'root',
})
export class SportsOddsBoostService {
  constructor(
    private readonly apiService: APIService,
    private readonly languageService: LanguageService,
    private readonly sportStore: SportStore
  ) {}

  retrieveOddsBoostInfoModalCMSContent(): void {
    this.apiService
      .get<OddsBoostInfoModalContent>(
        APIType.CMS,
        `/OddsBoost/GetOddsBoostInfoModalContent?language=${this.languageService.selectedLanguage.locale.toLowerCase()}`
      )
      .pipe(
        first(),
        tap(oddsBoostInfoModalCMSContent => {
          this.sportStore.updateOddsBoostInfoModalCMSContent(oddsBoostInfoModalCMSContent);
        })
      )
      .subscribe();
  }
}
