import { Injectable } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DataLayerService } from 'src/app/core/services/data-layer.service';
import { EvaluationSignalrService } from 'src/app/core/services/evaluation-signalr.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { EvaluationQuery } from 'src/app/core/state/evaluation/evaluation.query';
import { EvaluationStore } from 'src/app/core/state/evaluation/evaluation.store';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { EvaluationStatus } from 'src/app/shared/models/evaluation.model';
import { APIService } from './api.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  refreshInterval = undefined;

  private readonly operatorReasons: any = {
    0: $localize`None`,
    1: $localize`ARB/Exchange`,
    2: $localize`Late bet/Event closed`,
    3: $localize`Unavailable market price`,
    4: $localize`Section already chosen`,
    5: $localize`Sharp/Top price`,
    6: $localize`Odds changing`,
    7: $localize`Loss limit exceeded`,
    8: $localize`Market suspended`,
  };
  private readonly refreshTimer = 2000;

  constructor(
    private readonly apiService: APIService,
    private readonly notificationService: NotificationService,
    private readonly accountQuery: AccountQuery,
    private readonly evaluationStore: EvaluationStore,
    private readonly evaluationQuery: EvaluationQuery,
    private readonly evaluationSignalrService: EvaluationSignalrService,
    private readonly dataLayerService: DataLayerService
  ) {}

  initEvaluation(): void {
    let currLoadApiCalls = true;
    this.accountQuery.isAuthenticated$.subscribe(auth => {
      if (auth) {
        this.getPendingCoupons().subscribe();
      } else {
        // stop Evaluation Connection
        this.evaluationSignalrService.stopConnection();
      }
    });

    this.evaluationQuery.evaluationList$
      .pipe(
        tap(evaluationList => {
          if (evaluationList && evaluationList.length > 0) {
            // start Evaluation Connection
            this.evaluationSignalrService.startConnection();

            // load api calls to check for existing evaluation offers
            if (currLoadApiCalls) {
              currLoadApiCalls = false;
              const apiCalls: Observable<any>[] = [];
              evaluationList.forEach(evaluation => {
                const apiSettings: APISettings = new APISettings({ inBehalfOf: evaluation.coupon.UserId });
                apiCalls.push(
                  this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pending/byCode/${evaluation.coupon.CouponCode}`, apiSettings)
                );

                forkJoin(apiCalls)
                  .pipe(
                    map(responseData => {
                      responseData.forEach((data: any) => {
                        if (data) {
                          this.betEvaluationData(this.parseApiResponse(data));
                        }
                      });
                    })
                  )
                  .subscribe();
              });
            }
          } else {
            currLoadApiCalls = false;
          }
        })
      )
      .subscribe();

    this.evaluationSignalrService.betEvaluationComplete$
      .pipe(
        filter((response: any) => Object.keys(response).length !== 0),
        tap((response: any) => this.betEvaluationData(response))
      )
      .subscribe();
  }

  parseApiResponse(response): any {
    const data = {
      updatedBetCoupon: {
        confirmedStake: response.ConfirmedStake,
        couponCode: response.UpdatedBetCoupon.CouponCode,
        evalCouponStatusId: response.UpdatedBetCoupon.EvalCouponStatusId,
        idOperatorReason: response.UpdatedBetCoupon.IDOperatorReason,
        maxWinNet: response.UpdatedBetCoupon.MaxWinNet,
        maxWithholdingTax: response.UpdatedBetCoupon.MaxWithholdingTax,
        offerResponseByUser: response.UpdatedBetCoupon.OfferResponseByUser,
        stake: response.UpdatedBetCoupon.Stake,
        stakeGross: response.UpdatedBetCoupon.StakeGross,
        userId: response.UpdatedBetCoupon.UserId,
        odds: [],
      },
    };

    response.UpdatedBetCoupon.Odds.forEach(odd => {
      data.updatedBetCoupon.odds.push({
        categoryName: odd.CategoryName,
        championship: odd.Championship,
        confirmedOddValue: odd.ConfirmedOddValue,
        eventCategory: odd.EventCategory,
        eventName: odd.EventName,
        marketName: odd.MarketName,
        oddValue: odd.OddValue,
        onLineCode: odd.OnLineCode,
        selectionName: odd.SelectionName,
      });
    });

    return data;
  }

  betEvaluationData(response: any): void {
    if (!response) {
      return;
    }

    const evaluationList = this.evaluationQuery.evaluationList();

    // Get the response JSON
    const evalStatusId = response.updatedBetCoupon.evalCouponStatusId;
    let status = EvaluationStatus.Pending;

    if (evalStatusId === 1) {
      // coupon removed from evaluation, operator or client agreed
      status = EvaluationStatus.Accepted;
    } else if (evalStatusId === 3 || evalStatusId === 7) {
      // coupon removed from evaluation, operator or client declined
      status = EvaluationStatus.Declined;
    } else if (evalStatusId === 8 || evalStatusId === 9 || evalStatusId === 10) {
      // operator changed the coupon
      status = EvaluationStatus.AwaitingReply;
    }

    const currentEvaluation = evaluationList.find(evalItem => evalItem.coupon.CouponCode === response.updatedBetCoupon?.couponCode);

    if (status === EvaluationStatus.Pending) {
      if (!currentEvaluation.coupon.updatedBetCoupon) {
        this.evaluationStore.updateUpdatedBetCoupon(currentEvaluation.coupon.CouponCode, response.updatedBetCoupon);
      }
      return;
    }

    if (currentEvaluation) {
      if (status === EvaluationStatus.Accepted) {
        if (currentEvaluation.status === EvaluationStatus.AwaitingReply) {
          // previously was in an awaitingReply state
          this.removeFromAwaitingReplyList(currentEvaluation.coupon.couponCode);
        }
        this.showAcceptedMessage(response.updatedBetCoupon);
        this.dataLayerService.createDataLayerEvent({ event: 'btk.betPlaced', betPlaced: response.updatedBetCoupon.stakeGross });

        // remove from the evaluation list
        this.evaluationStore.removeFromEvaluation(currentEvaluation.coupon.CouponCode);
      } else if (status === EvaluationStatus.Declined) {
        if (currentEvaluation.status === EvaluationStatus.AwaitingReply) {
          // previously was in an awaitingReply state
          this.removeFromAwaitingReplyList(currentEvaluation.coupon.CouponCode);
        }
        this.showDeclinedMessage(response.updatedBetCoupon);

        // remove from the evaluation list
        this.evaluationStore.removeFromEvaluation(currentEvaluation.coupon.CouponCode);
      } else if (status === EvaluationStatus.AwaitingReply) {
        // add coupon to awaiting reply list
        this.addToAwaitingReplyList(response);
      }

      this.evaluationStore.updateCouponStatus(currentEvaluation.coupon.CouponCode, status);
    }
  }

  getPendingCoupons(): Observable<void> {
    return this.apiService.get<any>(APIType.Sportsbook, `api/coupons/pending`).pipe(
      map(responseData => {
        if (!responseData || !responseData.length) {
          // loggingService.logError('Unable to get pending evaluation coupons: ' + JSON.stringify(response.error));
          this.evaluationStore.clearEvaluationList();
          return;
        }

        responseData.forEach(coupon => {
          this.evaluationStore.addToEvaluation(coupon);
        });
        return;
      })
    );
  }

  addToEvaluation(coupon: any): void {
    this.evaluationStore.addToEvaluation(coupon);
  }

  parseCoupon(data: any): any {
    const couponData: any = cloneDeep(data.updatedBetCoupon);
    couponData.ConfirmedStake = data.confirmedStake;

    return couponData;
  }

  addToAwaitingReplyList(data: any): void {
    const parsedCoupon = this.parseCoupon(data);
    this.evaluationStore.updateAwaitingReplyList(parsedCoupon);
  }

  removeFromAwaitingReplyList(couponCode: string): void {
    this.evaluationStore.removeAwaitingReply(couponCode);
  }

  sendEvaluationReply(couponCode: string, accepted: boolean, inBehalfOf: string): Observable<boolean> {
    const apiSettings: APISettings = new APISettings({ inBehalfOf: inBehalfOf });
    return this.apiService.put<any>(APIType.Sportsbook, `api/coupons/pending/byCode/accept/${couponCode}/${accepted}`, apiSettings).pipe(
      map(responseData => {
        if (!responseData) {
          // loggingService.logError('Unable to send evaluation reply: ' + JSON.stringify(response.error));
          return false;
        }

        const replyAccepted = responseData.ResponseStatus === 1;
        return replyAccepted;
      })
    );
  }

  showAcceptedMessage(couponData: any): void {
    let couponApproved = $localize`Bet Slip approved by operator: ${couponData.couponCode}`;
    if (couponData.offerResponseByUser) {
      couponApproved = $localize`Bet Slip approved by client: ${couponData.couponCode}`;
    }

    this.notificationService.showSuccessNotification(couponApproved);
  }

  showDeclinedMessage(couponData: any): void {
    let couponRejected = $localize`Bet Slip rejected by operator: ${couponData.couponCode}`;
    if (couponData.offerResponseByUser) {
      couponRejected = $localize`Bet Slip rejected by client: ${couponData.couponCode}`;
    }

    if (couponData.idOperatorReason !== 0) {
      const reason = $localize`Reason`;
      const operatorReason = this.getOperatorReason(couponData.idOperatorReason);

      couponRejected = `${couponRejected} ${reason}: ${operatorReason}`;
    }

    this.notificationService.showErrorNotification(couponRejected);
  }

  evaluationReplyClicked(couponCode: string, accepted: boolean, userId: string): void {
    // register scope level event handler for message buttons
    this.removeFromAwaitingReplyList(couponCode);

    this.sendEvaluationReply(couponCode, accepted, userId).subscribe(replyAccepted => {
      if (accepted && !replyAccepted) {
        // coupon not saved
        this.notificationService.showErrorNotification($localize`The coupon has been rejected automatically.`);
      }
    });
  }

  getOperatorReason(reasonCode: number): string {
    return this.operatorReasons[reasonCode];
  }
}
