import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { SportQuery } from 'src/app/core/state/sport/sport.query';
import { TodaysEventsQuery } from 'src/app/modules/sport/state/todays-events/todays-events.query';
import { expandCollapse, fadeIn } from 'src/app/shared/animations';
import { AreaModel, RegionAreaDataModel, RegionAreaDropdownViewModel, RegionModel } from 'src/app/shared/models/sport.model';

const EVENT_ODDS = 'events';
const TODAY_EVENT = 'todays-events';

@Component({
  selector: 'app-region-area-dropdown',
  templateUrl: './region-area-dropdown.component.html',
  styleUrls: ['./region-area-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [expandCollapse(), fadeIn()],
})
export class RegionAreaDropdownComponent implements OnInit, OnDestroy {
  @Output() readonly selectedAreaAndRegionChange = new EventEmitter();
  @Input() areas: AreaModel[];
  @Input() regions: RegionModel[];
  @Input() initialAreaId: number;
  @Input() showMoreInfo: boolean;
  destroy$: Subject<boolean> = new Subject<boolean>();
  dropdownOpened$ = new BehaviorSubject(false);
  selectedRegionRow$: BehaviorSubject<number> = new BehaviorSubject(0);
  selectedRegionIndex$: BehaviorSubject<number> = new BehaviorSubject(0);
  regionsDropdown$ = new BehaviorSubject(undefined);
  selectedRegion$ = new BehaviorSubject(undefined);
  selectedArea$ = new BehaviorSubject(undefined);
  showInfo$ = new BehaviorSubject(false);
  dontUpdateStore = false;
  areasCache: string;
  regionsCache: string;

  constructor(
    private readonly sportService: SportService,
    private readonly sportQuery: SportQuery,
    private readonly applicationQuery: ApplicationQuery,
    private readonly todaysEventsQuery: TodaysEventsQuery
  ) {}

  ngOnInit(): void {
    if (this.applicationQuery.activeUrl.length > 1 && this.applicationQuery.activeUrl[1] === EVENT_ODDS) {
      combineLatest(this.sportQuery.areas$, this.sportQuery.regions$).subscribe((data: any) => {
        if (!this.sportQuery.restoreAreaRegionsCache) {
          return;
        }
        this.parseData(data[0], data[1]);
      });
      this.parseData(this.areas, this.regions);

      this.sportQuery.selectedArea$.pipe(takeUntil(this.destroy$)).subscribe(area => {
        if (this.dontUpdateStore) {
          this.dontUpdateStore = false;
          return;
        }
        this.regionsDropdown$.value.forEach((row, rowIndex) => {
          row.forEach((region, index) => {
            region.areas.forEach(areaDropdown => {
              if (area.id === areaDropdown.id) {
                this.selectedRegionRow$.next(rowIndex);
                this.selectedRegionIndex$.next(index);
              }
            });
          });
        });
        this.selectedArea$.next(area);
      });
    } else if (this.applicationQuery.activeUrl.length > 1 && this.applicationQuery.activeUrl[1] === TODAY_EVENT) {
      combineLatest(this.todaysEventsQuery.areas$, this.todaysEventsQuery.regions$).subscribe((data: any) => {
        if (this.areasCache === JSON.stringify(data[0]) || this.regionsCache === JSON.stringify(data[1])) {
          return;
        }
        this.areasCache = JSON.stringify(data[0]);
        this.regionsCache = JSON.stringify(data[1]);
        this.parseData(data[0], data[1], true);
      });
      this.parseData(this.areas, this.regions, true);
    }

    this.sportQuery.visibleCardsAtDepthFour$.pipe(takeUntil(this.destroy$)).subscribe(visibleCards => {
      if (!visibleCards || !this.sportQuery.filterToDepthFour) {
        return;
      }

      const filteredAreas = this.sportQuery.areas.filter(area =>
        visibleCards.some((elem, index) =>
          area.belongsTo.some(ownerId => {
            if (elem && index === ownerId) {
              return true;
            }
          })
        )
      );
      const filteredRegions = this.sportQuery.regions.filter(region =>
        visibleCards.some((elem, index) =>
          region.belongsTo.some(ownerId => {
            if (elem && index === ownerId) {
              return true;
            }
          })
        )
      );
      this.parseData(filteredAreas, filteredRegions, true);
      this.dontUpdateStore = true;

      const areaMarkets = this.sportQuery.selectedPrematch[visibleCards.indexOf(true)].area.markets;
      this.sportService.updateEventSelection({ selectedArea: this.selectedArea$.value, areaMarkets, selectedMarket: areaMarkets[0] });

      this.regionsDropdown$.value.forEach((regionRow, regionRowIndex) => {
        regionRow.forEach((region, regionIndex) => {
          region.areas.forEach(area => {
            if (area.id === this.selectedArea$.value.id) {
              this.selectedRegionRow$.next(regionRowIndex);
              this.selectedRegionIndex$.next(regionIndex);
              this.selectedRegion$.next(region.region);
            }
          });
        });
      });
    });

    this.todaysEventsQuery.regions$.pipe(takeUntil(this.destroy$)).subscribe(regions => {
      if (
        regions &&
        (!this.selectedRegion$.getValue() ||
          (this.selectedRegion$.getValue() && regions.findIndex(region => region.id === this.selectedRegion$.getValue().id) < 0))
      ) {
        // Reset on regions refresh, if there is not a selected region or the selected region is not present in the new region
        this.selectedRegion$.next(regions[0]);
        this.selectedRegionIndex$.next(0);
        this.selectedRegionRow$.next(0);
      }
    });
  }

  selectArea(area: AreaModel): void {
    this.dropdownOpened$.next(false);
    this.selectedArea$.next(area);
    this.selectedAreaAndRegionChange.emit({ area, region: this.selectedRegion$.value });
  }

  toggleDropdown(): void {
    this.dropdownOpened$.next(!this.dropdownOpened$.value);
  }

  selectRegion(rowIndex: number, regionIndex: number, region: { region: RegionModel; areas: AreaModel[] }): void {
    this.selectedRegion$.next(region.region);
    if (regionIndex === this.selectedRegionIndex$.value && rowIndex === this.selectedRegionRow$.value) {
      this.selectedRegionIndex$.next(undefined);
      this.selectedRegionRow$.next(undefined);
    } else {
      this.selectedRegionRow$.next(rowIndex);
      this.selectedRegionIndex$.next(regionIndex);
    }
  }

  parseData(areas: AreaModel[], regions: RegionModel[], noEmit: boolean = false): void {
    let regionToSelect;
    regions.forEach(region => {
      region.areaIds.forEach(areaId => {
        if (this.initialAreaId) {
          if (areaId === this.initialAreaId) {
            regionToSelect = region;
          }
        } else {
          if (areaId === this.areas[0].id) {
            regionToSelect = region;
          }
        }
      });
    });

    const newRegionsWithAreas = [];
    regions.forEach((region: RegionModel) => {
      const newRegionWithArea = { region, areas: [] };
      region.areaIds.forEach(areaId => {
        areas.forEach((area: AreaModel) => {
          if (areaId === area.id) {
            newRegionWithArea.areas.push(area);
          }
        });
      });
      newRegionsWithAreas.push(newRegionWithArea);
    });

    const regeneratedDataForDropdown = this.generateRegionsDropdown(newRegionsWithAreas);
    let found = false;
    let areaToEmit;
    // If no area is already selected in query, just skip the check with the new regions and default
    if (
      this.sportQuery.selectedArea &&
      this.sportQuery.selectedArea.id &&
      newRegionsWithAreas &&
      newRegionsWithAreas[0] &&
      newRegionsWithAreas[0].areas.length
    ) {
      found = newRegionsWithAreas.some(regionWithArea =>
        regionWithArea.areas.some(area => {
          if (this.sportQuery.selectedArea && this.sportQuery.selectedArea.id === area.id) {
            areaToEmit = area;
            regionToSelect = regionWithArea.region;
            this.selectedArea$.next(area);
            return true;
          }
        })
      );
    }
    if (!found) {
      areaToEmit = areas[0];
      this.selectedArea$.next(areas[0]);
    }

    if (!noEmit) {
      this.selectedAreaAndRegionChange.emit({ area: areaToEmit, region: regionToSelect });
    }
    this.selectedRegion$.next(regionToSelect);
    this.regionsDropdown$.next(regeneratedDataForDropdown);
  }

  generateRegionsDropdown(data: RegionAreaDataModel[]): RegionAreaDropdownViewModel {
    if (!data) {
      this.regionsDropdown$.next([]);
      return;
    }
    const regeneratedRegions = [];
    let regionRow = [];
    let counter = 0;
    data.forEach(region => {
      regionRow.push(region);
      counter++;
      if (counter >= 3) {
        regeneratedRegions.push(regionRow);
        regionRow = [];
        counter = 0;
      }
    });

    regeneratedRegions.push(regionRow);

    return regeneratedRegions;
  }

  toggleInfo(): void {
    this.showInfo$.next(!this.showInfo$.value);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
