import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { LoggerService } from 'src/app/core/services/logger.service';

@Injectable()
export class ErrorHandlerWithLogger implements ErrorHandler {
  constructor(private readonly injector: Injector) {}

  handleError(error: Error): void {
    // Since this ErrorHandler is provided during app bootstrapping, it's important
    // to not inject service dependencies inside the constructor
    const loggerService: LoggerService = this.injector.get(LoggerService);
    const appConfigService: AppConfigService = this.injector.get(AppConfigService);
    let isLoggerEnabled: boolean = false;
    const loggingConfig: any = appConfigService.get('logging');
    if (loggingConfig && loggingConfig.enabled) {
      isLoggerEnabled = true;
    }

    // Log the error to the console
    console.error(error);

    // Handle chunk load errors by reloading the page
    const chunkFailedRegex = /Loading chunk [\d]+ failed/;
    if (chunkFailedRegex.test(error.message)) {
      window.location.reload();
      return;
    }

    if (!isLoggerEnabled || error.name === 'HttpErrorResponse') {
      return;
    }
    // In case of error event, the stack contains all needed information.
    loggerService.logEvent(error.name, error.stack, SeverityLevel.Critical);
  }
}
