import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { first, map, mergeAll } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { CouponDetailsService } from 'src/app/core/services/coupon/coupon-details.service';
import { CurrencyService } from 'src/app/core/services/currency.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ShopOwnerService } from 'src/app/core/services/shop-owner.service';
import { VariablesService } from 'src/app/core/services/variables.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { CashoutQuery } from 'src/app/core/state/cashout/cashout.query';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { MyBetsService } from 'src/app/modules/my-bets/services/my-bets.service';
import { expandCollapseSimpler } from 'src/app/shared/animations';
import { UserType } from 'src/app/shared/models/account.model';
import { CashoutModel, CashoutStatusTab } from 'src/app/shared/models/cashout.model';
import { BetFinalState, CouponDetailsUIState } from 'src/app/shared/models/coupon-details.model';
import { NotificationSettings } from 'src/app/shared/models/notification.model';
import { CurrencyFormatPipe } from 'src/app/shared/pipes/currency-format.pipe';

@Component({
  selector: 'app-cashout',
  templateUrl: './cashout.component.html',
  styleUrls: ['./cashout.component.scss'],
  animations: [expandCollapseSimpler()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CashoutComponent implements OnInit {
  @Input() code: string;
  @Input() updateCouponDetails: boolean;

  cashoutStatusTab: typeof CashoutStatusTab = CashoutStatusTab;
  cashoutButtonStyle: any = {
    fontSize: '12px',
    fontWeight: 'normal',
    height: '31px',
    margin: '2px',
    padding: '0 8px 0 5px',
    textTransform: 'capitalize',
    letterSpacing: '0.8px',
  };

  enableCashout$: Observable<boolean>;
  cashoutLoggedIn$: Observable<boolean>;
  cashoutSameUser$: Observable<boolean>;
  cashoutWhitelist$: Observable<boolean>;
  cashoutShopOwnerSubUser$: Observable<boolean>;
  item$: Observable<CashoutModel>;
  currencyFormatPipe: CurrencyFormatPipe;

  readonly showMoreInfo$ = new BehaviorSubject(false);
  readonly betFinalState = BetFinalState;

  constructor(
    readonly variablesService: VariablesService,
    readonly cashoutStore: CashoutStore,
    readonly cashoutQuery: CashoutQuery,
    readonly accountQuery: AccountQuery,
    private readonly cashoutService: CashoutService,
    private readonly notificationService: NotificationService,
    private readonly couponDetailsService: CouponDetailsService,
    private readonly accountService: AccountService,
    private readonly myBetsService: MyBetsService,
    private readonly applicationQuery: ApplicationQuery,
    private readonly languageService: LanguageService,
    private readonly currencyService: CurrencyService,
    private readonly shopOwnerService: ShopOwnerService
  ) {
    this.currencyFormatPipe = new CurrencyFormatPipe(this.applicationQuery, this.languageService, this.currencyService);
  }

  ngOnInit(): void {
    this.item$ = this.cashoutQuery.getCashout(this.code);
    this.cashoutLoggedIn$ = this.accountQuery.isAuthenticated$;
    this.cashoutSameUser$ = this.userIdCheck$();
    this.cashoutWhitelist$ = this.cashoutService.isWhitelistedUserType$();
    this.cashoutShopOwnerSubUser$ =
      this.accountQuery.userData && this.accountQuery.userData.userTypeCode === UserType.ShopOwner
        ? this.shopOwnerCheck$()
        : this.cashoutSameUser$; // If not a shop owner, just mimic the same user check
    this.enableCashout$ = combineLatest([
      this.cashoutLoggedIn$,
      this.cashoutSameUser$,
      this.cashoutWhitelist$,
      this.cashoutShopOwnerSubUser$,
    ]).pipe(map(result => result[0] && result[2] && (result[1] || result[3])));
  }

  toggleMoreInfo(): void {
    this.showMoreInfo$.next(!this.showMoreInfo$.value);
  }

  onRefreshCashout(item: CashoutModel): void {
    // first check if this coupon's cashout is in evaluation state
    const cashoutRequested = this.cashoutQuery.isInPendingList(undefined, item.couponCode);

    this.cashoutStore.setActive(item.id);
    this.cashoutStore.cashoutRequested(cashoutRequested);

    if (cashoutRequested) {
      return;
    }

    this.cashoutService.refreshCashout(item.id, item.couponCode).subscribe(success => {
      if (!success) {
        return;
      }
    });
  }

  onCashout(item: CashoutModel): void {
    if (this.enableCashout$ && item.betCashout.allowCashout) {
      // first check if this coupon's cashout is in evaluation state
      const cashoutRequested = this.cashoutQuery.isInPendingList(undefined, item.couponCode);

      this.cashoutStore.setActive(item.id);
      this.cashoutStore.cashoutRequested(cashoutRequested);

      if (cashoutRequested) {
        return;
      }

      this.enableCashout$.pipe(first()).subscribe(cashoutEnabled => {
        if (this.cashoutQuery.isEnabled || item.betCashout !== undefined || item.betCashout.availability || cashoutEnabled) {
          const notificationSettings: NotificationSettings = new NotificationSettings({
            contentHtml:
              item.betCashout.tax > 0
                ? $localize`Cashout Value: <b>${this.currencyFormatPipe.transform(
                    item.betCashout.valueNet
                  )}</b> (Tax ${this.currencyFormatPipe.transform(item.betCashout.tax)})`
                : $localize`Cashout Value: <b>${this.currencyFormatPipe.transform(item.betCashout.value)}</b>`,
            showCloseButton: true,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: $localize`Proceed`,
            confirmButtonCallback: () => {
              this.onCashoutConfirm(item);
            },
          });

          this.notificationService.showNotification(notificationSettings);
        }
      });
    }
  }

  onCashoutConfirm(item: CashoutModel): void {
    this.cashoutService
      .cashout(
        item.id,
        item.betCashout.serverData,
        this.accountQuery.userData.userTypeCode === UserType.ShopOwner ? item.userId : undefined
      )
      .subscribe(success => {
        if (!success) {
          return;
        }

        const data = this.cashoutQuery.getActive() as CashoutModel;

        if (data) {
          if (!data.cashoutResponse.success) {
            this.notificationService.showErrorNotification(data.cashoutResponse.userMessage);
            return;
          }
          if (data.cashoutResponse.success && data.cashoutResponse.cashoutId >= 0) {
            if (data.cashoutResponse.cashoutAccepted) {
              if (this.updateCouponDetails) {
                this.couponDetailsService.getCouponDetails(item.couponCode).subscribe(
                  () => {
                    return;
                  },
                  () => {
                    // For the case when the backend want to tell us, it's a wrong coupon code by an Internal Server Error...
                    const uiUpdate2 = new CouponDetailsUIState({ wrongCouponCode: true, isLoading: false });
                    this.couponDetailsService.updateCouponDetailsUI(uiUpdate2);
                  }
                );
                const uiUpdate = new CouponDetailsUIState({ wrongCouponCode: false, isLoading: false });
                this.couponDetailsService.updateCouponDetailsUI(uiUpdate);
              }
              this.accountService.updateBalance();
              // Only remove the bet from the open bets list if the cashout is 100% successful
              this.myBetsService.removeFromOpenBets(item.couponCode);
              this.notificationService.showSuccessNotification(
                $localize`Coupon ${data.cashoutResponse.couponCode} has been cashed out with a value of ${this.currencyFormatPipe.transform(
                  data.cashoutResponse.cashoutValue
                )}.`
              );
            } else {
              this.notificationService.showInfoNotification(data.cashoutResponse.userMessage);

              this.cashoutStore.setActive(item.id);
              this.cashoutStore.cashoutRequested(true);

              this.cashoutService.addToPendingList(data.cashoutResponse.cashoutId, data.cashoutResponse.couponCode);
            }
          }

          this.cashoutService.refreshCashout(item.id, item.couponCode).subscribe();
          // It's best to always refresh clear the settled bets tab
          this.myBetsService.clearSettledBets();
        }
      });
  }

  /**
   * Check if user is the same as the one that created the coupon
   */
  private userIdCheck$(): Observable<boolean> {
    return this.item$.pipe(
      map(item => (item ? this.accountQuery.isAuthenticated && Number(this.accountQuery.userData.id) === Number(item.userId) : false))
    );
  }

  /**
   * For shop owners, check if the coupon has been created by a subordinate
   */
  private shopOwnerCheck$(): Observable<boolean> {
    return this.accountQuery.isAuthenticated
      ? this.item$.pipe(map(item => (item ? this.shopOwnerService.isSubUser$(Number(item.userId)) : of(false)))).pipe(mergeAll())
      : of(true); // If not logged in, default to USER type so shop owner check is bypassed
  }
}
