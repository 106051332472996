import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { EventSummaryModel, MatchModel } from 'src/app/shared/models/sport.model';

@Component({
  selector: 'app-events-summary',
  templateUrl: './events-summary.component.html',
  styleUrls: ['./events-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsSummaryComponent {
  @Input() eventsInfo: EventSummaryModel;
  @Input() showMarketName: boolean = false;
  @Input() eventLocation: string = undefined; // Used for data layer events

  eventTrackBy(index: number, match: MatchModel): number {
    return match.id;
  }
}
