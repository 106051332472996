import { Injectable } from '@angular/core';
import MD5 from 'crypto-js/md5';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { BetslipActionsDetails, DataLayerEvent } from 'src/app/shared/models/datalayer.model';

@Injectable({
  providedIn: 'root',
})
export class DataLayerService {
  constructor(private readonly appConfig: AppConfigService) {}

  createDataLayerEvent(event: DataLayerEvent): void {
    if (this.appConfig.get('enableDataLayer')) {
      const global = window as any;
      global.dataLayer = global.dataLayer || [];
      global.dataLayer.push(event);
    }
  }

  createOptimoveEvent(event: string, key: string, value: string): void {
    if (this.appConfig.get('enableDataLayer')) {
      const global = window as any;
      global.dataLayer = global.dataLayer || [];
      const object = {
        event: event,
      };
      object[key] = value;
      global.dataLayer.push(object);
    }
  }

  toMD5Hash(data: any): string {
    return MD5(data).toString();
  }

  /**
   * Get the Site UX value to be used in datalayer events
   * as per UP-186
   */
  dataLayerSiteUX(): string {
    const devREG = new RegExp('(.azurewebsites.net)|(localhost)');

    return devREG.exec(window.location.hostname) ? 'DEV' : 'M';
  }

  addBetslipEventToDataLayer(details: BetslipActionsDetails): void {
    const event = {
      event: details.event,
      product: details.product,
    };

    if (details.userId) {
      event['user_id'] = details.userId;
    }

    if (details.currency) {
      event['currency'] = details.currency;
    }

    if (details.errorMessage) {
      event['error_message'] = details.errorMessage;
    }

    if (details.betStake) {
      event['bet_stake'] = details.betStake;
    }

    if (details.betType) {
      event['bet_type'] = details.betType;
    }

    if (details.couponId) {
      event['coupon_id'] = details.couponId;
    }

    if (details.selectionId) {
      event['selection_id'] = details.selectionId;
    }

    if (details.location) {
      event['location'] = details.location;
    }

    if (details.isFreeBet) {
      event['is_free_bet'] = details.isFreeBet;
    }

    this.createDataLayerEvent(event);
  }
}
