<div class="close" (click)="navigateBack()">
  <i class="material-icons icon">{{ 'close' }}</i>
</div>

<ng-container [ngSwitch]="receiptState$ | async">
  <!-- Bet Success with Verified Number Screen -->
  <ng-container *ngSwitchCase="receiptStates.Confirmation" [ngTemplateOutlet]="confirmation"></ng-container>
  <!-- Bet Success without Verified Number Screen -->
  <ng-container *ngSwitchCase="receiptStates.PreVerification" [ngTemplateOutlet]="preVerification"></ng-container>
  <!-- Number Input Screen -->
  <ng-container *ngSwitchCase="receiptStates.InputNumber">
    <mobver-mobile-number-verification
      [content]="couponQuery.couponReceiptPhoneVerificationContent$ | async"
      (proceedClicked)="proceedFromVerifyNumber($event)"
    >
    </mobver-mobile-number-verification>
  </ng-container>
  <!-- OTP Screen -->
  <ng-container *ngSwitchCase="receiptStates.InputOTP">
    <app-otp-screen
      [content]="couponQuery.couponReceiptPhoneVerificationContent$ | async"
      [mobilePrefix]="mobilePrefix$ | async"
      [mobileNumber]="mobileNumber$ | async"
      [genericError]="otpInputGenericError$ | async"
      [otpOption]="5"
      (postOTP)="postOTP()"
    ></app-otp-screen>
  </ng-container>
  <!-- Verification Success -->
  <ng-container *ngSwitchCase="receiptStates.NumberVerified">
    <div class="post-verification">
      <div class="top-half-bg"></div>
      <div
        class="verification-success"
        *ngIf="couponQuery.couponReceiptPhoneVerificationContent$ | async as receiptPhoneVerficiationContent"
      >
        <h1 class="success-text-header">
          {{ receiptPhoneVerficiationContent.phoneVerificationSuccessHeader }}
        </h1>
        <div class="success-text-subtitle" [innerText]="receiptPhoneVerficiationContent.phoneVerificationSuccessMessage"></div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <div class="default-view">
      <div class="animation">
        <ng-lottie [options]="betslipLoadCompleteOptions"></ng-lottie>
        <h1 class="success-text-header" i18n>Bet Placed!</h1>
        <ng-container *ngTemplateOutlet="ctaButtons"></ng-container>
      </div>
    </div>
  </ng-container>

  <ng-template #confirmation>
    <div class="confirmation" *ngIf="receiptContent$ | async as receiptContent">
      <div class="top-half-bg"></div>
      <div class="animation">
        <ng-lottie [options]="betslipLoadCompleteOptions"></ng-lottie>
        <ng-container
          *ngTemplateOutlet="couponQuery.isLastPlacedCouponInEvaluation ? evaluation : success; context: { receiptContent: receiptContent }"
        >
        </ng-container>
      </div>
      <div class="message">
        <div class="logo" [style.background-image]="'url(' + receiptContent.imageUrl + ')'"></div>
        <div class="text">
          {{ receiptContent.receiptMessageText }}
        </div>
        <div class="buttons">
          <app-button
            [text]="receiptContent.backCTAText"
            [customButtonStyle]="ctaAdditionalStyles"
            [buttonType]="buttonType.Transparent"
            (click)="navigateBack()"
          ></app-button>
          <app-button
            [text]="receiptContent.forwardCTAText"
            [customButtonStyle]="ctaAdditionalStyles"
            (click)="navigateForward()"
          ></app-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #preVerification>
    <div class="pre-verification">
      <div class="top-half-bg"></div>
      <div class="animation" *ngIf="receiptContent$ | async as receiptContent">
        <ng-lottie [options]="betslipLoadCompleteOptions"></ng-lottie>
        <ng-container
          *ngTemplateOutlet="couponQuery.isLastPlacedCouponInEvaluation ? evaluation : success; context: { receiptContent: receiptContent }"
        >
        </ng-container>
      </div>
      <div class="message" *ngIf="couponQuery.couponReceiptPhoneVerificationContent$ | async as receiptPhoneVerficiationContent">
        <div class="text">
          {{ receiptPhoneVerficiationContent.phoneVerificationMessage }}
        </div>
        <div class="buttons">
          <app-button
            [text]="receiptPhoneVerficiationContent.phoneVerificationBackCTAText"
            [customButtonStyle]="ctaAdditionalStyles"
            [buttonType]="buttonType.Transparent"
            (click)="navigateBack()"
          ></app-button>
          <app-button
            [text]="receiptPhoneVerficiationContent.phoneVerificationForwardCTAText"
            [customButtonStyle]="ctaAdditionalStyles"
            (click)="navigateForward()"
          ></app-button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #success let-receiptContent="receiptContent">
    <h1 class="success-text-header">
      {{ receiptContent.receiptConfirmationHeader }}
    </h1>
    <div
      class="success-text-subtitle"
      *ngIf="(accumulatorBonusQuery.hasAccumulatorBonus$ | async) === false && (hasFreeBetSelected$ | async) === false"
    >
      {{ receiptContent.receiptConfirmationSubtitle }}
    </div>
    <div
      class="success-text-subtitle"
      [innerText]="receiptContent.receiptConfirmationAccumulatorBonusSubtitle"
      *ngIf="accumulatorBonusQuery.hasAccumulatorBonus$ | async"
    ></div>
    <div
      class="success-text-subtitle"
      [innerText]="receiptContent.receiptConfirmationFreebetSubtitle"
      *ngIf="hasFreeBetSelected$ | async"
    ></div>
    <ng-container *ngTemplateOutlet="ctaButtons"></ng-container>
  </ng-template>

  <ng-template #evaluation>
    <h1 class="success-text-header" i18n>Your Bet Request Is Being Evaluated</h1>
    <div class="success-text-subtitle" i18n>Please Wait A Few Moments For Confirmation.</div>
  </ng-template>

  <ng-template #ctaButtons>
    <div class="buttons-container">
      <app-button
        class="retain-selections-button"
        [text]="(receiptContent$ | async)?.receiptConfirmationRetainSelectionsCTAText"
        (click)="retainSelections()"
        [customButtonStyle]="ctaAdditionalStyles"
      >
      </app-button>
      <app-button
        *ngIf="(receiptContent$ | async)?.receiptConfirmationShareBetCTA && (hasFreeBetSelected$ | async) === false"
        class="share-selections"
        [text]="(receiptContent$ | async)?.receiptConfirmationShareBetCTA"
        (click)="performShareBet()"
        [customButtonStyle]="ctaAdditionalStyles"
        faIcon="fa-share-alt"
      >
      </app-button>
    </div>
  </ng-template>
</ng-container>
