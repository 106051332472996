import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, switchMap, takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/core/services/account/account.service';
import { ApplicationService } from 'src/app/core/services/application.service';
import { MenuService } from 'src/app/core/services/menu.service';
import { MessageService } from 'src/app/core/services/message.service';
import { AccountQuery } from 'src/app/core/state/account/account.query';
import { ApplicationQuery } from 'src/app/core/state/application/application.query';
import { MessagesQuery } from 'src/app/core/state/messages/messages.query';
import { SportService } from 'src/app/core/services/sports/sport.service';
import { SportQuery } from 'src/app/core/state/sport/sport.query';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() darkMode: boolean = false;
  @Input() showIcons: boolean = true;

  destroy$ = new Subject<boolean>();
  enableBankProfile: boolean = false;
  readonly maxTimeForUnreadMsgCount: number = 180;

  constructor(
    readonly accountQuery: AccountQuery,
    readonly messagesQuery: MessagesQuery,
    readonly applicationQuery: ApplicationQuery,
    private readonly applicationService: ApplicationService,
    private readonly accountService: AccountService,
    private readonly appConfigService: AppConfigService,
    private readonly menuService: MenuService,
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly sportQuery: SportQuery,
    private readonly sportService: SportService
  ) {
    this.enableBankProfile = this.appConfigService.get('account').enableWithdrawalBankProfile;
  }

  ngOnInit(): void {
    const timeSinceLastCall = this.applicationService.getEpochTimeDifference(this.applicationQuery.cms.lastQuicklinksUpdate);
    const timeSinceLastCallOnUpdateMsg = this.applicationService.getEpochTimeDifference(this.applicationQuery.cms.lastUnreadMessagesUpdate);
    if (timeSinceLastCall > this.applicationQuery.cms.cacheTTL) {
      this.menuService.getQuicklinks().subscribe();
    }
    if (timeSinceLastCallOnUpdateMsg > this.maxTimeForUnreadMsgCount) {
      this.accountQuery.isAuthenticated$
        .pipe(
          distinctUntilChanged(),
          filter(auth => !!auth),
          switchMap(() => this.messageService.getUnreadMessagesCount()),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  goHome(): void {
    // Requirements state that the brand logo must always lead to the topmost landing page, irrelevant of current active product
    this.router.navigateByUrl('/');
    this.applicationService.closeAnySlideUps();
    if (this.sportQuery.selectedPrematch) {
      this.sportService.updateEventSelection({ filterToDepthFour: false });
      this.sportService.restoreAreaRegionsCache();
    }
  }

  menuItemProfileStatus(): boolean {
    if ((this.accountQuery.userProfileState === 'profileEmpty' || this.accountQuery.sbadIndicatorState) && this.enableBankProfile) {
      return true;
    } else {
      return false;
    }
  }

  refreshBalance(): void {
    if (!this.accountQuery.refreshingBalance) {
      this.accountService.updateBalance();
    }
  }

  openLoginDialog(): void {
    this.applicationService.setShowLoginDialog(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
