import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, first } from 'rxjs/operators';
import { AccountService } from 'src/app/core/services/account/account.service';
import { LoggerService } from 'src/app/core/services/logger.service';
import { SecureLoginService } from 'src/app/modules/native-app/services/secure-login.service';
import { VersionService } from 'src/app/modules/native-app/services/version.service';
import { LogTypeModel } from 'src/app/shared/models/log.model';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private readonly accountService: AccountService,
    private readonly loggerService: LoggerService,
    private readonly router: Router,
    private readonly secureLoginService: SecureLoginService,
    private readonly versionService: VersionService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error.status === 401) {
          const queryParams = new URLSearchParams(window.location.search);
          if (!queryParams.has('btk') || queryParams.has('logoutOnError')) {
            // Does not have auto-login token, so we need to handle logout during interception on web, and auto login on the native app.
            if (Capacitor.isNativePlatform()) {
              this.secureLoginService.reLogin(() => {
                this.logout();
              });
            } else {
              this.logout();
            }
          }
          return;
        } else if (error.status === 0) {
          // Switch to offline mode on native app if there is no connection
          this.versionService.switchToOfflineMode(error.url);
          this.loggerService.logEvent(error.name, error.message, SeverityLevel.Error, LogTypeModel.Network);
        } else if (error.status !== 403 && error.status !== 418) {
          // If the server error status response means forbidden or tea pot, we don't need to log that event
          this.loggerService.logEvent(error.name, error.message, SeverityLevel.Error, LogTypeModel.Network);
        }

        return throwError(error);
      })
    );
  }

  private logout(): void {
    this.accountService
      .logout()
      .pipe(
        first(),
        finalize(() => {
          const queryParams = new URLSearchParams(window.location.search);
          if (!queryParams.has('noRedirectOnLogout')) {
            this.router.navigate(['/']);
          }
        })
      )
      .subscribe(undefined, () => {
        // if an error occurs while logging out, manually clear the
        // user data
        this.accountService.clearUserData();
      });
  }
}
